<template>
  <div
    class="event-market-code-section grouped"
    v-if="marketDisplayConfiguration.grouped"
  >
    <div
      class="market-code-section-name"
      @click="toggleMarket"
    >
      <div class="market-code-section-name-group">
        <div class="market-code-section-name-icon">
          <ChevronDownIcon v-if="marketToggled" />
          <ChevronRightIcon v-else />
        </div>
        {{ marketDisplayConfiguration.groupView.longNamePattern }}
      </div>
      <div
        class="show-all-lines"
        @click.stop="() => { showAllLines = !showAllLines }"
        v-if="showAllLinesButton"
      >
        {{ showAllLines ? 'Hide alternate lines' : 'Show all lines' }}
      </div>
    </div>
    <!-- Market is grouped and has defined grouped params -->
    <div
      class="event-market-code-section-markets"
      v-if="marketToggled && marketDisplayConfiguration.groupView?.groupedParams?.length"
    >
      <div
        class="event-market-code-section-markets-group"
        v-for="(group, groupIndex) in sectionFormattedMarkets"
        :key="groupIndex"
      >
        <div class="group-header">
          {{ group.paramDisplay }}
        </div>
        <div class="grouped-content">
          <div
            class="subgrouped-content"
            v-if="marketDisplayConfiguration.groupView.groupedParams[1]"
          >
            <div
              class="event-market-code-section-markets-group"
              v-for="(subgroup, subgroupIndex) in group.markets"
              :key="subgroupIndex"
            >
              <div class="group-header">
                {{ subgroup.paramDisplay }}
              </div>
              <div class="grouped-content">
                <div
                  class="grouped-content-markets"
                >
                  <div
                    class="grouped-content-market"
                    v-for="(market, subGroupMarketIndex) in subgroup.markets"
                    :key="market.marketId"
                  >
                    <div
                      v-show="!marketVisible && ((subGroupMarketIndex === 0) || (!isNil(market.marketType.params.LINE) && showAllLines) || isNil(market.marketType.params.LINE))"
                      :style="{
                        height: `${(market.selections?.length * 68) + 16}px`,
                      }"
                      class="market-hidden-placeholder"
                    />
                    <EventMarket
                      v-if="marketVisible && ((subGroupMarketIndex === 0) || (!isNil(market.marketType.params.LINE) && showAllLines) || isNil(market.marketType.params.LINE))"
                      :market-id="market.marketId"
                      :betslip-market-name="`${marketDisplayConfiguration.groupView.shortNamePattern} ${group.paramDisplay} ${subgroup.paramDisplay}`"
                      :sport="sport"
                      :competition="competition"
                      :event-id="eventId"
                      :teams="teams"
                      :has-sgp="hasSgp"
                      :markets-internal-source="marketsInternalSource"
                      :bookmaker-groups="bookmakerGroups"
                      :is-usa-view="isUsaView"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="grouped-content-markets"
          >
            <div
              class="grouped-content-market"
              v-for="(market, marketIndex) in group.markets"
              :key="market.marketId"
            >
              <div
                v-show="!marketVisible && ((marketIndex === 0) || (!isNil(market.marketType.params.LINE) && showAllLines) || isNil(market.marketType.params.LINE))"
                :style="{
                  height: `${(market.selections?.length * 68) + 16}px`,
                }"
                class="market-hidden-placeholder"
              />
              <EventMarket
                v-if="marketVisible && ((marketIndex === 0) || (!isNil(market.marketType.params.LINE) && showAllLines) || isNil(market.marketType.params.LINE))"
                :market-id="market.marketId"
                :betslip-market-name="`${marketDisplayConfiguration.groupView.shortNamePattern} ${group.paramDisplay}`"
                :sport="sport"
                :competition="competition"
                :event-id="eventId"
                :teams="teams"
                :has-sgp="hasSgp"
                :markets-internal-source="marketsInternalSource"
                :bookmaker-groups="bookmakerGroups"
                :is-usa-view="isUsaView"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!sectionFormattedMarkets.length"
      >
        <div class="no-market-available">
          Market not available
        </div>
      </div>
    </div>
    <!-- Market is grouped but doesnt have defined grouped params -->
    <div
      class="event-market-code-section-markets"
      v-if="marketToggled && !marketDisplayConfiguration.groupView?.groupedParams?.length"
    >
      <div
        class="event-market-code-section-markets-group"
        v-for="(market, marketIndex) in sectionFormattedMarkets"
        :key="market.marketId"
      >
        <div
          v-show="!marketVisible && (marketIndex === 0 || (!isNil(market.marketType.params.LINE) && showAllLines) || isNil(market.marketType.params.LINE))"
          :style="{
            height: `${(market.selections?.length * 68) + 16}px`,
          }"
          class="market-hidden-placeholder"
        />
        <EventMarket
          v-if="marketVisible && (marketIndex === 0 || (!isNil(market.marketType.params.LINE) && showAllLines) || isNil(market.marketType.params.LINE))"
          :market-id="market.marketId"
          :betslip-market-name="marketDisplayConfiguration.groupView.longNamePattern"
          :sport="sport"
          :competition="competition"
          :event-id="eventId"
          :teams="teams"
          :has-sgp="hasSgp"
          :markets-internal-source="marketsInternalSource"
          :bookmaker-groups="bookmakerGroups"
          :is-usa-view="isUsaView"
        />
      </div>
      <div
        class="no-market-available"
        v-if="!sectionFormattedMarkets.length"
      >
        Market not available
      </div>
    </div>
  </div>
  <!-- Market is ungrouped -->
  <div
    class="event-market-code-section"
    v-else
  >
    <div
      class="event-market-code-section-ungrouped"
      v-for="ungroupedMarket in sectionFormattedMarkets"
      :key="ungroupedMarket.marketId"
    >
      <div
        class="market-code-section-name"
        @click="toggleMarket"
      >
        <div class="market-code-section-name-group">
          <div class="market-code-section-name-icon">
            <ChevronDownIcon v-if="marketToggled" />
            <ChevronRightIcon v-else />
          </div>
          {{ ungroupedMarket.displayName }}
        </div>
      </div>
      <div
        class="event-market-code-section-markets"
        v-if="marketToggled"
      >
        <div
          v-show="!marketVisible"
          :style="{
            height: `${(ungroupedMarket.selections?.length * 68) + 16}px`,
          }"
          class="market-hidden-placeholder"
        />
        <EventMarket
          v-if="marketVisible"
          :market-id="ungroupedMarket.marketId"
          :betslip-market-name="ungroupedMarket.displayName"
          :sport="sport"
          :competition="competition"
          :event-id="eventId"
          :teams="teams"
          :has-sgp="hasSgp"
          :markets-internal-source="marketsInternalSource"
          :bookmaker-groups="bookmakerGroups"
          :is-usa-view="isUsaView"
        />
      </div>
    </div>
    <div
      class="market-code-section-name"
      @click="toggleMarket"
      v-if="!sectionFormattedMarkets.length"
    >
      <div class="market-code-section-name-group">
        <div class="market-code-section-name-icon">
          <ChevronDownIcon v-if="marketToggled" />
          <ChevronRightIcon v-else />
        </div>
        {{ marketDisplayConfiguration.defaultName }}
      </div>
    </div>
    <div
      class="event-market-code-section-markets"
      v-if="!sectionFormattedMarkets.length && marketToggled"
    >
      <div class="no-market-available">
        Market not available
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  filter, find, includes, map,
  merge, groupBy, each, orderBy, isNil,
} from 'lodash';
import { ChevronRightIcon, ChevronDownIcon } from '@/components/svg';
import bookmakers from '@/services/helpers/bookmakers';
import MarketMapper from '@/services/helpers/market-mapper';
import EventMarket from './EventMarket';

export default {
  components: {
    ChevronRightIcon,
    ChevronDownIcon,
    EventMarket,
  },
  props: {
    marketDisplayConfiguration: {
      type: Object,
      required: true,
    },
    allMarketParameters: {
      type: Array,
      required: true,
    },
    marketsInternalSource: {
      type: String,
      required: true,
    },
    sport: {
      type: Object,
      default: null,
    },
    competition: {
      type: Object,
      default: null,
    },
    teams: {
      type: Object,
      default: null,
    },
    eventId: {
      type: String,
      default: null,
    },
    hasSgp: {
      type: Boolean,
      default: false,
    },
    isUsaView: {
      type: Boolean,
      default: false,
    },
    marketVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const showAllLines = ref(false);
    const showAllLinesButton = ref(false);

    const marketCodeSectionInternalMarkets = computed(() => MarketMapper.sortMarkets(map(props.marketDisplayConfiguration.internalMarketsIds,
      (marketId) => {
        const market = store.getters.getEventMarketById(marketId);
        if (market.marketType.params.PLAYER_INDEX) {
          const playerdisplayNameSplit = market.marketSummary.playerName.split(', ');
          const playerDisplayName = `${playerdisplayNameSplit[1]} ${playerdisplayNameSplit[0]}`;
          return {
            ...market,
            playerDisplayName,
          };
        }
        return {
          ...market,
        };
      }), props.isUsaView));

    const groupParamDisplay = (param, paramValue, markets) => {
      if (param === 'TEAM') {
        const team = props.teams[paramValue === 0 ? 'home' : 'away'];
        return team?.name || 'N/A team';
      }
      if (param === 'PLAYER_INDEX') return markets[0]?.playerDisplayName || markets[0]?.marketSummary?.playerName || 'N/A player';
      const paramName = find(props.allMarketParameters, (parameter) => parameter.code === param)?.name || param;
      return props.marketDisplayConfiguration.parameters[param]?.type === 'ORDINAL'
        ? `${paramValue}${MarketMapper.mapNumberSuffix(paramValue.toString())} ${paramName}`
        : `${paramName} ${paramValue}`;
    };

    const sectionFormattedMarkets = computed(() => {
      // eslint-disable-next-line
      showAllLinesButton.value = false;
      // grouped market
      if (props.marketDisplayConfiguration.grouped) {
        // grouped but doesnt have set grouped params ---> exception
        if (!props.marketDisplayConfiguration.groupView?.groupedParams.length) {
          // eslint-disable-next-line
          if (marketCodeSectionInternalMarkets.value?.length > 1 && marketCodeSectionInternalMarkets.value?.[0]?.marketType?.params.LINE) showAllLinesButton.value = true;
          return marketCodeSectionInternalMarkets.value;
        }
        const mainGroupedParam = props.marketDisplayConfiguration.groupView?.groupedParams[0];
        const subGroupedParam = props.marketDisplayConfiguration.groupView?.groupedParams[1];
        const mainGroup = `marketType.params.${mainGroupedParam}`;
        const subGroup = `marketType.params.${subGroupedParam}`;
        let mainGroupMarkets = map(groupBy(marketCodeSectionInternalMarkets.value, mainGroup),
          (groupMarkets, key) => {
            let filteredSubGroupMarkets = [];
            if (subGroupedParam) {
              filteredSubGroupMarkets = map(groupBy(groupMarkets, subGroup), (subgroupMarkets, subkey) => ({
                paramValue: +subkey,
                param: subGroupedParam,
                paramDisplay: groupParamDisplay(subGroupedParam, +subkey, subgroupMarkets),
                markets: subgroupMarkets,
              }));
              if (subGroupedParam === 'TEAM' && props.isUsaView) {
                filteredSubGroupMarkets = orderBy(filteredSubGroupMarkets, ['paramValue'], ['desc']);
              }
              if (subGroupedParam === 'PLAYER_INDEX') {
                filteredSubGroupMarkets = orderBy(filteredSubGroupMarkets, 'paramDisplay');
              }
            }
            if (!subGroupedParam && groupMarkets?.length > 1 && groupMarkets[0]?.marketType?.params.LINE) showAllLinesButton.value = true;
            each(filteredSubGroupMarkets, (group) => {
              if (group?.markets?.length > 1 && !isNil(group?.markets[0]?.marketType.params.LINE)) showAllLinesButton.value = true;
            });

            return {
              markets: subGroupedParam ? filteredSubGroupMarkets : groupMarkets,
              param: mainGroupedParam,
              paramDisplay: groupParamDisplay(mainGroupedParam, +key, groupMarkets),
              paramValue: +key,
            };
          });

        if (mainGroupedParam === 'TEAM' && props.isUsaView) {
          mainGroupMarkets = orderBy(mainGroupMarkets, ['paramValue'], ['desc']);
        }
        if (mainGroupedParam === 'PLAYER_INDEX') {
          mainGroupMarkets = orderBy(mainGroupMarkets, 'paramDisplay');
        }
        return mainGroupMarkets;
      }
      // ungrouped market
      const startingBracketIndexes = [];
      const endingBracketIndexes = [];

      const { longNamePattern } = props.marketDisplayConfiguration;
      for (let i = 0; i < longNamePattern?.length; i += 1) {
        if (longNamePattern[i] === '{') startingBracketIndexes.push(i);
        if (longNamePattern[i] === '}') endingBracketIndexes.push(i);
      }

      const paramsToReplace = [];

      for (let i = 0; i < startingBracketIndexes.length; i += 1) {
        paramsToReplace.push(longNamePattern.slice(startingBracketIndexes[i] + 1, endingBracketIndexes[i]));
      }
      return map(marketCodeSectionInternalMarkets.value, (market) => {
        let displayName = longNamePattern;
        if (!paramsToReplace.length) {
          return {
            ...market,
            displayName,
          };
        }
        each(paramsToReplace, (param) => {
          if (param === 'TEAM') {
            const team = props.teams[market.marketType.params.TEAM === 0 ? 'home' : 'away'];
            const teamName = includes(paramsToReplace, 'PLAYER_INDEX') ? team?.shortName : team?.name;
            displayName = displayName.replace('TEAM', teamName);
            displayName = displayName.replace(/{|}/g, '');
            return;
          }
          if (param === 'PLAYER_INDEX') {
            displayName = displayName.replace('PLAYER_INDEX', market.playerDisplayName || market.marketSummary?.playerName || 'N/A');
            displayName = displayName.replace(/{|}/g, '');
            return;
          }
          if (param === 'PITCHER_PLAYER_INDEX' || param === 'BATTER_PLAYER_INDEX') {
            const playerIndex = market.marketType.params[param];
            const playerName = market.marketSummary?.players?.[playerIndex]?.playerName;
            const playerdisplayNameSplit = playerName?.split(', ');
            const playerDisplayName = playerdisplayNameSplit
              ? `${playerdisplayNameSplit[1]} ${playerdisplayNameSplit[0]}`
              : '';
            displayName = displayName.replace(param, playerDisplayName || 'N/A');
            displayName = displayName.replace(/{|}/g, '');
            return;
          }
          if (param === 'IS_TOP_INNING') {
            const isTopInningValue = market.marketType.params.IS_TOP_INNING === 0 ? 'Bottom' : 'Top';
            displayName = displayName.replace('IS_TOP_INNING', isTopInningValue);
            displayName = displayName.replace(/{|}/g, '');
            return;
          }
          const valueToReplace = market.marketType.params[param];
          const numberSuffix = props.marketDisplayConfiguration.parameters[param]?.type === 'ORDINAL' ? MarketMapper.mapNumberSuffix(valueToReplace.toString()) : '';
          displayName = displayName.replace(param, `${valueToReplace}${numberSuffix}`);
          displayName = displayName.replace(/{|}/g, '');
        });
        return {
          ...market,
          displayName,
        };
      });
    });

    const marketCodeSectionExternalMarkets = computed(() => map(props.marketDisplayConfiguration.externalMarketsIds,
      (marketId) => store.getters.getEventMarketById(marketId)));

    const groupedSources = computed(() => filter(map(groupBy(marketCodeSectionExternalMarkets.value, 'source'),
      (sourceMarkets, key) => ({
        name: key,
        markets: filter(sourceMarkets, (market) => find(market.selections,
          (selection) => selection.displayStatus === 'ON' && selection.price.decimalValue)),
      })), (source) => source.markets.length));

    const bookmakerGroups = computed(() => map(bookmakers, (bookmaker) => {
      const bookyFound = find(groupedSources.value, (source) => source.name === bookmaker.name);
      return bookyFound ? merge({}, bookmaker, bookyFound) : bookmaker;
    }));

    const marketToggled = ref(true);

    const toggleMarket = () => {
      marketToggled.value = !marketToggled.value;
    };

    return {
      bookmakerGroups,
      marketToggled,
      toggleMarket,
      marketCodeSectionInternalMarkets,
      sectionFormattedMarkets,
      showAllLines,
      showAllLinesButton,
      isNil,
    };
  },
};

</script>

<style lang="scss">
  .event-market-code-section {
    width: 100%;
    margin-bottom: 8px;

    .event-market-code-section-ungrouped {
      width: 100%;
    }

    .market-code-section-name {
      height: 44px;
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: #003C3C;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #003C3C;
      border-radius: 4px;
      cursor: pointer;
      justify-content: space-between;
      padding-right: 16px;

      .market-code-section-name-group {
        height: 100%;
        display: flex;
        align-items: center;
        .market-code-section-name-icon {
          height: 24px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 4px;

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }

      .show-all-lines {
        height: 28px;
        display: flex;
        padding: 0 8px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #003C3C;
        border-radius: 4px;
      }
    }

    .event-market-code-section-markets {
      padding-top: 16px;

      .event-market-code-section-markets-group {
        .group-header {
          font-weight: 16px;
          font-weight: 700;
          color: #003C3C;
        }

        .grouped-content {
          padding: 16px 0 16px 16px;

          .subgrouped-content {
            padding-left: 16px;
            border-left: 2px solid #E6ECEC;
          }

          .grouped-content-markets {
            padding-left: 16px;
            border-left: 2px solid #E6ECEC;
          }
        }
      }

      .no-market-available {
        height: 42px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
        justify-content: center;
      }
    }
  }
</style>
