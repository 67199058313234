<template>
  <svg
    viewBox="0 0 21 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 2C8.78959 2 6.79015 2.89514 5.34119 4.34511C4.58346 5.10335 3.72302 6.13128 3.03079 7H7C7.55228 7 8 7.44772 8 8C8 8.55228 7.55228 9 7 9H1C0.447715 9 0 8.55228 0 8V2C0 1.44772 0.447715 1 1 1C1.55228 1 2 1.44772 2 2V5.09444C2.60188 4.36248 3.28685 3.57146 3.92649 2.93138C5.73522 1.12139 8.2376 0 11 0C16.5228 0 21 4.47715 21 10C21 15.5228 16.5228 20 11 20C6.43955 20 2.59451 16.9481 1.39097 12.7773C1.23785 12.2466 1.54388 11.6923 2.07452 11.5392C2.60515 11.3861 3.15944 11.6921 3.31256 12.2227C4.27571 15.5605 7.35426 18 11 18C15.4183 18 19 14.4183 19 10C19 5.58172 15.4183 2 11 2Z"
      fill="#191414"
    />
  </svg>
</template>
