<template>
  <div
    ref="root"
    class="operator-dropdown"
  >
    <div
      :class="[
        'operator-dropdown__field',
        { 'operator-dropdown__field--active': open },
      ]"
      @click="toggleDropdown"
    >
      <div class="operator-dropdown__selection">
        <div class="operator-logo">
          <img
            v-if="getOperatorLogo(client)"
            :src="getOperatorLogo(client)"
            :alt="client"
          >
        </div>
        <span>{{ client }}</span>
      </div>
      <ChevronUpIcon
        v-if="open"
        class="operator-dropdown__icon"
      />
      <ChevronDownIcon
        v-else
        class="operator-dropdown__icon"
      />
    </div>

    <transition name="dropdown">
      <div
        v-show="open"
        class="operator-dropdown__list"
      >
        <div class="operator-dropdown__client-group">
          Superadmin
        </div>
        <div
          @click="updateSelection(SUPER_ADMIN_CLIENT)"
          class="operator-dropdown__super-admin-group operator-dropdown__list-item"
        >
          <div class="odd__list-item--left-side">
            <div class="operator-logo">
              <img
                v-if="getOperatorLogo(SUPER_ADMIN_CLIENT)"
                :src="getOperatorLogo(SUPER_ADMIN_CLIENT)"
                :alt="SUPER_ADMIN_CLIENT"
              >
            </div>
            <span>{{ SUPER_ADMIN_CLIENT }}</span>
          </div>
          <div
            v-if="client === SUPER_ADMIN_CLIENT"
            class="odd__list-item--right-side"
          >
            <CheckIcon />
          </div>
        </div>
        <div class="operator-dropdown__client-group">
          Clients
        </div>
        <div
          v-for="option in options"
          :key="option.operatorId"
          class="operator-dropdown__list-item"
          @click="updateSelection(option.operatorId)"
        >
          <div class="odd__list-item--left-side">
            <div class="operator-logo">
              <img
                v-if="getOperatorLogo(option.operatorId)"
                :src="getOperatorLogo(option.operatorId)"
                :alt="option.operatorId"
              >
            </div>
            <span>{{ option.operatorId }}</span>
          </div>
          <div
            v-if="client === option.operatorId"
            class="odd__list-item--right-side"
          >
            <CheckIcon />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { filter } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@/components/svg';
import { getSuperAdminData, getOperatorLogo } from '@/services/helpers/super-admin';

export default {
  emits: ['onOperatorChange'],
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    CheckIcon,
  },
  setup(_, { emit }) {
    const root = ref(null);
    const open = ref(false);
    const { SUPER_ADMIN_CLIENT, client } = getSuperAdminData();

    const store = useStore();
    const options = computed(() => filter(store.getters.operatorsList, (item) => item.operatorId !== SUPER_ADMIN_CLIENT));

    const toggleDropdown = () => {
      open.value = !open.value;
    };
    const updateSelection = (newOperator) => {
      emit('onOperatorChange', newOperator);
    };

    onClickOutside(root, () => {
      open.value = false;
    });

    return {
      root,
      open,
      options,
      client,
      SUPER_ADMIN_CLIENT,
      toggleDropdown,
      updateSelection,
      getOperatorLogo,
    };
  },
};
</script>

<style lang="scss" scoped>
.operator-dropdown {
  position: relative;
  user-select: none;
  font-size: 0.875rem;
  font-weight: 400;
}

.operator-dropdown__field {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  color: #6D6D6D;
  fill: #6D6D6D;
  transition: color linear 200ms, fill linear 200ms;

  &:hover,
  &.operator-dropdown__field--active {
    color: #000;
    fill: #000;
  }

  .operator-dropdown__selection {
    display: flex;
    align-items: center;
    gap: 4px;

    .operator-logo {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.operator-dropdown__icon {
  width: 1rem;
  height: 1rem;
}

.operator-dropdown__list {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
  overflow: hidden;
  min-width: 8.75rem;
}

.operator-dropdown__client-group {
  overflow: hidden;
  color: #6D6D6D;
  text-overflow: ellipsis;
  font-family: Rubik;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 0.5rem 0.5rem 0;
  height: 1.25rem;
}

.operator-dropdown__super-admin-group {
  border-bottom: 1px solid #ddd;
}

.operator-dropdown__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0 0.5rem;
  height: 2rem;
  cursor: pointer;

  &:hover {
    background-color: #FAFAFA;
  }

  .odd__list-item--left-side {
    display: flex;
    align-items: center;
    gap: 4px;

    .operator-logo {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &:hover {
    background-color: #FAFAFA;
  }
}
</style>
