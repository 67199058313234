import { throttle } from 'lodash';
import { ref, watch } from 'vue';
import { useWindowScroll } from '@vueuse/core';
import useBreakpoints from './useBreakpoints';

export default (options) => {
  const {
    initialValue = false,
    mobileOnly = false,
  } = options ?? {};

  const showMenu = ref(initialValue);
  const { y } = useWindowScroll();
  const { isMobile } = useBreakpoints();
  const update = throttle((newValue, oldValue) => {
    if (!isMobile.value && mobileOnly) return;

    if (newValue > 120 && newValue > oldValue) {
      showMenu.value = false;
    } else {
      showMenu.value = true;
    }
  }, 100);

  watch(y, update, { immediate: true });

  return {
    showMenu,
  };
};
