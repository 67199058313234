<template>
  <div class="mobile-competition-filter">
    <ScrollControl
      hide-controls
    >
      <CompetitionFilter />
    </ScrollControl>
  </div>
</template>

<script>
import ScrollControl from '@/components/common/ScrollControl';
import CompetitionFilter from '@/components/event-list/common/CompetitionFilter';

export default {
  components: {
    ScrollControl,
    CompetitionFilter,
  },
};
</script>

<style lang="scss">
.mobile-competition-filter {
  position: fixed;
  top: 7rem;
  width: 100%;
  z-index: 250;

  &::before {
    background: #FAFAFA;
    position: absolute;
    top: -0.75rem;
    left: 0;
    width: 100%;
    height: calc(100% + 1.55rem);
    content: '';
  }

  .scroll-control {
    height: auto;
  }
}
</style>
