<template>
  <component
    :is="icon"
    :class="[
      'baseball-clock',
      `baseball-clock--is-${icon}`,
    ]"
    :name="icon"
  />
</template>

<script>
import { computed } from 'vue';
import { includes } from 'lodash';
import { BaseballIcon, PitcherIcon, HitterIcon } from '@/components/svg';

export default {
  components: {
    baseball: BaseballIcon,
    pitcher: PitcherIcon,
    hitter: HitterIcon,
  },
  props: {
    side: {
      type: String,
      required: true,
    },
    teamInPossession: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isActive = computed(() => includes(['HOME', 'AWAY'], props.teamInPossession));
    const icon = computed(() => {
      if (!isActive.value) return 'baseball';
      if (props.side === props.teamInPossession) return 'hitter';
      return 'pitcher';
    });

    return {
      isActive,
      icon,
    };
  },
};
</script>

<style lang="scss">
.baseball-clock {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  fill: #CDCDCD;

  &--is-pitcher {
    fill: #fff;

    path {
      stroke: #175FFF;
    }
  }

  &--is-hitter {
    path {
      fill: #175FFF;
    }
  }
}
</style>
