import {
  max,
  includes,
  clamp,
  assign,
  cloneDeep,
} from 'lodash';

const PERIODS = [
  'PREMATCH',
  'IN_FIRST_HALF',
  'AT_HALF_TIME',
  'IN_SECOND_HALF',
  'NORMAL_TIME_COMPLETED',
  'IN_EXTRATIME_FIRST_HALF',
  'AT_EXTRATIME_HALF_TIME',
  'IN_EXTRATIME_SECOND_HALF',
  'EXTRA_TIME_COMPLETED',
  'IN_PENALTY_SHOOTOUTS',
  'EVENT_COMPLETED',
  'N_A',
];

const PERIODS_ABBREVATION_MAP = {
  IN_FIRST_HALF: '1. HT',
  AT_HALF_TIME: 'Half Time',
  IN_SECOND_HALF: '2. HT',
  IN_EXTRATIME_FIRST_HALF: '1. ET',
  IN_EXTRATIME_SECOND_HALF: '2. ET',
  AT_EXTRATIME_HALF_TIME: 'Break',
  NORMAL_TIME_COMPLETED: 'Break',
  SUSPENDED: 'Suspended',
  EVENT_COMPLETED: 'End',
};

const LIVE_PERIODS = [
  'IN_FIRST_HALF',
  'IN_SECOND_HALF',
  'IN_EXTRATIME_FIRST_HALF',
  'IN_EXTRATIME_SECOND_HALF',
];

const NON_LIVE_PERIODS = [
  'PREMATCH',
  'EVENT_COMPLETED',
  'N_A',
];

const OVER_PERIODS = [
  'EVENT_COMPLETED',
];

const livePeriods = [
  'IN_FIRST_HALF',
  'AT_HALF_TIME',
  'IN_SECOND_HALF',
  'NORMAL_TIME_COMPLETED',
  'IN_EXTRATIME_FIRST_HALF',
  'AT_EXTRATIME_HALF_TIME',
  'IN_EXTRATIME_SECOND_HALF',
  'EXTRA_TIME_COMPLETED',
  'IN_PENALTY_SHOOTOUTS',
];

const getLivePeriods = () => [...livePeriods];

const getPeriodTime = ({
  period,
  elapsedSecondInPeriod,
  halfDuration,
  extraTimeHalfDuration,
}) => {
  const elapsedMinute = parseInt((elapsedSecondInPeriod || 0) / 60, 10) + 1;
  switch (period) {
  case 'IN_FIRST_HALF':
    return {
      currentMinute: clamp(elapsedMinute, 1, halfDuration),
      currentExtraMinute: max([0, elapsedMinute - halfDuration]),
    };
  case 'IN_SECOND_HALF':
    const secondHalfOffset = halfDuration;
    const secondHalfCurrentMinute = elapsedMinute + secondHalfOffset;

    return {
      currentMinute: clamp(secondHalfCurrentMinute, secondHalfOffset, secondHalfOffset + halfDuration),
      currentExtraMinute: max([0, secondHalfCurrentMinute - (secondHalfOffset + halfDuration)]),
    };
  case 'IN_EXTRATIME_FIRST_HALF':
    const firstHalfExtraOffset = halfDuration * 2;
    const firstHalfExtraCurrentMinute = elapsedMinute + firstHalfExtraOffset;

    return {
      currentMinute: clamp(firstHalfExtraCurrentMinute, firstHalfExtraOffset + extraTimeHalfDuration),
      currentExtraMinute: max([0, firstHalfExtraCurrentMinute - (firstHalfExtraOffset + extraTimeHalfDuration)]),
    };
  case 'IN_EXTRATIME_SECOND_HALF':
    const secondHalfExtraOffset = (halfDuration * 2) + extraTimeHalfDuration;
    const secondHalfExtraCurrentMinute = elapsedMinute + secondHalfExtraOffset;

    return {
      currentMinute: clamp(secondHalfExtraCurrentMinute, secondHalfExtraOffset, secondHalfExtraOffset + extraTimeHalfDuration),
      currentExtraMinute: max([0, secondHalfExtraCurrentMinute - (secondHalfExtraOffset + extraTimeHalfDuration)]),
    };
  default:
    return undefined;
  }
};

const getPeriodLabel = ({
  period,
  elapsedSecondInPeriod,
  halfDuration,
  extraTimeHalfDuration,
}) => {
  const label = PERIODS_ABBREVATION_MAP[period];

  if (!includes(LIVE_PERIODS, period)) {
    return label;
  }

  const time = getPeriodTime({
    period,
    elapsedSecondInPeriod,
    halfDuration,
    extraTimeHalfDuration,
  });

  const labelBase = `${label} - ${time.currentMinute}`;
  const labelAddon = `+${time.currentExtraMinute}`;
  return `${labelBase}${time.currentExtraMinute ? labelAddon : ''}'`;
};

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      homeScore: payload.state.homeScore,
      homeScoreFirstHalf: payload.state.homeScoreFirstHalf,
      homeScoreSecondHalf: payload.state.homeScoreSecondHalf,
      homeYellowCards: payload.competitors.homeTeam.yellowCards,
      homeRedCards: payload.competitors.homeTeam.redCards,
      homeGoals: payload.competitors.homeTeam.goals,
      homeCorners: payload.competitors.homeTeam.corners,
      homeFouls: payload.competitors.homeTeam.fouls,
      homeShotsOnTarget: payload.competitors.homeTeam.shotsOnTarget,
      homeShotsOffTarget: payload.competitors.homeTeam.shotsOffTarget,
      homeSubstitutions: payload.competitors.homeTeam.substitutions,
      awayScore: payload.state.awayScore,
      awayScoreFirstHalf: payload.state.awayScoreFirstHalf,
      awayScoreSecondHalf: payload.state.awayScoreSecondHalf,
      awayYellowCards: payload.competitors.awayTeam.yellowCards,
      awayRedCards: payload.competitors.awayTeam.redCards,
      awayGoals: payload.competitors.awayTeam.goals,
      awayCorners: payload.competitors.awayTeam.corners,
      awayFouls: payload.competitors.awayTeam.fouls,
      awayShotsOnTarget: payload.competitors.awayTeam.shotsOnTarget,
      awayShotsOffTarget: payload.competitors.awayTeam.shotsOffTarget,
      awaySubstitutions: payload.competitors.awayTeam.substitutions,
      period: payload.state.period,
      elapsedSecondInPeriod: payload.state.elapsedSecondInPeriod,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
    },
  );

  return updatedEventDetails;
};

export default {
  PERIODS,
  LIVE_PERIODS,
  NON_LIVE_PERIODS,
  OVER_PERIODS,

  updateEvent,
  getPeriodTime,
  getPeriodLabel,
  getLivePeriods,
};
