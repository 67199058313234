<template>
  <transition name="fade">
    <div
      v-if="open"
      class="backdrop"
      @click="close"
      :style="{ zIndex }"
    />
  </transition>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 9990,
    },
  },
  emits: {
    'update:open': {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const close = () => {
      emit('update:open', false);
    };

    return {
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize(#191414, 0.4);
  z-index: 9990;
}
</style>
