import { includes } from 'lodash';

const SUPER_ADMIN_CLIENT = 'Huddle';

export const getOperatorLogo = (operator) => {
  try {
    // eslint-disable-next-line
    return require(`/public/images/clients/${operator}.png`);
  } catch (err) {
    return null;
  }
};

export const getSuperAdminData = () => {
  // Checking if user is super admin
  const huddleRoles = localStorage.getItem('huddleDemoRoles');
  const isSuperAdmin = includes(huddleRoles, 'SUPER_ADMIN');

  // Getting client info from query search
  const urlParams = new URLSearchParams(window.location.search);
  const client = urlParams.get('client') || SUPER_ADMIN_CLIENT;

  // Check if Huddle is selected
  const isSuperAdminSelected = isSuperAdmin && client === SUPER_ADMIN_CLIENT;

  return {
    isSuperAdmin,
    client,
    SUPER_ADMIN_CLIENT,
    isSuperAdminSelected,
  };
};
