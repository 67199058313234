<template>
  <div class="event-market">
    <div class="event-market-selections">
      <EventMarketSelections
        :market="market"
        :betslip-market-name="betslipMarketName"
        :bookmakers="bookmakerGroups"
        :teams="teams"
        :sport-label="sport.sportLabel"
        :event-id="eventId"
        :has-sgp="hasSgp"
        :markets-internal-source="marketsInternalSource"
        :is-usa-view="isUsaView"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import EventMarketSelections from './EventMarketSelections';

export default {
  components: {
    EventMarketSelections,
  },
  props: {
    marketId: {
      type: String,
      required: true,
    },
    betslipMarketName: {
      type: String,
      required: true,
    },
    sport: {
      type: Object,
      default: null,
    },
    competition: {
      type: Object,
      default: null,
    },
    teams: {
      type: Object,
      default: null,
    },
    eventId: {
      type: String,
      default: null,
    },
    hasSgp: {
      type: Boolean,
      default: false,
    },
    marketsInternalSource: {
      type: String,
      required: true,
    },
    bookmakerGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const market = computed(() => store.getters.getEventMarketById(props.marketId));

    return {
      market,
    };
  },
};

</script>

<style lang="scss">
  .event-market {
    width: 100%;

    .market-name {
      height: 44px;
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: #003C3C;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #003C3C;
      border-radius: 4px;
      cursor: pointer;

      .market-name-icon {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px;

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    .event-market-selections {
      padding-top: 16px;
    }
  }
</style>
