import {
  split,
  startsWith,
  indexOf,
  cloneDeep,
  assign,
  orderBy,
} from 'lodash';
import { formatMinutesAndSeconds } from '@/utils/number';

const PERIODS = [
  'PRE_GAME',
  'FIRST_PERIOD',
  'BEFORE_SECOND_PERIOD',
  'SECOND_PERIOD',
  'FIRST_HALF',
  'HALF_TIME',
  'THIRD_PERIOD',
  'BEFORE_FOURTH_PERIOD',
  'FOURTH_PERIOD',
  'SECOND_HALF',
  'BEFORE_OVERTIME',
  'OVERTIME_1',
  'OVERTIME_2',
  'OVERTIME_3',
  'OVERTIME_4',
  'OVERTIME_5',
  'OVERTIME_6',
  'OVERTIME_7',
  'OVERTIME_8',
  'OVERTIME_9',
  'OVERTIME_10',
  'POST_GAME',
  'GAME_ABANDONED',
];

const PERIOD_ORDINAL_MAPPER = {
  TOTAL: 'Match',
  PRE_GAME: 'Pregame',
  FIRST_PERIOD: '1st quarter',
  SECOND_PERIOD: '2nd quarter',
  THIRD_PERIOD: '3rd quarter',
  FOURTH_PERIOD: '4th quarter',
  OVERTIME_1: 'Overtime',
  OVERTIME_2: '2nd overtime',
  OVERTIME_3: '3rd overtime',
  OVERTIME_4: '4th overtime',
  OVERTIME_5: '5th overtime',
  OVERTIME_6: '6th overtime',
  OVERTIME_7: '7th overtime',
  OVERTIME_8: '8th overtime',
  OVERTIME_9: '9th overtime',
  OVERTIME_10: '10th overtime',
};

const PERIOD_SHORT_ORDINAL_MAPPER = {
  TOTAL: 'Match',
  PRE_GAME: 'Pregame',
  FIRST_PERIOD: '1st Q',
  SECOND_PERIOD: '2nd Q',
  THIRD_PERIOD: '3rd Q',
  FOURTH_PERIOD: '4th Q',
  OVERTIME_1: 'OT',
  OVERTIME_2: '2nd OT',
  OVERTIME_3: '3rd OT',
  OVERTIME_4: '4th OT',
  OVERTIME_5: '5th OT',
  OVERTIME_6: '6th OT',
  OVERTIME_7: '7th OT',
  OVERTIME_8: '8th OT',
  OVERTIME_9: '9th OT',
  OVERTIME_10: '10th OT',
};

const NON_LIVE_PERIODS = [
  'PRE_GAME',
  'POST_GAME',
  'GAME_ABANDONED',
];

const OVER_PERIODS = [
  'POST_GAME',
];

const livePeriods = [
  'FIRST_PERIOD',
  'BEFORE_SECOND_PERIOD',
  'SECOND_PERIOD',
  'HALF_TIME',
  'THIRD_PERIOD',
  'BEFORE_FOURTH_PERIOD',
  'FOURTH_PERIOD',
  'BEFORE_OVERTIME',
  'OVERTIME_1',
  'OVERTIME_2',
  'OVERTIME_3',
  'OVERTIME_4',
  'OVERTIME_5',
  'OVERTIME_6',
  'OVERTIME_7',
  'OVERTIME_8',
  'OVERTIME_9',
  'OVERTIME_10',
];

const ncaaLivePeriods = [
  'FIRST_HALF',
  'HALF_TIME',
  'SECOND_HALF',
  'BEFORE_OVERTIME',
  'OVERTIME_1',
  'OVERTIME_2',
  'OVERTIME_3',
  'OVERTIME_4',
  'OVERTIME_5',
  'OVERTIME_6',
  'OVERTIME_7',
  'OVERTIME_8',
  'OVERTIME_9',
  'OVERTIME_10',
];

const getLivePeriods = ({ competitionType }) => (competitionType === 'NCAA' ? ncaaLivePeriods : livePeriods);

const getPeriodAbbreviation = (inPlayPeriodCode, competitionType) => {
  if (competitionType === 'NCAA') {
    switch (inPlayPeriodCode) {
    case 'FIRST_PERIOD':
      return '1st H';
    case 'SECOND_PERIOD':
      return '2nd H';
    default:
      // Continue
    }
  }

  switch (inPlayPeriodCode) {
  case 'PRE_GAME':
    return 'Pregame';
  case 'FIRST_PERIOD':
    return '1st Q';
  case 'SECOND_PERIOD':
    return '2nd Q';
  case 'THIRD_PERIOD':
    return '3rd Q';
  case 'FOURTH_PERIOD':
    return '4th Q';
  case 'OVERTIME_1':
    return '1st OT';
  case 'OVERTIME_2':
    return '2nd OT';
  case 'OVERTIME_3':
    return '3rd OT';
  case 'OVERTIME_4':
    return '4th OT';
  case 'OVERTIME_5':
    return '5th OT';
  case 'OVERTIME_6':
    return '6th OT';
  case 'OVERTIME_7':
    return '7th OT';
  case 'OVERTIME_8':
    return '8th OT';
  case 'OVERTIME_9':
    return '9th OT';
  case 'OVERTIME_10':
    return '10th OT';
  case 'TOTAL':
    return 'Total';
  default:
    return '';
  }
};

const getPeriodOrdinal = (inPlayPeriodCode) => PERIOD_ORDINAL_MAPPER[inPlayPeriodCode];

const getPeriodShortOrdinal = (inPlayPeriodCode) => PERIOD_SHORT_ORDINAL_MAPPER[inPlayPeriodCode];

const getPeriodTime = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const getPeriodLabel = ({
  period,
  periodLengthInMinutes,
  overtimeLengthInMinutes,
  secondsLeftInPeriod,
  competitionType,
}, {
  shortLabel = false,
} = {}) => {
  if (!period) return '';

  const time = getPeriodTime({
    period,
    periodLengthInMinutes,
    overtimeLengthInMinutes,
    secondsLeftInPeriod,
  });

  if (startsWith(period, 'OVERTIME_')) {
    const values = split(period, '_');
    return `${values[1]}. OT • ${time}`;
  }

  if (competitionType === 'NCAA') {
    switch (period) {
    case 'FIRST_PERIOD':
      return shortLabel ? `H1 • ${time}` : `1. half • ${time}`;
    case 'SECOND_PERIOD':
      return shortLabel ? `H2 • ${time}` : `2. half • ${time}`;
    default:
      // Continue
    }
  }

  switch (period) {
  case 'FIRST_PERIOD':
    return shortLabel ? `Q1 • ${time}` : `1. QTR • ${time}`;
  case 'SECOND_PERIOD':
    return shortLabel ? `Q2 • ${time}` : `2. QTR • ${time}`;
  case 'THIRD_PERIOD':
    return shortLabel ? `Q3 • ${time}` : `3. QTR • ${time}`;
  case 'FOURTH_PERIOD':
    return shortLabel ? `Q4 • ${time}` : `4. QTR • ${time}`;
  case 'HALF_TIME':
    return 'Halftime';
  case 'BEFORE_SECOND_PERIOD':
  case 'BEFORE_THIRD_PERIOD':
  case 'BEFORE_FOURTH_PERIOD':
    return 'Break';
  case 'POST_GAME':
    return 'End';
  case 'GAME_ABANDONED':
    return 'Suspended';
  default:
    return '';
  }
};

const getPeriodIndex = (periodCode) => {
  if (periodCode === 'TOTAL') return 0;
  return indexOf(PERIODS, periodCode) + 1;
};

const isActivePeriod = (periodCode) => !!PERIOD_ORDINAL_MAPPER[periodCode];

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      lastTeamToScore: payload.state.lastTeamToScore,
      ftsToCome: payload.state.ftsToCome,
      teamInPossession: payload.state.teamInPossession,
      period: payload.state.currentClock.period,
      secondsLeftInPeriod: payload.state.currentClock.secondsLeftInPeriod,
      points: payload.state.points,
      scorePerPeriod: payload.state.scorePerPeriod,
      threePointersPerPeriod: payload.state.threePointersPerPeriod,
      twoPointersPerPeriod: payload.state.twoPointersPerPeriod,
      onePointersPerPeriod: payload.state.onePointersPerPeriod,
      foulsPerPeriod: payload.state.foulsPerPeriod,
      homeScore: payload.state.homeScore,
      awayScore: payload.state.awayScore,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      actions: payload?.state?.actions,
    },
  );

  return updatedEventDetails;
};

const sortPeriods = (periods) => orderBy(periods, (period) => getPeriodIndex(period));

export default {
  PERIODS,
  NON_LIVE_PERIODS,
  OVER_PERIODS,
  getLivePeriods,
  getPeriodOrdinal,
  getPeriodShortOrdinal,
  getPeriodAbbreviation,
  getPeriodTime,
  getPeriodLabel,
  getPeriodIndex,
  isActivePeriod,
  updateEvent,
  sortPeriods,
};
