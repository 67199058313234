<template>
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.28599 12.7203C4.54932 13.9837 6.22265 14.679 7.99999 14.679C9.77732 14.679 11.4513 13.9837 12.714 12.7203C15.3133 10.121 15.3133 5.89167 12.714 3.29167C11.4513 2.02901 9.77665 1.33301 7.99999 1.33301C6.22332 1.33301 4.54865 2.02901 3.28599 3.29167C0.686653 5.89101 0.686653 10.121 3.28599 12.7203ZM11.7713 11.7777C11.0727 12.4783 10.1923 12.9698 9.22932 13.197C9.22332 12.129 9.61999 11.0997 10.3567 10.363C11.132 9.58768 12.178 9.14434 13.208 9.12901C12.9956 10.1324 12.4965 11.0525 11.7713 11.7777ZM13.3213 7.80101C11.9053 7.78701 10.4653 8.36967 9.41399 9.42034C8.39265 10.4417 7.86265 11.8737 7.90532 13.341C6.52246 13.3199 5.20269 12.7588 4.22799 11.7777C3.25207 10.8013 2.69449 9.48335 2.67332 8.10301C2.72465 8.10434 2.77599 8.11234 2.82732 8.11234C4.23732 8.11234 5.60065 7.57634 6.58532 6.59234C7.63865 5.53901 8.22332 4.09967 8.20532 2.67701C9.54857 2.72525 10.8233 3.28218 11.7713 4.23501C12.7205 5.18476 13.2744 6.45905 13.3213 7.80101ZM4.22865 4.23501C4.95319 3.50709 5.87321 3.00482 6.87732 2.78901C6.86532 3.82567 6.42065 4.87167 5.64332 5.64967C4.90732 6.38501 3.88199 6.78034 2.81799 6.77701C3.04138 5.81448 3.53009 4.93384 4.22865 4.23501Z"
    />
  </svg>
</template>
