<template>
  <div :class="['event-markets', { 'no-content': !marketDisplayConfigurations.length}]">
    <div class="event-markets-header">
      <EventDetailsNavigation
        :event="event"
        v-if="displayNavigation"
      />
      <div class="title-search">
        <div
          class="event-markets-header-label"
          v-if="!displayNavigation"
        >
          Event odds
        </div>
        <div :class="['event-markets-header-search', { 'full-width': displayNavigation }]">
          <div class="search-input">
            <div class="search-icon">
              <SearchIcon />
            </div>
            <input
              v-model="search"
              @input="searchMarkets"
              placeholder="Search markets"
            >
          </div>
        </div>
      </div>
      <div
        class="market-groups"
      >
        <div
          v-if="scrollLeftButtonActive"
          class="chevron-left"
          @click="onManualMarketGroupScroll('left')"
        >
          <ChevronRightIcon />
        </div>
        <div
          v-if="scrollRightButtonActive"
          class="chevron-right"
          @click="onManualMarketGroupScroll('right')"
        >
          <ChevronRightIcon />
        </div>
        <div
          ref="marketGroupsScroll"
          @scroll="onMarketGroupScroll"
          class="market-groups__scroll-container"
        >
          <div
            v-for="group in marketGroups"
            :key="group.code"
            :class="['market-group-item ', selectedMarketGroup.code === group.code && 'active']"
            @click="selectMarketGroup(group)"
          >
            {{ group.displayName }}
          </div>
        </div>
        <div class="market-groups__scroll-container__bottom-line" />
      </div>
    </div>
    <div class="event-markets-content">
      <div
        class="no-content-found"
        v-if="!marketDisplayConfigurations.length && !search"
      >
        <NotFoundIcon />
        No odds for this event at the moment
      </div>
      <div
        class="no-content-found"
        v-if="!marketDisplayConfigurations.length && search"
      >
        <NoResultsIcon />
        No results found
      </div>
      <div class="event-markets-body">
        <EventMarketCodeSectionWrapper
          v-for="(marketDisplayConfiguration, index) in marketDisplayConfigurations"
          :key="index"
          :market-display-configuration="marketDisplayConfiguration"
          :markets-internal-source="marketsInternalSource"
          :sport="event.sport"
          :competition="event.competition"
          :event-id="event.id"
          :teams="teams"
          :has-sgp="event.hasSgp"
          :is-usa-view="event.isUSAView"
          :all-market-parameters="allMarketParameters"
        />
      </div>
    </div>
    <div
      class="event-markets-footer"
      v-if="marketDisplayConfigurations.length || !marketDisplayConfigurations.length && search"
    >
      <div class="odds">
        <div class="odds-icon shortening" />
        <div>Odds shortening</div>
      </div>
      <div class="odds">
        <div class="odds-icon drifting" />
        <div>Odds drifting</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, computed, reactive,
  watch, onMounted, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import {
  filter, find, cloneDeep, map, isNil,
  includes, floor, orderBy, lowerCase,
} from 'lodash';
import {
  SearchIcon, NotFoundIcon, NoResultsIcon, ChevronRightIcon,
} from '@/components/svg';
import MarketMapper from '@/services/helpers/market-mapper';
import EventDetailsNavigation from './EventDetailsNavigation';
import EventMarketCodeSectionWrapper from './EventMarketCodeSectionWrapper';

export default {
  components: {
    SearchIcon,
    EventDetailsNavigation,
    NotFoundIcon,
    NoResultsIcon,
    ChevronRightIcon,
    EventMarketCodeSectionWrapper,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    displayNavigation: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const teams = computed(() => ({
      home: props.event.home,
      away: props.event.away,
    }));

    const search = ref('');
    const searchMarkets = () => {
      store.dispatch('setMarketsFilter', { search: search.value });
    };

    const marketsSearchFilter = computed(() => store.getters.marketsFilters);

    const selectedMarketGroup = computed(() => store.getters.selectedMarketGroup);
    const marketGroupsScroll = ref(null);
    const scrollLeftButtonActive = ref(false);
    const scrollRightButtonActive = ref(false);

    const match = reactive({
      state: cloneDeep(props.event.matchState),
    });

    const marketsInternalSource = computed(() => (props.event.hasDpsPrices ? 'DPS' : 'INTERNAL_AGGREGATION'));
    const internalMarkets = computed(() => store.getters.eventInternalMarkets);
    const externalMarkets = computed(() => store.getters.eventExternalMarkets);

    const eventDisplayMarkets = computed(() => orderBy(filter(store.getters.eventDisplayMarkets,
      (market) => market.active), 'position'));

    const filteredInternalMarkets = computed(() => {
      const markets = filter(
        internalMarkets.value,
        (market) => (includes(lowerCase(market.marketCode), marketsSearchFilter.value)
          || includes(lowerCase(market.marketSummary?.playerName), marketsSearchFilter.value)
          || (!isNil(market.marketType.params.TEAM) && includes(lowerCase(teams.value?.home?.name), marketsSearchFilter.value))
          || (!isNil(market.marketType.params.TEAM) && includes(lowerCase(teams.value?.away?.name), marketsSearchFilter.value))
          || includes(lowerCase(find(eventDisplayMarkets.value,
            (displayMarket) => displayMarket.marketCode === market.marketCode
              && displayMarket.selections === market.marketType.params.SELECTIONS)?.defaultName), marketsSearchFilter.value))
          && find(
            market.selections,
            (selection) => selection.price.decimalValue && selection.displayStatus === 'ON',
          )
          && (selectedMarketGroup.value.code === 'ALL'
            || (selectedMarketGroup.value.code !== 'ALL' && includes(selectedMarketGroup.value.markets, market.marketCode))),
      );
      if (match.state === 'FINISHED') {
        return [];
      }
      return match.state === 'LIVE' ? filter(markets, { inPlay: true }) : markets;
    });
    const marketDisplayConfigurations = computed(() => filter(map(eventDisplayMarkets.value, (marketConfiguration) => ({
      ...marketConfiguration,
      internalMarketsIds: map(filter(filteredInternalMarkets.value, (market) => market.marketCode === marketConfiguration.marketCode
      && market.marketType.params.SELECTIONS === marketConfiguration.selections), (filterMarket) => filterMarket.marketId),
      externalMarketsIds: map(filter(externalMarkets.value, (market) => market.marketCode === marketConfiguration.marketCode
      && market.marketType.params.SELECTIONS === marketConfiguration.selections), (filterMarket) => filterMarket.marketId),
    })), (marketDisplayConfiguration) => marketDisplayConfiguration.internalMarketsIds?.length));

    const allMarketParameters = computed(() => {
      const sport = store.getters.sportById(props.event.sportId);
      return store.getters.allMarketParameters(sport.sportLabel);
    });

    const marketGroups = computed(() => {
      let { marketGroupsBySport } = store.getters;
      // NCAA events don't have periods so we remove them from the market group list
      if (props.event?.competition?.id === 'd1303850-9f46-4ef3-bc0d-11e0b8477d69') {
        marketGroupsBySport = filter(marketGroupsBySport, (marketGroup) => marketGroup.code !== 'PERIOD-BASKETBALL');
      }
      return filter(
        MarketMapper.formatMarketGroups(marketGroupsBySport, internalMarkets.value, props.event.sport),
        (market) => market.matchingMarkets || market.code === 'ALL',
      );
    });
    const selectMarketGroup = (group) => {
      const scrollContainer = document.getElementsByTagName('html')[0];
      scrollContainer.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      store.dispatch('setSelectedMarketGroup', group);
    };

    const onMarketGroupScroll = () => {
      const { scrollLeft, clientWidth, scrollWidth } = marketGroupsScroll.value;
      scrollLeftButtonActive.value = scrollLeft > 0;
      scrollRightButtonActive.value = (floor(scrollLeft + clientWidth + 1)) < scrollWidth;
    };

    const onManualMarketGroupScroll = (scrollButton) => {
      const { clientWidth, scrollLeft } = marketGroupsScroll.value;
      const newScrollLeft = scrollButton === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
      marketGroupsScroll.value.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    };

    watch(() => props.event, (newValue, oldValue) => {
      if (oldValue.matchState !== newValue.matchState) match.state = newValue.matchState;
    });

    watch(marketGroups, (newMarketGroups) => {
      const { code = '' } = selectedMarketGroup.value ?? {};
      const foundMarketGroup = find(newMarketGroups, { code });
      if (foundMarketGroup) return;
      selectMarketGroup(newMarketGroups[0]);
    });

    onMounted(() => {
      const { clientWidth, scrollWidth } = marketGroupsScroll.value || {};
      scrollRightButtonActive.value = scrollWidth > clientWidth;
    });

    onUnmounted(() => {
      store.dispatch('setMarketsFilter', { search: '' });
    });

    return {
      search,
      marketGroups,
      selectedMarketGroup,
      marketsInternalSource,
      marketGroupsScroll,
      scrollLeftButtonActive,
      scrollRightButtonActive,
      searchMarkets,
      selectMarketGroup,
      onMarketGroupScroll,
      onManualMarketGroupScroll,
      teams,
      marketDisplayConfigurations,
      allMarketParameters,
    };
  },
};

</script>

<style lang="scss">
  .event-markets {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: calc(68px + 1.5rem);
    width: 100%;
    flex: 1;
    min-width: 0;

    &.no-content {
      height: fit-content;
    }

    .event-markets-header {
      width: 100%;
      padding: 1.5rem 1.5rem 1rem;
      background-color: #fff;
      position: sticky;
      top: calc(68px + 1.5rem);
      border: 1px solid #F0F0F0;
      border-bottom: none;
      border-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .title-search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
      }

      .market-groups {
        display: flex;
        white-space: nowrap;
        position: relative;

        .chevron-left,
        .chevron-right {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 24px;
          height: 100%;
          top: 0;
          background-color: #fff;
          z-index: 2;
          cursor: pointer;
        }

        .chevron-left {
          left: 0;
          .chevron-right-icon {
            transform: rotate(180deg);
          }
        }
        .chevron-right {
          right: 0;
        }

        .market-groups__scroll-container__bottom-line {
          position: absolute;
          height: 2px;
          width: 100%;
          background-color: #cdcdcd;
          z-index: 0;
          bottom: 0;
        }

        .market-groups__scroll-container {
          white-space:nowrap;
          position: relative;
          z-index: 1;
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .market-group-item {
          display: inline-block;
          padding: 14px 6px;
          cursor: pointer;

          &:hover {
            border-bottom: 2px solid #99b2b1;
          }

          &.active {
            border-bottom: 2px solid #003C3C;
          }
        }
      }

      &::after {
        position: absolute;
        background-color: #FAFAFA;
        left: -1px;
        top: calc(-1.5rem - 1px);
        width: calc(100% + 2px);
        height: 1.5rem;
        content: '';
      }

      .event-markets-header-label {
        font-weight: 600;
        font-size: 16px;
        color: #000;
      }

      .event-markets-header-search {
        width: 280px;
        height: 44px;

        &.full-width {
          width: 100%;
        }

        .search-input {
          height: 100%;
          width: 100%;
          border: 1px solid #DDDDDD;
          box-sizing: border-box;
          border-radius: 4px;
          display: flex;
          position: relative;

          .search-icon {
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);

            svg {
              height: 16px;
              width: 16px;
              stroke: #191414;
            }
          }

          input {
            outline: none;
            border: 0;
            height: 100%;
            width: 100%;
            color: #191414;
            border-radius: 4px;
            background-color: #FFF;
            font-size: 14px;
            padding-left: 44px;

            &::placeholder {
              color: #DDDDDD;
            }

            &:focus {
              box-shadow:  0 0 0 2px #658A8A;
              outline: none;
            }
          }
        }
      }
    }

    .event-markets-content {
      padding: 0 1.5rem 0;
      background-color: #fff;
      border-left: 1px solid #F0F0F0;
      border-right: 1px solid #F0F0F0;
    }

    .event-markets-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #191414;
      padding-bottom: 16px;

      .event-market {
        width: 100%;
      }
    }

    .event-markets-footer {
      min-height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      background-color: #fff;
      padding: 0 1.5rem;
      border: 1px solid #F0F0F0;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      position: sticky;
      bottom: 0;

      .odds {
        display: flex;
        height: 16px;
        align-items: center;
        margin-right: 24px;

        .odds-icon {
          height: 16px;
          width: 16px;
          border-radius: 4px;
          margin-right: 8px;

          &.shortening {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #00BC57;
          }

          &.drifting {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #FF2E2D;
          }
        }
      }
    }
  }

  .no-content-found {
    flex: 1;
    align-items: center;
    justify-content: center;
    color: #A9A9A9;
    font-size: 1rem;
    gap: 0;
    width: 25rem;
    margin: 0 auto;
    text-align: center;
    padding-top: 24px;
    margin-bottom: 45px;

    svg {
      width: 100%;
      margin-bottom: 1.5rem;
      }
  }

@media screen and (max-width: $maxMobileBreakpoint) {
  .event-markets {
    width: 100%;
    display: flex;
    flex-direction: column;

    .event-markets-header {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      border: none;
      border-radius: none;
      top: 56px;
      padding: 1rem;

      .market-groups {

        .chevron-left {
          width: 44px;
          justify-content: flex-start;
          background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 46.35%, rgba(255, 255, 255, 0) 100%);
          .chevron-right-icon {
            margin-left: 5px;
          }
        }
        .chevron-right {
          width: 44px;
          justify-content: flex-end;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 46.35%, #FFFFFF 100%);
          .chevron-right-icon {
            margin-right: 5px;
          }
        }
      }

      &::after {
        content: unset;
      }

      .event-markets-header-label {
        font-size: 14px;
        margin-bottom: 8px;
      }

      .event-markets-header-search {
        width: 100%;
      }
    }

    .event-markets-content {
      padding: 0 1rem;
    }

    .event-markets-footer {
      font-size: 12px;
      border: none;
      border-top: 1px solid #f0f0f0;
      padding: 0 1rem;
    }
  }

  .no-content-found {
    font-size: 14px;
    width: 17rem;
    padding-top: 0;
    margin-bottom: 20px;

    svg {
      margin-bottom: 0;
    }
  }
}
</style>
