<template>
  <ScrollControl>
    <div class="sport-filter">
      <div
        v-for="sport in sports"
        :key="sport.sportId"
        :class="[
          'sport-filter__item',
          { 'sport-filter__item--selected': sport.sportId === selectedSport.sportId },
        ]"
        :ref="ref => itemRefs[sport.sportId] = ref"
        @click="selectSport(sport)"
      >
        <div
          :class="[
            'sport-filter__icon',
            { 'sport-filter__icon--stroke': sport.sportLabel === 'VOLLEYBALL' },
          ]"
        >
          <SportIcon
            :sport-label="sport.sportLabel"
          />
        </div>
        {{ sport.sportName }}
      </div>
    </div>
  </ScrollControl>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import ScrollControl from '@/components/common/ScrollControl';
import SportIcon from '@/components/common/SportIcon';

export default {
  components: {
    ScrollControl,
    SportIcon,
  },
  setup() {
    const store = useStore();
    const sports = computed(() => store.getters.sports);
    const selectedSport = computed(() => {
      const sportId = store.getters.selectedSportId;
      return store.getters.sportById(sportId);
    });

    const itemRefs = ref({});
    const selectionTop = computed(() => {
      const itemRef = itemRefs.value[selectedSport.value?.sportId];
      if (!itemRef) return 0;
      return itemRef.offsetTop;
    });

    const selectSport = ({ sportId }) => {
      if (selectedSport.value?.sportId === sportId) return;
      store.dispatch('selectSport', sportId);
    };

    return {
      sports,
      selectedSport,
      itemRefs,
      selectionTop,
      selectSport,
    };
  },
};
</script>

<style lang="scss" scoped>
.sport-filter {
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 0.875rem;
  padding: 0 1rem;

  &::before,
  &::after {
    background: #CDCDCD;
    position: absolute;
    bottom: 0rem;
    width: 1rem;
    height: 2px;
    content: '';
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

.sport-filter__icon {
  fill: inherit;
  stroke: none;
  height: 1rem;
  margin-right: 0.25rem;
  aspect-ratio: 1 / 1;

  &.sport-filter__icon--stroke {
    fill: none;
    stroke: inherit;
  }
}

.sport-filter__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  font-size: 0.625rem;
  color: #6D6D6D;
  fill: #6D6D6D;
  text-transform: uppercase;
  font-weight: 600;
}

.sport-filter__body {
  display: flex;
  overflow-x: scroll;
  padding: 0 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sport-filter__item {
  display: flex;
  align-items: center;
  height: 2.75rem;
  user-select: none;
  fill: #000;
  stroke: #000;
  white-space: nowrap;
  background-color: #fff;
  padding: 0 0.5rem;
  border-bottom: 2px solid #CDCDCD;

  &.sport-filter__item--selected {
    border-bottom-color: #003C3C;
  }
}
</style>
