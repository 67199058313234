import { ref, computed, onMounted } from 'vue';
import { concat } from 'lodash';
import { getTeamSquad } from '@/services/api';
import { mapSquad, mapTeams } from '@/services/helpers/players';

export default (event) => {
  const loading = ref(false);
  const homeSquad = ref([]);
  const awaySquad = ref([]);
  const teams = computed(() => mapTeams(event));
  const squad = computed(() => concat([], homeSquad.value, awaySquad.value));

  const loadSquad = async () => {
    try {
      loading.value = true;

      const homeResponse = await getTeamSquad({
        teamId: event.home.id,
        sportId: event.sportId,
      });
      const awayResponse = await getTeamSquad({
        teamId: event.away.id,
        sportId: event.sportId,
      });

      homeSquad.value = mapSquad({
        teams: teams.value,
        squad: homeResponse?.squad ?? [],
      });
      awaySquad.value = mapSquad({
        teams: teams.value,
        squad: awayResponse?.squad ?? [],
      });
    } catch (error) {
      console.error(error);
      homeSquad.value = [];
      awaySquad.value = [];
    } finally {
      loading.value = false;
    }
  };

  onMounted(loadSquad);

  return {
    loading,
    homeSquad,
    awaySquad,
    squad,
  };
};
