import { assign, cloneDeep } from 'lodash';

const getPeriodAbbreviation = ({ period, periodStatus, isClockRunning }, { shortLabel = false } = []) => {
  if (!isClockRunning) return 'BREAK';
  const [, number] = period.split('_');
  const prefix = periodStatus === 'TOP' ? '▲' : '▼';
  return shortLabel ? `${prefix} I${number}` : `${prefix} ${number}. INN`;
};

const getPeriodLabel = (details) => getPeriodAbbreviation(details);

const getLivePeriods = () => [
  'INNING_1',
  'INNING_2',
  'INNING_3',
  'INNING_4',
  'INNING_5',
  'INNING_6',
  'INNING_7',
  'INNING_8',
  'INNING_9',
  'INNING_10',
  'INNING_11',
  'INNING_12',
  'INNING_13',
  'INNING_14',
];

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      currentTeamABatterProviderId: payload.state.currentTeamABatterProviderId,
      currentTeamBPitcherProviderId: payload.state.currentTeamBPitcherProviderId,
      currentTeamAPitcherId: payload.state.currentTeamAPitcherId,
      currentTeamBPitcherId: payload.state.currentTeamBPitcherId,
      currentTeamABatterId: payload.state.currentTeamABatterId,
      currentTeamBBatterId: payload.state.currentTeamBBatterId,
      inningDetails: payload.state.inningDetails,
      lastUpdated: payload.state.lastUpdated,
      loadedBases: payload.state.loadedBases,
      matchHits: payload.state.matchHits,
      matchHomeRuns: payload.state.matchHomeRuns,
      matchRuns: payload.state.matchRuns,
      pitchCount: payload.state.pitchCount,
      raceToMap: payload.state.raceToMap,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      strikeOuts: payload.state.strikeOuts,
      eventInBreak: payload.state.eventInBreak,
      eventPreMatch: payload.state.eventPreMatch,
    },
  );

  return updatedEventDetails;
};

export default {
  getPeriodAbbreviation,
  getPeriodLabel,
  updateEvent,
  getLivePeriods,
};
