<template>
  <div>
    <component :is="iconName" />
  </div>
</template>

<script>
import { computed } from 'vue';
import {
  BaseballIcon,
  BasketballIcon,
  HockeyIcon,
  FootballIcon,
  SoccerIcon,
  TennisIcon,
  TrophyIcon,
  UltimateIcon,
  VolleyballIcon,
} from '@/components/svg';

export default {
  components: {
    BaseballIcon,
    BasketballIcon,
    HockeyIcon,
    FootballIcon,
    SoccerIcon,
    TennisIcon,
    TrophyIcon,
    UltimateIcon,
    VolleyballIcon,
  },
  props: {
    sportLabel: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const iconName = computed(() => {
      switch (props.sportLabel) {
      case 'BASEBALL':
        return 'BaseballIcon';
      case 'BASKETBALL':
        return 'BasketballIcon';
      case 'ICE_HOCKEY':
        return 'HockeyIcon';
      case 'AMERICAN_FOOTBALL':
      case 'FOOTBALL':
      case 'RUGBY_LEAGUE':
      case 'RUGBY_UNION':
        return 'FootballIcon';
      case 'SOCCER':
        return 'SoccerIcon';
      case 'TENNIS':
        return 'TennisIcon';
      case 'ULTIMATE':
        return 'UltimateIcon';
      case 'VOLLEYBALL':
        return 'VolleyballIcon';
      default:
        return 'TrophyIcon';
      }
    });

    return {
      iconName,
    };
  },
};
</script>
