<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/minus-circle-filled">
      <g id="Icon (Stroke)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.66699 8.50033C4.66699 8.13213 4.96547 7.83366 5.33366 7.83366H10.667C11.0352 7.83366 11.3337 8.13213 11.3337 8.50033C11.3337 8.86852 11.0352 9.16699 10.667 9.16699H5.33366C4.96547 9.16699 4.66699 8.86852 4.66699 8.50033Z"
          fill="#FF2E2D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.00033 1.16699C3.95024 1.16699 0.666992 4.45024 0.666992 8.50033C0.666992 12.5504 3.95024 15.8337 8.00033 15.8337C12.0504 15.8337 15.3337 12.5504 15.3337 8.50033C15.3337 4.45024 12.0504 1.16699 8.00033 1.16699Z"
          fill="#FF2E2D"
        />
        <path
          d="M5.33366 7.83366C4.96547 7.83366 4.66699 8.13213 4.66699 8.50033C4.66699 8.86852 4.96547 9.16699 5.33366 9.16699H10.667C11.0352 9.16699 11.3337 8.86852 11.3337 8.50033C11.3337 8.13213 11.0352 7.83366 10.667 7.83366H5.33366Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
</template>
