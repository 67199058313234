<template>
  <slot
    v-if="isMobile"
    name="mobile"
  />
  <slot
    v-else
    name="desktop"
  />
</template>

<script>
import { useBreakpoints } from '@/composables';

export default {
  setup() {
    const { isMobile } = useBreakpoints();

    return {
      isMobile,
    };
  },
};
</script>
