import { toUpper } from 'lodash';

function findByName(name) {
  const normalizedName = toUpper(name);
  const imgName = normalizedName === 'INTERNAL_AGGREGATION' ? 'HUDDLE' : normalizedName;
  try {
    // eslint-disable-next-line
    return require(`/public/images/${imgName}.png`);
  } catch (err) {
    return null;
  }
}

export default { findByName };
