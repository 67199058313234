<template>
  <div
    :class="[
      'boolean-indicator',
      { 'boolean-indicator--variant-success': isSuccessVariant },
      { 'boolean-indicator--variant-danger': isDangerVariant },
    ]"
  >
    <component :is="iconName" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { CheckIcon, XIcon } from '@/components/svg';

export default {
  components: {
    CheckIcon,
    XIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isSuccessVariant = computed(() => !!props.value);
    const isDangerVariant = computed(() => !props.value);
    const iconName = computed(() => (props.value ? 'CheckIcon' : 'XIcon'));

    return {
      isSuccessVariant,
      isDangerVariant,
      iconName,
    };
  },
};
</script>

<style lang="scss">
.boolean-indicator {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 16px;
  height: 16px;
  border-radius: 100%;

  svg {
    width: 100%;
    height: 100%;
    padding: 2px;
  }

  &--variant-success {
    background: #00BC57;

    svg {
      path {
        stroke: #fff;
      }
    }
  }

  &--variant-danger {
    background: #FF2E2D;

    svg {
      path {
        stroke: #fff;
      }
    }
  }
}
</style>
