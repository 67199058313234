<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2380_57588)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.59367 12.3874C3.55843 13.8668 3.36881 15.0677 3.7965 15.8085C4.22419 16.5493 5.359 16.9855 7.15782 16.8287C8.90754 16.6761 11.0823 15.9681 13.2482 14.7176C15.4142 13.4671 17.1147 11.9377 18.1217 10.4986C19.157 9.01925 19.3466 7.81836 18.9189 7.07758C18.4912 6.33679 17.3564 5.90057 15.5576 6.05741C13.8079 6.20998 11.6331 6.918 9.46715 8.16851C7.3012 9.41902 5.60065 10.9484 4.59367 12.3874ZM3.69242 11.7568C4.81067 10.1587 6.6441 8.52823 8.91715 7.21589C11.1902 5.90354 13.519 5.13099 15.462 4.96157C17.356 4.79643 19.1032 5.19683 19.8715 6.52758C20.6398 7.85833 20.113 9.57166 19.023 11.1293C17.9047 12.7274 16.0713 14.3578 13.7982 15.6702C11.5252 16.9825 9.19643 17.7551 7.25337 17.9245C5.3594 18.0896 3.61218 17.6893 2.84387 16.3585C2.07556 15.0278 2.60242 13.3144 3.69242 11.7568Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.65436 13.5232C2.39078 15.3289 2.19661 16.7435 2.6824 17.5849C3.16819 18.4264 4.49037 18.9655 6.68597 18.7741C8.81015 18.5889 11.4602 17.7277 14.1048 16.2009C16.7493 14.6741 18.8201 12.8096 20.0426 11.0626C21.3062 9.25691 21.5003 7.84229 21.0145 7.00088C20.5287 6.15947 19.2066 5.62031 17.011 5.81175C14.8868 5.99696 12.2368 6.85808 9.59219 8.38492C6.94762 9.91176 4.87685 11.7762 3.65436 13.5232ZM2.34345 12.6059C3.72779 10.6276 5.99184 8.61606 8.79219 6.99928C11.5925 5.38249 14.4666 4.42753 16.872 4.21779C19.206 4.01429 21.4189 4.50133 22.4002 6.20088C23.3814 7.90043 22.6967 10.0604 21.3535 11.98C19.9691 13.9583 17.7051 15.9698 14.9048 17.5865C12.1044 19.2033 9.23036 20.1583 6.82495 20.368C4.49094 20.5715 2.27799 20.0845 1.29676 18.3849C0.315526 16.6854 1.00021 14.5254 2.34345 12.6059Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2380_57588">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
