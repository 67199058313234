<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.63604 4.22258C6.02656 4.6131 6.02656 5.24627 5.63604 5.63679C2.12132 9.15151 2.12132 14.85 5.63604 18.3647C6.02656 18.7552 6.02656 19.3884 5.63604 19.7789C5.24551 20.1695 4.61235 20.1695 4.22183 19.7789C-0.0739417 15.4832 -0.0739417 8.51835 4.22183 4.22258C4.61235 3.83206 5.24551 3.83206 5.63604 4.22258ZM18.364 4.22263C18.7545 3.8321 19.3876 3.8321 19.7782 4.22263C24.0739 8.51839 24.0739 15.4832 19.7782 19.779C19.3876 20.1695 18.7545 20.1695 18.364 19.779C17.9734 19.3885 17.9734 18.7553 18.364 18.3648C21.8787 14.85 21.8787 9.15156 18.364 5.63684C17.9734 5.24632 17.9734 4.61315 18.364 4.22263ZM8.46447 7.05097C8.85499 7.4415 8.85499 8.07466 8.46447 8.46519C6.51184 10.4178 6.51184 13.5836 8.46447 15.5363C8.85499 15.9268 8.85499 16.5599 8.46447 16.9505C8.07394 17.341 7.44078 17.341 7.05025 16.9505C4.31658 14.2168 4.31658 9.78464 7.05025 7.05097C7.44078 6.66045 8.07394 6.66045 8.46447 7.05097ZM15.5355 7.05101C15.9261 6.66048 16.5592 6.66048 16.9497 7.05101C19.6834 9.78468 19.6834 14.2168 16.9497 16.9505C16.5592 17.341 15.9261 17.341 15.5355 16.9505C15.145 16.56 15.145 15.9268 15.5355 15.5363C17.4882 13.5837 17.4882 10.4178 15.5355 8.46522C15.145 8.0747 15.145 7.44153 15.5355 7.05101ZM12 11.0008C11.4477 11.0008 11 11.4485 11 12.0008C11 12.553 11.4477 13.0008 12 13.0008C12.5523 13.0008 13 12.553 13 12.0008C13 11.4485 12.5523 11.0008 12 11.0008ZM9 12.0008C9 10.3439 10.3431 9.00076 12 9.00076C13.6569 9.00076 15 10.3439 15 12.0008C15 13.6576 13.6569 15.0008 12 15.0008C10.3431 15.0008 9 13.6576 9 12.0008Z"
    />
  </svg>
</template>
