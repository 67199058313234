<template>
  <Responsive>
    <template #mobile>
      <div
        v-if="!eventsPageLoading"
        :class="[
          'events',
          'events--mobile',
        ]"
      >
        <transition name="drop">
          <MobileCompetitionFilter
            v-if="!eventsPageLoading"
            v-show="showMenu"
          />
        </transition>
        <EventList
          v-if="!eventsPageLoading && selectedSportId"
          :markets="markets"
        />
      </div>
      <div
        v-else
        :class="[
          'events',
          'events--mobile',
          'events--mobile-loading'
        ]"
      >
        <Spinner style="marign-top: 30rem" />
      </div>
    </template>

    <template #desktop>
      <div class="events">
        <div class="events__sidebar">
          <Sidebar />
        </div>
        <div
          v-if="!eventsPageLoading"
          class="events__content"
        >
          <DesktopCompetitionFilter />
          <EventList
            v-if="selectedSportId"
            :markets="markets"
          />
        </div>
        <div
          v-else
          class="events__content events__content--loading"
        >
          <Spinner />
        </div>
      </div>
    </template>
  </Responsive>
</template>

<script>
import { computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { filter, includes } from 'lodash';
import { useScrollMenu, useOnlineStatus } from '@/composables';
import Responsive from '@/components/common/Responsive';
import Spinner from '@/components/common/Spinner';
import Sidebar from '@/components/event-list/desktop/Sidebar';
import MobileCompetitionFilter from '@/components/event-list/mobile/CompetitionFilter';
import DesktopCompetitionFilter from '@/components/event-list/desktop/CompetitionFilter';
import EventList from '@/components/event-list/common/EventList';

export default {
  components: {
    Responsive,
    Spinner,
    Sidebar,
    MobileCompetitionFilter,
    DesktopCompetitionFilter,
    EventList,
  },
  setup() {
    const store = useStore();

    const eventsPageLoading = computed(() => store.getters.eventsPageLoading);
    const eventsPagination = computed(() => store.getters.eventsPagination);
    const events = computed(() => store.getters.events);
    const sports = computed(() => store.getters.sports);
    const loading = computed(() => store.getters.eventsLoading);
    const mobSportsOddsFilterToggled = computed(() => store.getters.mobSportsOddsFilterToggled);
    const { showMenu } = useScrollMenu({
      initialValue: true,
      mobileOnly: true,
    });

    const allowedSports = [
      '841e188b-0dcf-4f5f-974f-aa52c8cec95b', // Am. football
      'cf64a1fd-9982-48f7-a2e4-0897cc8c668f', // Basketball
      '09eb8090-68f3-4a53-838f-f79e7a6912c3', // Soccer
      '6093d1bf-d572-486b-af56-96287ee0e865', // AUDL
    ];

    const filteredSports = computed(() => filter(
      sports.value, (sport) => includes(allowedSports, sport.sportId),
    ));

    const selectedSportId = computed(() => store.getters.selectedSportId);

    const markets = computed(() => {
      if (selectedSportId.value === '09eb8090-68f3-4a53-838f-f79e7a6912c3') { // Soccer
        return [
          {
            code: 'RESULT_EXC_OT',
            label: 'Match Winner',
            selections: ['HOME', 'DRAW', 'AWAY'],
            hasLine: false,
            id: 1,
          },
          {
            code: 'GOAL_OVER_UNDER',
            label: 'Total Goals',
            selections: ['OVER', 'UNDER'],
            hasLine: true,
            id: 2,
          },
        ];
      }
      if (selectedSportId.value === '4e8eca10-6afa-44ed-af77-42414ec45cb3') { // Baseball
        return [
          {
            code: 'RESULT',
            label: 'Money Line',
            selections: ['HOME', 'AWAY'],
            hasLine: false,
            id: 1,
          },
          {
            code: 'RUN_OVER_UNDER',
            label: 'Total',
            selections: ['OVER', 'UNDER'],
            hasLine: true,
            id: 2,
          },
          {
            code: 'RUN_HANDICAP',
            label: 'Spread',
            selections: ['HOME', 'AWAY'],
            hasLine: true,
            id: 3,
          },
        ];
      }
      if (selectedSportId.value === 'db5e8b75-30a3-4a97-9112-f28b8e962887') { // Ice Hockey
        return [
          {
            code: 'RESULT',
            label: 'Money Line',
            selections: ['HOME', 'AWAY'],
            hasLine: false,
            id: 1,
          },
          {
            code: 'GOAL_OVER_UNDER',
            label: 'Total',
            selections: ['OVER', 'UNDER'],
            hasLine: true,
            id: 2,
          },
          {
            code: 'GOAL_HANDICAP',
            label: 'Spread',
            selections: ['HOME', 'AWAY'],
            hasLine: true,
            id: 3,
          },
        ];
      }
      return [
        {
          code: 'RESULT',
          label: 'Money Line',
          selections: ['HOME', 'AWAY'],
          hasLine: false,
          id: 1,
        },
        {
          code: 'POINT_OVER_UNDER',
          label: 'Total Points',
          selections: ['OVER', 'UNDER'],
          hasLine: true,
          id: 2,
        },
        {
          code: 'POINT_HANDICAP',
          label: 'Points Spread',
          selections: ['HOME', 'AWAY'],
          hasLine: true,
          id: 3,
        },
      ];
    });

    onMounted(() => {
      store.dispatch('loadEvents');
    });
    onUnmounted(() => {
      store.dispatch('unsubscribeEventList');
    });

    useOnlineStatus({
      onReconnect() {
        store.dispatch('loadEvents');
      },
    });

    return {
      eventsPageLoading,
      eventsPagination,
      sports,
      events,
      loading,
      filteredSports,
      markets,
      mobSportsOddsFilterToggled,
      showMenu,
      selectedSportId,
    };
  },
};
</script>

<style lang="scss" scoped>
.events {
  display: flex;
  // width: 87%;
  // max-width: $appContentMaxWidth;
  // margin: 1rem auto;
  margin: 1.5rem 1rem;
  gap: 1rem;

  @media screen and (min-width: 1520px) {
    max-width: 1520px;
    padding: 0 1rem;
    margin: 1.5rem auto;
  }

  .events__content {
    flex: 1;
    width: calc(73% - 17rem); // content width - sidebar width

    &.events__content--loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.events--mobile {
    width: auto;
    flex-direction: column;
    margin: 0;
    min-height: 100%;

    .events-list {
      padding: 0 1rem;
    }
  }

  &.events--mobile-loading {
    margin-top: 300px;
  }
}

@media screen and (max-width: $maxMobileBreakpoint) {
  .events {
    margin: 0.75rem auto;
  }
}
</style>
