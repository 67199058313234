import EventList from '@/components/event-list/EventList';
import Event from '@/components/event/Event';
import Logout from '@/components/login/Logout';

export default [
  {
    name: 'home',
    path: '',
    component: EventList,
  },
  {
    name: 'event_noid',
    path: '/event',
    component: Event,
  },
  {
    name: 'event',
    path: '/event/:id',
    component: Event,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/login/Login'),
    meta: {
      login: true,
    },
    children: [
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('@/components/login/ResetPasswordForm'),
      },
      {
        path: '/password-change',
        name: 'password-change',
        component: () => import('@/components/login/ChangePasswordForm'),
      },
    ],
  },
  {
    name: 'logout',
    path: '/logout',
    component: Logout,
  },
];
