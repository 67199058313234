<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/check-circle-filled">
      <g id="Icon (Stroke)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4717 6.02892C11.7321 6.28927 11.7321 6.71138 11.4717 6.97173L7.47173 10.9717C7.21138 11.2321 6.78927 11.2321 6.52892 10.9717L4.52892 8.97173C4.26857 8.71138 4.26857 8.28927 4.52892 8.02892C4.78927 7.76857 5.21138 7.76857 5.47173 8.02892L7.00033 9.55752L10.5289 6.02892C10.7893 5.76857 11.2114 5.76857 11.4717 6.02892Z"
          fill="#00BC57"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.00033 1.16699C3.95024 1.16699 0.666992 4.45024 0.666992 8.50033C0.666992 12.5504 3.95024 15.8337 8.00033 15.8337C12.0504 15.8337 15.3337 12.5504 15.3337 8.50033C15.3337 4.45024 12.0504 1.16699 8.00033 1.16699Z"
          fill="#00BC57"
        />
        <path
          d="M11.4717 6.97173C11.7321 6.71138 11.7321 6.28927 11.4717 6.02892C11.2114 5.76857 10.7893 5.76857 10.5289 6.02892L7.00033 9.55752L5.47173 8.02892C5.21138 7.76857 4.78927 7.76857 4.52892 8.02892C4.26857 8.28927 4.26857 8.71138 4.52892 8.97173L6.52892 10.9717C6.78927 11.2321 7.21138 11.2321 7.47173 10.9717L11.4717 6.97173Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
</template>
