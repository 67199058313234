import { isFunction } from 'lodash';
import {
  ref,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';

export default (options) => {
  const isOnline = ref(true);

  const handleNetworkEvent = (event) => {
    isOnline.value = event?.type === 'online';
  };

  onMounted(() => {
    window.addEventListener('online', handleNetworkEvent);
    window.addEventListener('offline', handleNetworkEvent);
  });
  onUnmounted(() => {
    window.removeEventListener('online', handleNetworkEvent);
    window.removeEventListener('offline', handleNetworkEvent);
  });

  if (isFunction(options?.onReconnect)) {
    watch(isOnline, (newIsOnline, oldIsOnline) => {
      if (oldIsOnline || !newIsOnline) return;
      options.onReconnect();
    });
  }

  return {
    isOnline,
  };
};
