<template>
  <div :class="['betslip', { 'toggled': betslipToggled }]">
    <div class="betslip-scroller">
      <div
        :class="['betslip-header', { 'opened': betslipToggled }]"
        @click="() => { betslipToggled = !betslipToggled }"
      >
        <div class="betslip-header-label">
          Betslip ({{ bets?.length }})
        </div>
        <div class="betslip-header-toggle">
          <ChevronUpIcon
            v-if="(betslipToggled && !isMobile) || (!betslipToggled && isMobile)"
            class="betslip-header-toggle-icon"
          />
          <ChevronDownIcon
            v-else
            class="betslip-header-toggle-icon"
          />
        </div>
      </div>
      <div
        class="betslip-body"
        v-if="betslipToggled"
      >
        <div
          v-if="bets.length"
          class="betslip-content-header"
        >
          <div class="betslip-content-header-selections">
            SELECTIONS
          </div>
          <div
            class="betslip-content-header-clear-betslip"
            @click="clearBetslip"
          >
            <div class="clear-betslip-icon">
              <TrashIcon class="betslip-trash-icon" />
            </div>
            <div class="clear-betslip-label">
              Clear betslip
            </div>
          </div>
        </div>
        <div
          v-else
          class="betslip-no-bets"
        >
          Your betslip is empty
        </div>
        <div
          class="betslip-bet"
          v-for="bet in bets"
          :key="bet.marketId"
        >
          <div class="betslip-bet-name-group">
            <div class="betslip-bet-name-group-selection">
              {{ bet.selectionName }}  {{ displayLine(bet) }}
            </div>
            <div class="betslip-bet-name-group-market">
              {{ bet.marketName }}
            </div>
          </div>
          <div
            class="betslip-bet-remove-button"
            @click="removeBet(bet)"
          >
            <XIcon />
          </div>
        </div>
      </div>
      <div
        class="betslip-footer"
        v-if="betslipToggled"
      >
        <div class="probability-and-price">
          <div class="betslip-footer-row">
            <div class="betslip-footer-row-label">
              Probability
            </div>
            <div class="betslip-footer-row-value">
              {{ probability }}
            </div>
          </div>
          <div class="betslip-footer-row">
            <div class="betslip-footer-row-label">
              Total price
            </div>
            <div class="betslip-footer-row-value">
              {{ totalPrice }}{{ totalPrice && totalPrice !== '-' && selectedOddFormat.name === 'probability' ? '%' : '' }}
            </div>
          </div>
          <div
            :class="['betslip-error', betslipError.price.type]"
            v-if="betslipError.price"
          >
            <AlertCircleIcon />
            {{ betslipError.price.message }}
          </div>
        </div>
        <div
          class="calculator-button-mobile-wrapper"
          v-if="isMobile"
        >
          <div
            class="calculator-button-mobile"
            @click="() => { calculatorActive = true; }"
          >
            Cash-Out calculator
          </div>
          <div
            :class="['betslip-error', betslipError.selections.type]"
            v-if="betslipError.selections"
          >
            <AlertCircleIcon />
            {{ betslipError.selections.message }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="calculator-overlay"
      v-if="calculatorActive && isMobile"
    />
    <div
      class="calculator-wrapper"
      v-if="(!isMobile && betslipToggled) || (isMobile && calculatorActive)"
    >
      <div
        class="calculator-header"
        v-if="calculatorActive"
      >
        <div class="calculator-header-title">
          <div>Cash-Out calculator</div>
          <div
            class="calculator-header-title-close-icon"
            v-if="isMobile"
            @click="() => { calculatorActive = false; }"
          >
            <XIcon />
          </div>
        </div>
        <div class="calculator-header-subtitle">
          Input Stake and Price values to simulate Cash-Out from a previously placed bet on the legs currently in your betslip.
        </div>
      </div>
      <div
        class="calculator-body"
        v-if="calculatorActive"
      >
        <div class="calculator-inputs-wrapper">
          <div class="calculator-input-group">
            <div class="input-label">
              STAKE OF PREVIOUS BET
            </div>
            <div :class="['calculator-input', { 'input-error': stakeValidationError }]">
              <div class="currency-icon">
                <CurrencyDollarIcon />
              </div>
              <input
                v-model="calculatorStake"
                @input="validateCalculatorValues('stake')"
                placeholder="0.00"
              >
            </div>
            <div
              class="calculator-input-error"
              v-if="stakeValidationError"
            >
              {{ stakeValidationError }}
            </div>
          </div>
          <div class="calculator-input-group">
            <div class="input-label">
              PRICE OF PREVIOUS BET
            </div>
            <div :class="['calculator-input', { 'input-error': priceValidationError }]">
              <input
                v-model="calculatorPrice"
                @input="validateCalculatorValues('price')"
                placeholder="0.00"
              >
            </div>
            <div
              class="calculator-input-error"
              v-if="priceValidationError"
            >
              {{ priceValidationError }}
            </div>
          </div>
        </div>
        <div class="calculator-result-row">
          <div class="calculator-result-row-label">
            Cash-Out value
          </div>
          <div class="calculator-result-row-value">
            {{ cashoutAmount }}
          </div>
        </div>
      </div>
      <div
        class="calculator-button"
        v-if="!calculatorActive"
        @click="() => { calculatorActive = true; }"
      >
        Cash-Out calculator
      </div>
      <div
        :class="['calculator-button', {'disabled': !calculatorEnabled}]"
        v-else
        @click="calculateCashout"
      >
        Calculate
      </div>
      <div
        :class="['betslip-error', betslipError.selections.type]"
        v-if="betslipError.selections"
      >
        <AlertCircleIcon />
        {{ betslipError.selections.message }}
      </div>
      <div
        :class="['betslip-error', betslipError.cashout.type]"
        v-if="betslipError.cashout"
      >
        <AlertCircleIcon />
        {{ betslipError.cashout.message }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, computed, onMounted, onUnmounted, watch,
} from 'vue';
import { useStore } from 'vuex';
import { includes } from 'lodash';
import { useBreakpoints } from '@/composables';
import {
  ChevronUpIcon, ChevronDownIcon, TrashIcon,
  XIcon, CurrencyDollarIcon, AlertCircleIcon,
} from '@/components/svg';
import OddsConverter from '@/services/helpers/odds-converter';

export default {
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
    TrashIcon,
    XIcon,
    CurrencyDollarIcon,
    AlertCircleIcon,
  },
  props: {
    eventId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const { isMobile } = useBreakpoints();

    const bets = computed(() => store.getters.betslip.bets);
    const probability = computed(() => (store.getters.betslip.probability
      ? `${parseFloat(store.getters.betslip.probability * 100).toFixed(2)}%`
      : '-'));
    const selectedOddFormat = computed(() => store.getters.selectedOddFormat);
    const totalPrice = computed(() => (store.getters.betslip.totalPrice
      ? OddsConverter.getOddsLadderMappedOddValue(store.getters.betslip, selectedOddFormat.value.name)
      : '-'));

    const betslipToggled = ref(!isMobile.value);

    const calculatorActive = ref(false);
    const calculatorStake = ref('');
    const calculatorPrice = ref('');

    const stakeValidationError = ref('');
    const priceValidationError = ref('');

    const validateCalculatorValues = (inputChange) => {
      if (inputChange === 'stake') {
        stakeValidationError.value = '';
        // eslint-disable-next-line no-restricted-globals
        if (calculatorStake.value && isNaN(calculatorStake.value)) {
          stakeValidationError.value = 'Value must be a number.';
          return;
        }
        if ((calculatorStake.value || calculatorStake.value === '0') && Number(calculatorStake.value) <= 0) {
          stakeValidationError.value = "Value has to be greater than '0.00'";
        }
        return;
      }
      priceValidationError.value = false;
      // eslint-disable-next-line no-restricted-globals
      if (calculatorPrice.value && isNaN(calculatorPrice.value)) {
        priceValidationError.value = 'Value must be a number.';
        return;
      }
      if ((calculatorPrice.value || calculatorPrice.value === '0') && Number(calculatorPrice.value) <= 1) {
        priceValidationError.value = "Value has to be greater than '1.00'";
      }
    };

    const clearBetslip = () => {
      store.dispatch('clearBetslip');
      store.dispatch('clearCashout');
      calculatorActive.value = false;
      calculatorPrice.value = '';
      calculatorStake.value = '';
      stakeValidationError.value = '';
      priceValidationError.value = '';
    };

    const removeBet = (bet) => {
      store.dispatch('updateBetslip', { bet });
    };

    const displayPeriodPrefix = (bet) => {
      const prefixValue = bet.selection.selectionType.params.HALF_X || bet.selection.selectionType.params.PERIOD_X;
      if (!prefixValue) return '';
      const prefixes = {
        1: '1st',
        2: '2nd',
        3: '3rd',
        4: '4th',
      };
      return prefixes[prefixValue] || '';
    };

    const displayLine = (bet) => {
      const line = bet.market.marketType.params.LINE;
      if (!line) return '';
      if (!includes(bet.market.marketType.marketCode, 'HANDICAP')) return line;
      let lineValue = '';
      lineValue = line;
      if (bet.selection.selectionType.selectionCode === 'HOME' && line > 0) {
        lineValue = `+${line}`;
      }
      if (bet.selection.selectionType.selectionCode === 'AWAY' && line < 0) {
        lineValue = `+${Math.abs(line)}`;
      }
      if (bet.selection.selectionType.selectionCode === 'AWAY' && line > 0) {
        lineValue = `-${line}`;
      }
      return lineValue;
    };

    const calculatorEnabled = computed(() => (bets.value.length > 1 && calculatorStake.value && calculatorPrice.value
      && !stakeValidationError.value && !priceValidationError.value));

    const calculateCashout = () => {
      if (!calculatorEnabled.value) return;
      store.dispatch('calculateCashout', { stake: calculatorStake.value, price: calculatorPrice.value });
    };

    const cashoutAmount = computed(() => (store.getters.cashoutAmount ? `$ ${store.getters.cashoutAmount}` : '-'));

    const betslipError = computed(() => store.getters.betslipError);

    onMounted(() => {
      store.dispatch('subscribeToSgpChanges', props.eventId);
    });

    onUnmounted(() => {
      store.dispatch('unsubscribeToSgpChanges', props.eventId);
      clearBetslip();
    });

    watch(
      [isMobile, betslipToggled],
      ([newIsMobile, newBetslipToggled]) => {
        if (!newIsMobile) {
          document.body.classList.remove('modal-open');
          return;
        }

        if (newBetslipToggled) {
          document.body.classList.add('modal-open');
        } else {
          document.body.classList.remove('modal-open');
        }
      },
      { immediate: true },
    );

    watch(
      isMobile,
      (newMobile, oldMobile) => {
        if (newMobile === oldMobile) return;
        calculatorActive.value = false;
        betslipToggled.value = !newMobile;
      },
    );

    return {
      betslipToggled,
      bets,
      clearBetslip,
      removeBet,
      probability,
      totalPrice,
      displayPeriodPrefix,
      displayLine,
      calculatorStake,
      calculatorPrice,
      validateCalculatorValues,
      calculatorActive,
      calculatorEnabled,
      calculateCashout,
      cashoutAmount,
      isMobile,
      betslipError,
      stakeValidationError,
      priceValidationError,
      selectedOddFormat,
    };
  },
};
</script>

<style lang="scss" scoped>
.betslip {
  display: flex;
  flex-direction: column;
  width: 368px;
  height: fit-content;
  max-height: calc(100dvh - 68px - 2.5rem);
  position: sticky;
  top: 92px;
  background-color: #fff;
  border: 1px solid #F0F0F0;
  box-shadow: none;
  border-radius: 4px;
  font-size: 14px;

  .betslip-scroller {
    overflow-y: auto;
  }

  .betslip-header {
    height:  40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
    position: sticky;
    top: 0;
    background: #fff;

    &.opened {
      box-shadow: inset 0px -1px 0px #F0F0F0;
    }

    .betslip-header-label {
      font-weight: 600;
      color: #003C3C;
    }

    .betslip-header-toggle {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .betslip-header-toggle-icon {
        height: 16px;
        width: 16px;
      }
    }
  }

  .betslip-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px 16px;

    .betslip-content-header {
      display: flex;
      justify-content: space-between;
      height: 41px;
      width: 100%;
      background: #fff;
      position: sticky;
      top: 40px;

      .betslip-content-header-selections {
        height: 100%;
        line-height: 41px;
        color: #A9A9A9;
        font-weight: 600;
        font-size: 10px;
      }

      .betslip-content-header-clear-betslip {
        display: flex;
        height: 100%;
        align-items: center;
        padding-right: 12px;
        color: #191414;
        cursor: pointer;

        .clear-betslip-icon {
          height: 16px;
          width: 16px;
          margin-right: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .betslip-bet {
      height: 56px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 8px;
      align-items: center;
      color: #191414;
      background-color: #FAFAFA;
      border: 1px solid #F0F0F0;
      border-radius: 4px;
      margin-bottom: 8px;

      .betslip-bet-name-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 85%;

        .betslip-bet-name-group-selection {
          font-size: 16px;
          text-transform: capitalize;
        }

        .betslip-bet-name-group-market {
          font-size: 14px;
          color: #A9A9A9;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .betslip-bet-remove-button {
        height: 33px;
        width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .betslip-no-bets {
      height: 100px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: -16px;
    }
  }

  .betslip-footer {
    width: 100%;
    border-top: 1px solid #F0F0F0;
    position: sticky;
    bottom: 0;
    background: #fff;

    .probability-and-price {
      border-bottom: 1px solid #F0F0F0;
      padding: 8px 0;
      .betslip-footer-row {
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 33px;
        margin-bottom: 4px;

        .betslip-footer-row-value {
          font-weight: 600;
        }
      }
    }
  }

  .calculator-wrapper {
      width: 100%;
      padding: 16px;

      .calculator-header {
        width: 100%;
        margin-bottom: 16px;

        .calculator-header-title {
          height: 33px;
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          color: #191414;
          font-weight: 600;
          justify-content: space-between;

          .calculator-header-title-close-icon {
            height: 33px;
            width: 33px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              height: 16px;
              width: 16px;
            }
          }
        }

        .calculator-header-subtitle {
          height: 28px;
          width: 100%;
          display: flex;
          align-items: center;
          color: #A9A9A9;
          font-size: 12px;

        }
      }

      .calculator-body {
        width: 100%;
        padding: 8px 0;
        .calculator-inputs-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          .calculator-input-group {
            width: 48%;
            .input-label {
              height: 12px;
              line-height: 12px;
              color: #A9A9A9;
              font-weight: 600;
              font-size: 10px;
              margin-bottom: 4px;
            }
            .calculator-input {
              height: 44px;
              width: 100%;
              background-color: #FFFFFF;
              border: 1px solid #CDCDCD;
              border-radius: 4px;
              display: flex;
              position: relative;

              &.input-error {
                border-color: #FF2E2D;
              }

              .currency-icon {
                width: 32px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0;
                top: 0;

                svg {
                  height: 16px;
                  width: 16px;
                  stroke: #DDDDDD;
                  opacity: 0.5;
                }
              }

              input {
                outline: none;
                border: 0;
                height: 100%;
                width: 100%;
                color: #191414;
                border-radius: 4px;
                background-color: #FFF;
                font-size: 14px;
                padding: 0 8px 0 32px;
                text-align: right;

                &::placeholder {
                  color: #DDDDDD;
                }

                &:focus {
                  box-shadow:  0 0 0 2px #658A8A;
                  outline: none;
                }
              }
            }

            .calculator-input-error {
              font-size: 10px;
              color: #FF2E2D;
            }
          }
        }

        .calculator-result-row {
          display: flex;
          height: 33px;
          width: 100%;
          justify-content: space-between;

          .calculator-result-row-value {
            font-weight: 600;
          }
        }
      }

      .calculator-button {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: #003C3C;
        border-radius: 4px;
        cursor: pointer;

        &.disabled {
          background-color: #F0F0F0;
          color: #A9A9A9;
          cursor: default;
        }
      }
    }
}

.betslip-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  width: 100%;
  margin-top: 4px;
  text-align: center;

  &.error {
    svg {
      stroke: #FF2E2D;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

@media screen and (max-width: $maxMobileBreakpoint) {
  .betslip {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1999;
    top: unset;
    max-height: unset;

    .betslip-scroller {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }

    &.toggled {
      bottom: unset;
      top: 0;
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    .betslip-header {
      height:  60px;
      min-height: 60px;
    }

    .betslip-body {
      flex: 1;
      .betslip-content-header {
        top: 60px;
      }
    }
  }

  .calculator-wrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        max-width: 92.5%;
        border: 1px solid #F0F0F0;
        border-radius: 4px;
        z-index: 2001;
        background-color: #fff;
        transform: translate(-50%, -50%);
      }

      .calculator-button-mobile-wrapper {
        widows: 100%;
        height: 84px;
        padding: 0 16px;
        .calculator-button-mobile {
          width: 100%;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          background-color: #003C3C;
          border-radius: 4px;
          cursor: pointer;
        }
      }

  .calculator-overlay {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2000;
  }
}
</style>
