<template>
  <Navbar
    v-if="isLoggedIn"
  />
  <router-view
    v-if="bootstrapped"
  />
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { isUserLoggedIn } from '@/services/api';
import Navbar from '@/components/navbar/Navbar';

export default {
  components: {
    Navbar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isLoggedIn = computed(() => !!store.getters.userToken);
    const bootstrapped = computed(() => store.getters.bootstrapped);

    onMounted(async () => {
      try {
        await router.isReady();
        if (route?.name === 'login' || route?.name === 'logout') return;
        const {
          token, userRoles, username, operator,
        } = await isUserLoggedIn();
        store.dispatch('updateUser', {
          token, userRoles, username, operator,
        });
        store.dispatch('connectWebSocket');
        await store.dispatch('initialize');
      } catch {
        router.push({ name: 'login' });
      }
    });

    return {
      isLoggedIn,
      bootstrapped,
    };
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

.app {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 16px;
  background-color: #FAFAFA;
  min-height: 100%;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
</style>
