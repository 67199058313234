<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.8293 15.11C16.4803 15.11 16.1673 14.865 16.0953 14.51C16.0123 14.104 16.2743 13.708 16.6803 13.625C19.4113 13.069 22.3063 9.707 22.5623 4.5H19.2503C18.8363 4.5 18.5003 4.164 18.5003 3.75C18.5003 3.336 18.8363 3 19.2503 3H23.3303C23.7443 3 24.0803 3.336 24.0803 3.75C24.0803 10.249 20.5083 14.376 16.9803 15.095C16.9293 15.105 16.8793 15.11 16.8293 15.11Z" />
    <path d="M7.161 15.09C7.107 15.09 7.052 15.084 6.996 15.072C3.52 14.292 0 10.153 0 3.75C0 3.336 0.336 3 0.75 3H4.75C5.164 3 5.5 3.336 5.5 3.75C5.5 4.164 5.164 4.5 4.75 4.5H1.519C1.774 9.634 4.63 13.004 7.325 13.608C7.729 13.699 7.983 14.1 7.893 14.504C7.814 14.853 7.504 15.09 7.161 15.09Z" />
    <path d="M13.2502 16.1097C12.8362 16.1097 12.5002 16.4457 12.5002 16.8597H11.5002C11.5002 16.4457 11.1642 16.1097 10.7502 16.1097C10.3362 16.1097 10.0002 16.4457 10.0002 16.8597V20.4997C10.0002 20.9137 10.3362 21.2497 10.7502 21.2497H13.2502C13.6642 21.2497 14.0002 20.9137 14.0002 20.4997V16.8597C14.0002 16.4457 13.6642 16.1097 13.2502 16.1097Z" />
    <path d="M14.2502 20.0003H9.75017C8.23317 20.0003 7.00016 21.2333 7.00016 22.7503V23.2503C7.00016 23.6643 7.33616 24.0003 7.75016 24.0003H16.2502C16.6642 24.0003 17.0002 23.6643 17.0002 23.2503V22.7503C17.0002 21.2333 15.7672 20.0003 14.2502 20.0003Z" />
    <path d="M18.2502 0H5.75016C4.78516 0 4.00016 0.785 4.00016 1.75V9.005C4.04316 15.461 11.4392 18.1 11.7542 18.209C11.8342 18.237 11.9172 18.251 12.0002 18.251C12.0832 18.251 12.1662 18.237 12.2452 18.21C12.5612 18.1 20.0002 15.46 20.0002 9V1.75C20.0002 0.785 19.2152 0 18.2502 0Z" />
  </svg>
</template>
