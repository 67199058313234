<template>
  <div class="search-result-list-item">
    <div
      v-if="isCompetitorType"
      class="search-result-list-item__content search-result-list-item__content--competitor"
      @click="browseEvents(result)"
    >
      <SportIcon
        class="search-result-list-item__icon"
        :sport-label="result.sportLabel"
      />
      <div class="search-result-list-item__title">
        {{ result.name }}
      </div>
      <div class="search-result-list-item__subtitle">
        {{ formatSportName(result) }}
      </div>
      <div class="search-result-list-item__badge">
        {{ formatEventCount(result) }}
      </div>
    </div>

    <div
      v-else-if="isEventType"
      class="search-result-list-item__content search-result-list-item__content--event"
      @click="visitEvent(result)"
    >
      <div class="search-result-list-item__title">
        {{ result.eventName }}
      </div>
      <div class="search-result-list-item__subtitle">
        <span>
          {{ result.sport.sportName }}
        </span>
        &bull;
        <span class="search-result-list-item__competition">
          {{ result.competition?.competitionName }}
        </span>
        &bull;
        <span>
          {{ formatDateTime(result) }}
        </span>
      </div>
    </div>

    <div
      v-else
      class="search-result-list-item__content"
      @click="handleRecentResult(result)"
    >
      <RefreshIcon
        class="search-result-list-item__icon"
      />
      <div class="search-result-list-item__title">
        {{ result.title }}
      </div>
      <div class="search-result-list-item__subtitle">
        {{ result.subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { toLower, capitalize } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { RefreshIcon } from '@/components/svg';
import SportIcon from '@/components/common/SportIcon';

export default {
  components: {
    RefreshIcon,
    SportIcon,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    // eslint-disable-next-line no-underscore-dangle
    const resultType = computed(() => props.result.__typename);
    const isCompetitorType = computed(() => resultType.value === 'Competitor');
    const isEventType = computed(() => resultType.value === 'Event');

    const formatSportName = ({ sportLabel }) => {
      if (sportLabel === 'AMERICAN_FOOTBALL') return 'Football';
      if (sportLabel === 'ICE_HOCKEY') return 'Hockey';
      return capitalize(toLower(sportLabel));
    };
    const formatEventCount = ({ events }) => {
      const { totalCount } = events;
      if (totalCount === 1) return `${totalCount} event`;
      return `${totalCount} events`;
    };
    const formatDateTime = ({ startsAt }) => {
      const eventDate = format(zonedTimeToUtc(startsAt, 'UTC'), 'MMM dd');
      const eventTime = format(zonedTimeToUtc(startsAt, 'UTC'), 'p');
      return `${eventDate}, ${eventTime}`;
    };

    const browseEvents = async (result) => {
      const competitor = result.source || result;

      if (!result.source) {
        store.dispatch('search', result.name);
        store.dispatch('addRecentSearchResult', {
          id: result.teamId,
          title: result.name,
          subtitle: formatSportName(result),
          source: result,
        });
      } else {
        store.dispatch('addRecentSearchResult', result);
      }

      store.dispatch('closeSearch');
      router.push({
        name: 'home',
      });

      const { sportLabel: rawSportLabel } = competitor;
      const sportLabel = rawSportLabel === 'FOOTBALL' ? 'AMERICAN_FOOTBALL' : rawSportLabel;
      store.dispatch('selectSearchResult', { ...competitor, sportLabel });
    };
    const visitEvent = (result) => {
      const event = result.source || result;

      if (!result.source) {
        store.dispatch('addRecentSearchResult', {
          id: result.eventId,
          title: result.eventName,
          subtitle: `${formatSportName(result.sport)} • ${result.competition?.competitionName}`,
          source: result,
        });
      } else {
        store.dispatch('addRecentSearchResult', result);
      }

      store.dispatch('clearSearch');
      store.dispatch('closeSearch');
      router.push({
        name: 'event',
        params: {
          id: event.eventId,
        },
      });
    };
    const handleRecentResult = (result) => {
      // eslint-disable-next-line default-case, no-underscore-dangle
      switch (result.source.__typename) {
      case 'Competitor':
        store.dispatch('search', result.source.name);
        browseEvents(result);
        break;
      case 'Event':
        visitEvent(result);
        break;
      }
    };

    return {
      isCompetitorType,
      isEventType,
      formatSportName,
      formatEventCount,
      formatDateTime,
      browseEvents,
      visitEvent,
      handleRecentResult,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-result-list-item {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #F0F0F0;
  line-height: 1.1875rem;
  @include truncate;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.search-result-list-item__content {
  display: grid;
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 0.25rem;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  height: 4rem;
  padding: 0.75rem 0.5rem;
  border-radius: 0.25rem;
  user-select: none;
  cursor: pointer;
  @include truncate;

  &:hover {
    background-color: #FAFAFA;
  }

  &.search-result-list-item__content--competitor {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
  }

  &.search-result-list-item__content--event {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.25rem;
  }
}

.search-result-list-item__icon {
  grid-row: 1 / 3;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
}

.search-result-list-item__title,
.search-result-list-item__subtitle {
  @include truncate;
  width: 100%;
}

.search-result-list-item__subtitle {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #A9A9A9;
  gap: 0.25rem;
}

.search-result-list-item__competition {
  @include truncate;
}

.search-result-list-item__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #191414;
  padding: 0.25rem  0.5rem;
  font-size: 0.875rem;
  border: 1px solid #DDDDDD;
  border-radius: 1rem;
  grid-row: 1 / 3;
  grid-column: 3;
}

@media screen and (max-width: $maxMobileBreakpoint) {
  .search-result-list-item__content {
    padding: 0.375rem 0.5rem;
    height: 3.25rem;
  }

  .search-result-list-item__title {
    font-size: 0.875rem;
  }

  .search-result-list-item__subtitle,
  .search-result-list-item__badge {
    font-size: 0.75rem;
  }

  .search-result-list-item__badge {
    padding: 0.21875rem 0.5rem;
    height: 1.5rem;
  }
}
</style>
