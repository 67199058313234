import {
  includes,
  split,
  indexOf,
  cloneDeep,
  assign,
} from 'lodash';
import { formatMinutesAndSeconds } from '@/utils/number';

const PERIODS = [
  'N_A',
  'IN_FIRST_PERIOD',
  'IN_SECOND_PERIOD',
  'AT_HALF_TIME',
  'IN_THIRD_PERIOD',
  'IN_FOURTH_PERIOD',
  'IN_OVERTIME',
  'EVENT_COMPLETED',
];

const PERIOD_ORDINAL_MAPPER = {
  IN_FIRST_PERIOD: '1st Quarter',
  IN_SECOND_PERIOD: '2nd Quarter',
  AT_HALF_TIME: 'Half Time',
  IN_THIRD_PERIOD: '3rd Quarter',
  IN_FOURTH_PERIOD: '4th Quarter',
  IN_OVERTIME: 'Overtime',
};

const LIVE_PERIODS = [
  'IN_FIRST_PERIOD',
  'IN_SECOND_PERIOD',
  'IN_THIRD_PERIOD',
  'IN_FOURTH_PERIOD',
  'IN_OVERTIME',
];

const NON_LIVE_PERIODS = [
  'EVENT_COMPLETED',
  'N_A',
];

const OVER_PERIODS = [
  'EVENT_COMPLETED',
];

const livePeriods = [
  'IN_FIRST_PERIOD',
  'IN_SECOND_PERIOD',
  'AT_HALF_TIME',
  'IN_THIRD_PERIOD',
  'IN_FOURTH_PERIOD',
  'NORMAL_TIME_COMPLETED',
  'IN_OVERTIME',
];

const getLivePeriods = () => [...livePeriods];

const getPeriodAbbreviation = (inPlayPeriodCode) => {
  switch (inPlayPeriodCode) {
  case 'PERIOD_1':
    return 'Q1';
  case 'PERIOD_2':
    return 'Q2';
  case 'PERIOD_3':
    return 'Q3';
  case 'PERIOD_4':
    return 'Q4';
  case 'OVERTIME':
    return 'OT';
  default:
  }

  if (!inPlayPeriodCode || !includes(PERIODS, inPlayPeriodCode)) return '';

  const periodValues = split(inPlayPeriodCode, '_');

  if (periodValues[1] === 'OVERTIME') return 'OT';

  switch (periodValues[1]) {
  case 'FIRST':
    return '1st Q';
  case 'SECOND':
    return '2nd Q';
  case 'THIRD':
    return '3rd Q';
  case 'FOURTH':
    return '4th Q';
  default:
    return '';
  }
};

const getPeriodOrdinal = (inPlayPeriodCode) => PERIOD_ORDINAL_MAPPER[inPlayPeriodCode];

const getPeriodTime = ({
  period,
  gameTimeElapsedInPeriod,
  quarterDuration,
  overtimeDuration,
}) => {
  const totalDurationInSeconds = (period === 'IN_OVERTIME' ? overtimeDuration : quarterDuration);
  const currentTimeInSeconds = totalDurationInSeconds - gameTimeElapsedInPeriod - 1;
  return formatMinutesAndSeconds(currentTimeInSeconds);
};

const getPeriodLabel = ({
  period,
  gameTimeElapsedInPeriod,
  quarterDuration,
  overtimeDuration,
}, {
  shortLabel = false,
} = {}) => {
  const time = getPeriodTime({
    period,
    gameTimeElapsedInPeriod,
    quarterDuration,
    overtimeDuration,
  });

  switch (period) {
  case 'IN_FIRST_PERIOD':
    return shortLabel ? `Q1 • ${time}` : `1. QTR • ${time}`;
  case 'IN_SECOND_PERIOD':
    return shortLabel ? `Q2 • ${time}` : `2. QTR • ${time}`;
  case 'AT_HALF_TIME':
    return shortLabel ? 'HT' : 'Half Time';
  case 'IN_THIRD_PERIOD':
    return shortLabel ? `Q3 • ${time}` : `3. QTR • ${time}`;
  case 'IN_FOURTH_PERIOD':
    return shortLabel ? `Q4 • ${time}` : `4. QTR • ${time}`;
  case 'IN_OVERTIME':
    return `OT • ${time}`;
  case 'EVENT_COMPLETED':
    return 'End';
  case 'PERIOD_1':
    return 'Q1';
  case 'PERIOD_2':
    return 'Q2';
  case 'PERIOD_3':
    return 'Q3';
  case 'PERIOD_4':
    return 'Q4';
  case 'OVERTIME':
    return 'OT';
  default:
    return '';
  }
};

const getPeriodIndex = (periodCode) => {
  if (periodCode === 'TOTAL') return 0;
  return indexOf(PERIODS, periodCode) + 1;
};

const isActivePeriod = (periodCode) => !!PERIOD_ORDINAL_MAPPER[periodCode];

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      period: payload.state.period,
      inPossession: payload.state.inPossession,
      gameTimeElapsedInPeriod: payload.state.gameTimeElapsedInPeriod,
      quarterDuration: payload.rules.quarterDuration,
      overtimeDuration: payload.rules.overtimeDuration,
      overtimePossible: payload.rules.overtimePossible,
      homeScore: payload.state.homeScore,
      homeScoreFirstQuarter: payload.state.homeScoreFirstQuarter,
      homeScoreSecondQuarter: payload.state.homeScoreSecondQuarter,
      homeScoreThirdQuarter: payload.state.homeScoreThirdQuarter,
      homeScoreFourthQuarter: payload.state.homeScoreFourthQuarter,
      homeScoreOvertime: payload.state.homeScoreOverTime,
      awayScore: payload.state.awayScore,
      awayScoreFirstQuarter: payload.state.awayScoreFirstQuarter,
      awayScoreSecondQuarter: payload.state.awayScoreSecondQuarter,
      awayScoreThirdQuarter: payload.state.awayScoreThirdQuarter,
      awayScoreFourthQuarter: payload.state.awayScoreFourthQuarter,
      awayScoreOvertime: payload.state.awayScoreOverTime,
      drives: payload.state.drives,
      downNumber: payload.state.downNumber,
      yardsToEndZone: payload.state.yardsToEndZone,
      yardsToWinDown: payload.state.yardsToWinDown,
      isPlayRunning: payload.state.isPlayRunning,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      awayTimeoutsLeft: payload.state.awayTimeOutsLeft,
      homeTimeoutsLeft: payload.state.homeTimeOutsLeft,
    },
  );

  return updatedEventDetails;
};

export default {
  PERIODS,
  LIVE_PERIODS,
  NON_LIVE_PERIODS,
  OVER_PERIODS,
  getPeriodOrdinal,
  getPeriodAbbreviation,
  getPeriodTime,
  getPeriodLabel,
  getPeriodIndex,
  isActivePeriod,
  updateEvent,
  getLivePeriods,
};
