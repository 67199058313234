<template>
  <div
    v-if="isSuspended"
    class="event-card-market event-card-market--inactive"
  >
    {{ shouldShortenText ? 'Susp' : 'Suspended' }}
  </div>
  <div
    v-else
    :class="[
      'event-card-market',
      { 'event-card-market--success': oddChange === 'up' },
      { 'event-card-market--danger': oddChange === 'down' },
    ]"
  >
    <div
      v-if="line"
      class="event-card-market__header"
    >
      {{ linePrefix }}
      {{ line }}
    </div>
    {{ odd }}
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useWindowSize } from '@vueuse/core';
import OddsConverter from '@/services/helpers/odds-converter';

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    market: {
      type: Object,
      required: true,
    },
    selection: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const { width } = useWindowSize();
    const shouldShortenText = computed(() => width.value < 1200);

    const oddFormat = computed(() => store.getters.selectedOddFormat.name);
    const linePrefix = computed(() => {
      const selectionCode = props.selection?.selectionType?.selectionCode;
      if (selectionCode === 'OVER') return 'O';
      if (selectionCode === 'UNDER') return 'U';
      if (selectionCode === 'HOME') return 'H';
      if (selectionCode === 'AWAY') return 'A';
      return '';
    });
    const line = computed(() => {
      if (!props.market.hasLine) return null;
      let lineValue = '';
      if (props.market?.marketType?.params?.LINE) {
        lineValue = props.market.marketType.params.LINE;
        if (linePrefix.value === 'H' && props.market.marketType.params.LINE > 0) {
          lineValue = `+${props.market.marketType.params.LINE}`;
        }
        if (linePrefix.value === 'A' && props.market.marketType.params.LINE < 0) {
          lineValue = `+${Math.abs(props.market.marketType.params.LINE)}`;
        }
        if (linePrefix.value === 'A' && props.market.marketType.params.LINE > 0) {
          lineValue = `-${props.market.marketType.params.LINE}`;
        }
      }
      return props.market.marketType && props.event.matchState !== 'FINISHED' ? lineValue : '';
    });
    const odd = computed(() => {
      const price = props.selection?.price;
      return OddsConverter.getMappedOddValue(price, oddFormat.value);
    });
    const oddChange = computed(() => props.selection?.price?.changedPrice);
    const isSuspended = computed(() => props.market.offeringStatus === 'SUSPENDED' || props.selection?.offeringStatus === 'SUSPENDED');

    return {
      line,
      linePrefix,
      odd,
      oddChange,
      isSuspended,
      shouldShortenText,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-card-market {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #E5EBEB;
  height: 100%;
  font-size: 1rem;
  border-radius: 0.25rem;
  padding: 0.25rem;
  min-width: 4.6875rem;

  &.event-card-market--inactive {
    color: #99B2B1;
    text-transform: uppercase;
  }
  &.event-card-market--success {
    background-color: #E5F8EE;
  }

  &.event-card-market--danger {
    background-color: #FEEEEF;
  }
}

.event-card-market__header {
  font-size: 0.875rem;
  color: #346363;
}

@media screen and (max-width: 767px) {
  .event-card-market {
    font-size: inherit;
  }

  .event-card-market__header {
    font-size: 0.75rem;
  }
}
</style>
