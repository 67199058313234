<template>
  <div class="badge">
    <slot />
  </div>
</template>

<style lang="scss">
.badge {
  background-color: #D1DFFF;
  color: #175FFF;
  padding: 0.125rem  0.5rem;
  font-size: 0.625rem;
  border-radius: 6.25rem;
  text-transform: uppercase;
}
</style>
