<template>
  <Backdrop
    :open="searchToggled"
    @update:open="closeSearch"
  />
  <div
    :class="[
      'search-bar',
      { 'search-bar--is-absolute': absolute },
    ]"
  >
    <SearchField />
    <transition name="dropdown">
      <div
        v-if="dropdownVisible"
        class="search-bar__dropdown"
      >
        <SearchResultList />
      </div>
    </transition>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Backdrop from '@/components/common/Backdrop';
import SearchField from '@/components/navbar/common/SearchField';
import SearchResultList from '@/components/navbar/common/SearchResultList';

export default {
  components: {
    Backdrop,
    SearchField,
    SearchResultList,
  },
  props: {
    absolute: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();
    const searchToggled = computed(() => store.getters.searchToggled);
    const searchLoading = computed(() => store.getters.searchLoading);
    const searchResultsNotFound = computed(() => store.getters.searchResultsNotFound);
    const dropdownVisible = computed(() => {
      const { searchQuery, searchRecentResults } = store.getters;
      if (!searchQuery && !searchRecentResults.length) return false;
      return searchToggled.value;
    });

    const closeSearch = () => {
      store.dispatch('closeSearch');
    };

    return {
      searchToggled,
      searchLoading,
      searchResultsNotFound,
      dropdownVisible,
      closeSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  position: relative;
  height: 2.75rem;
  flex-basis: 35%;
  flex-shrink: 1;
  z-index: 9991;
  &.search-bar--is-absolute {
    width: 26.875rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.search-bar__dropdown {
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  position: absolute;
  z-index: 9999;
  top: calc(100% + 0.25rem);
  max-height: 51rem;
  padding: 0 1rem;
  overflow-y: auto;
  min-width: 26.25rem;
  width: 100%;

  &:empty {
    display: none;
  }
}

@media screen and (max-width: $maxMobileBreakpoint) {
  .search-bar {
    width: 18rem;
  }
}
</style>
