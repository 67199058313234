import {
  debounce, isNil, cloneDeep, find, map, each,
  findIndex, assign, filter, take, isEmpty,
} from 'lodash';
import * as api from '@/services/api';
import Soccer from '@/services/helpers/soccer';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Hockey from '@/services/helpers/hockey';
import Baseball from '@/services/helpers/baseball';
import Ultimate from '@/services/helpers/ultimate';
import eventHelper from '@/services/helpers/event';
import { updateEventListMarkets, mergeIncomingMarket } from '@/services/helpers/market-updater';
import socket from '@/services/socket';
import { dispatchGoogleTag } from '@/services/google-analytics';
import computeStartsAt from '@/utils/computeStartsAt';
import types from './mutation-types';
import { getSuperAdminData } from '../services/helpers/super-admin';

export default {
  /*
   * Bootstrap
   */
  async initialize({ commit, dispatch }) {
    await dispatch('getOperatorsFeatures');
    await dispatch('loadSports');
    commit(types.SET_INITIALIZED, true);

    commit(types.SET_EVENTS_PAGE_LOADING, true);
    await dispatch('loadCompetitions');
    commit(types.SET_EVENTS_PAGE_LOADING, false);

    // II. Fetch current user config
    const { oddsFormatType = 'AMERICAN' } = await api.findUserConfiguration();
    dispatch('setSelectedOddFormatById', oddsFormatType);
  },
  async loadSports({ commit }) {
    try {
      const response = await api.queryAllSports();
      const { data: { sports: { nodes = [] } } } = response ?? {};
      commit(types.UPDATE_SPORTS, nodes);
      commit(types.SELECT_SPORT, nodes?.[0]?.sportId);
    } catch (error) {
      console.error(error);
      commit(types.UPDATE_SPORTS, []);
    }
  },

  /*
   * Helper
   */
  setScrollPosition({ commit }, scrollPosition) {
    commit(types.SET_SCROLL_POSITION, scrollPosition);
  },
  setSelectedMarketGroup({ commit }, marketGroup) {
    commit(types.SET_SELECTED_MARKET_GROUP, marketGroup);
  },

  /*
   * Auth
   */
  async login({ dispatch }, userData) {
    try {
      const response = await api.authenticateUser(userData);
      if (response.message === 'newPasswordRequired') {
        dispatch('changePasswordAttributes', response.data);
        throw new Error('Auth failed. New password required!');
      }
      dispatch('updateUser', {
        token: response.data.token,
        userRoles: response.data.userRoles,
        username: response.data.username,
      });
      dispatch('connectWebSocket');
      dispatch('initialize');
    } catch (error) {
      console.error(error);
      dispatch('loginErrorMessage', error);
      throw new Error(error);
    }
  },
  logout({ commit, dispatch }) {
    api.logout();
    localStorage.removeItem('huddleDemoUserToken');
    localStorage.removeItem('huddleDemoRoles');
    localStorage.removeItem('huddleUsername');
    commit(types.STORE_USER_TOKEN, null);
    commit(types.STORE_USER_NAME, null);
    commit(types.STORE_USER_OPERATOR, null);
    commit(types.SET_INITIALIZED, false);
    dispatch('disconnectWebSocket');
  },
  updateUser({ commit }, {
    token,
    userRoles,
    username,
    operator,
  }) {
    const roles = userRoles || [];
    localStorage.setItem('huddleDemoUserToken', token);
    localStorage.setItem('huddleDemoRoles', roles);
    localStorage.setItem('huddleUsername', username);
    localStorage.setItem('huddleOperator', operator);
    commit(types.STORE_USER_TOKEN, token);
    commit(types.STORE_USER_NAME, username);
    commit(types.STORE_USER_OPERATOR, operator);
  },
  loginErrorMessage({ commit }, errorMessage) {
    commit(types.UPDATE_LOGIN_ERROR_MESSAGE, errorMessage);
  },
  changePasswordAttributes({ commit }, payload) {
    commit(types.UPDATE_CHANGE_PASSWORD_ATTRIBUTES, payload);
  },
  changePassword(_, payload) {
    return new Promise((resolve, reject) => {
      api.changePassword(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  confirmNewPassword(_, payload) {
    return new Promise((resolve, reject) => {
      api.confirmNewPassword(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendCodeToEmail(_, username) {
    return new Promise((resolve, reject) => {
      api.requestCode(username)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /*
   * Web Socket
   */
  connectWebSocket({ getters, dispatch }) {
    if (socket.connected) return;
    const { userToken = '' } = getters;
    socket.connect(userToken, dispatch);
  },
  disconnectWebSocket() {
    if (!socket.connected) return;
    socket.disconnect();
  },
  subscribeEvent({ getters }) {
    if (!socket.connected || !getters.event) return;
    socket.subscribeEvent(eventHelper.mapEventIdAndSource(getters.event));
  },
  unsubscribeEvent({ getters }) {
    if (!socket.connected || !getters.event) return;
    socket.unsubscribeEvent(eventHelper.mapEventIdAndSource(getters.event));
  },
  subscribeEventList({ getters }) {
    if (!socket.connected || !getters.events?.length) return;
    socket.subscribeEventList(eventHelper.groupEventIdsBySource(getters.events));
  },
  unsubscribeEventList({ getters }) {
    if (!socket.connected || !getters.events?.length) return;
    socket.unsubscribeEventList(eventHelper.groupEventIdsBySource(getters.events));
  },

  /*
   * Event
   */
  async loadEvent({ commit, getters, dispatch }, eventId) {
    if (getters.eventFound) dispatch('unloadEvent');

    try {
      commit(types.SET_EVENT_LOADING, true);

      const response = await api.queryEvent(eventId, computeStartsAt());
      const { data: { event } } = response ?? {};
      const { markets: { nodes = [] } = {} } = event ?? {};

      if (!event) throw new Error('Not Found');

      const { marketDisplayConfigurations, allMarketParameters } = await api.getMarketDisplayConfigurations({ sportId: event.sport.sportId });
      const eventMarkets = nodes.reduce((markets, market) => ({ ...markets, [market.marketId]: market }), {});
      commit(types.SET_EVENT, event);
      commit(types.SET_EVENT_DISPLAY_MARKETS, marketDisplayConfigurations);
      commit(types.SET_ALL_MARKET_PARAMETERS, allMarketParameters);
      commit(types.SET_EVENT_MARKETS, eventMarkets);

      // Getting team squad data for football only as we only have player statistics for football
      if (event.sport.sportId === '841e188b-0dcf-4f5f-974f-aa52c8cec95b' || event.sport.sportId === 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f' || event.sport.sportId === '4e8eca10-6afa-44ed-af77-42414ec45cb3') {
        api.getTeamSquad({
          teamId: event.competitors[0]?.competitorId,
          sportId: event.sportId,
        })
          .then((team) => {
            commit(types.UPDATE_EVENT_TEAM_PLAYERS, { team: 'home', players: map(team.squad, (squad) => squad.player) });
          })
          .catch((err) => {
            console.error(err);
            commit(types.UPDATE_EVENT_TEAM_PLAYERS, { team: 'home', players: [] });
          });
        api.getTeamSquad({
          teamId: event.competitors[1]?.competitorId,
          sportId: event.sportId,
        })
          .then((team) => {
            commit(types.UPDATE_EVENT_TEAM_PLAYERS, { team: 'away', players: map(team.squad, (squad) => squad.player) });
          })
          .catch((err) => {
            console.error(err);
            commit(types.UPDATE_EVENT_TEAM_PLAYERS, { team: 'away', players: [] });
          });
      }

      // In case of reload on the event page we lose a reference of the selected sport
      // So we update it here with the info from the event
      if (event.sportId !== getters.selectedSportId) {
        commit(types.SELECT_SPORT, event.sportId);
      }
      // Loading market groups based on the selected sport
      if (getters.selectedSportId !== getters.latestLoadedMarketGroupSport) {
        const marketGroupsResponse = await api.getMarketGroups({
          filter: {
            sportId: {
              equalTo: getters.selectedSportId,
            },
            active: { equalTo: true },
          },
        });
        commit(types.SET_MARKET_GROUPS_BY_SPORT, [
          ...[{ code: 'ALL', displayName: 'All', hideMatchingMarkets: true }],
          ...marketGroupsResponse,
        ]);
      }

      commit(types.SET_SELECTED_MARKET_GROUP, getters.marketGroupsBySport[0]);
      commit(types.SET_LATEST_LOADED_MARKET_GROUP_SPORT, getters.selectedSportId);

      dispatch('subscribeEvent');
    } catch (error) {
      console.error(error);
      commit(types.SET_EVENT, null);
      commit(types.SET_EVENT_MARKETS, null);
    } finally {
      commit(types.SET_EVENT_LOADING, false);
    }
  },
  unloadEvent({ commit, getters, dispatch }) {
    if (!getters.eventFound) return;

    dispatch('unsubscribeEvent');
    commit(types.SET_EVENT, null);
    commit(types.SET_EVENT_MARKETS, null);
  },
  reloadEvent({ getters, dispatch }) {
    const { eventId = '' } = getters.event ?? {};

    if (!eventId) return;

    dispatch('unloadEvent');
    dispatch('loadEvent', eventId);
  },

  updateEventDetails({ commit, getters }, payload) {
    if (!getters.eventFound || getters.event.eventId !== payload.eventId) return;

    let matchState;
    let eventDetails;
    let eventDetailsField;

    if (getters.event.matchState !== 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock.period === 'FIRST_PERIOD')
        || payload.state.period === 'FIRST_QUARTER' || payload.state.period === 'IN_FIRST_HALF') {
        matchState = 'LIVE';
      } else {
        matchState = getters.event.matchState;
      }
    } else if (getters.event.matchState === 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock.period === 'POST_GAME')
        || payload.state.period === 'EVENT_COMPLETED') {
        matchState = 'FINISHED';
      } else {
        matchState = getters.event.matchState;
      }
    } else {
      matchState = getters.event.matchState;
    }

    switch (getters.event.sportId) {
    case '09eb8090-68f3-4a53-838f-f79e7a6912c3':
      eventDetailsField = 'soccerEventDetails';
      eventDetails = Soccer.updateEvent(getters.event[eventDetailsField]?.nodes?.[0], payload);
      break;
    case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
      eventDetailsField = 'basketballEventDetails';
      eventDetails = Basketball.updateEvent(eventHelper.findEventDetails(getters.event), payload);
      break;
    case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
      eventDetailsField = 'footballEventDetails';
      eventDetails = Football.updateEvent(getters.event[eventDetailsField]?.nodes?.[0], payload);
      break;
    case 'db5e8b75-30a3-4a97-9112-f28b8e962887':
      eventDetailsField = 'hockeyEventDetails';
      eventDetails = Hockey.updateEvent(getters.event[eventDetailsField]?.nodes?.[0], payload);
      break;
    case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
      eventDetailsField = 'baseballEventDetails';
      eventDetails = Baseball.updateEvent(getters.event[eventDetailsField]?.nodes?.[0], payload);
      break;
    case '6093d1bf-d572-486b-af56-96287ee0e865':
      eventDetailsField = 'ultimateEventDetails';
      eventDetails = Ultimate.updateEvent(getters.event[eventDetailsField]?.nodes?.[0], payload);
      break;
    default:
      return;
    }

    commit(types.SET_EVENT, {
      ...getters.event,
      matchState,
      [eventDetailsField]: {
        nodes: [eventDetails],
      },
    });
  },
  updateEventMarkets({ commit, getters, state }, incomingMarkets) {
    const { event } = getters;
    if (!event) return;
    const allMarkets = cloneDeep(state.eventMarkets);
    each(incomingMarkets, (market) => {
      if (event.eventId !== market.eventId) return;
      const updatedMarket = mergeIncomingMarket(allMarkets, market);
      if (updatedMarket.deleteMarket) {
        commit(types.UPDATE_SINGLE_EVENT_MARKET, { marketId: market.marketId, deleteMarket: true });
        return;
      }
      if (updatedMarket.update) commit(types.UPDATE_SINGLE_EVENT_MARKET, { deleteMarket: false, market: updatedMarket.market });
    });
  },

  /*
   * Event List
   */
  async loadEvents({ commit, getters, dispatch }) {
    if (!getters.selectedSportId) return;
    if (getters.events.length) dispatch('unsubscribeEventList');

    try {
      commit(types.SET_EVENTS_LOADING, true);

      const queryOptions = {
        first: getters.eventsPageSize,
        offset: (getters.eventsPage - 1) * getters.eventsPageSize,
        filter: {
          eventName: {
            includesInsensitive: getters.searchQuery || '',
          },
          startsAt: {
            greaterThanOrEqualTo: computeStartsAt(),
          },
          bookedEventsByEventIdExist: true,
        },
        condition: {},
        marketsFilter: {},
        marketsCondition: {},
      };

      if (getters.selectedSportId) {
        queryOptions.filter.sportId = {
          in: [getters.selectedSportId],
        };
        queryOptions.marketsFilter.marketCode = {
          in: ['RESULT_EXC_OT', 'GOAL_OVER_UNDER', 'POINT_HANDICAP', 'RUN_HANDICAP', 'GOAL_HANDICAP', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER', 'RESULT'],
        };
        queryOptions.marketsCondition = {
          displayStatus: 'ON',
        };
        queryOptions.marketsFilter.source = {
          in: ['INTERNAL_AGGREGATION', 'DPS'],
        };
      }

      if (getters.selectedCompetitionId) {
        queryOptions.filter.competitionId = {
          in: [getters.selectedCompetitionId],
        };
      }

      if (getters.eventsFilters.live) {
        queryOptions.condition.matchState = 'LIVE';
      }

      const response = await api.queryAllEvents(queryOptions);
      commit(types.UPDATE_EVENTS, {
        events: response.data.allEvents.events,
        eventsTotalCount: response.data.allEvents.totalCount || 0,
      });
      dispatch('subscribeEventList');
    } catch (error) {
      console.error('Querying events failed', error);
      commit(types.UPDATE_EVENTS, {
        events: [],
        eventsTotalCount: 0,
      });
    } finally {
      commit(types.SET_EVENTS_LOADING, false);
    }
  },
  resetEvents({ getters, commit, dispatch }) {
    // Reset sport
    commit(types.SELECT_SPORT, find(getters.sports, { sportLabel: 'AMERICAN_FOOTBALL' }).sportId);
    commit(types.SELECT_MARKET_ID, 1);

    dispatch('loadCompetitions');

    // Reset filters
    dispatch('clearSearch');
    commit(types.SELECT_DATE_RANGE, find(getters.dateRanges, { id: 3 }));
    commit(types.SET_EVENTS_FILTERS, {
      search: '',
      live: false,
      sports: [],
    });

    commit(types.SET_EVENTS_PAGE, 1);
    dispatch('loadEvents');
  },
  debounceLoadEvents: debounce(({ dispatch }) => {
    dispatch('loadEvents');
  }, 1000),
  setEventsPage({ commit, dispatch }, page) {
    commit(types.SET_EVENTS_PAGE, page);
    dispatch('loadEvents');
  },
  setEventsFilters({ commit, dispatch, getters }, {
    search, sports, live, date,
  }) {
    commit(types.SET_EVENTS_FILTERS, {
      search: isNil(search) ? getters.eventsFilters.search : search,
      sports: sports || getters.eventsFilters.sports,
      live: isNil(live) ? getters.eventsFilters.live : live,
      date: isNil(date) ? getters.selectedDateRange : date,
    });
    commit(types.SET_EVENTS_PAGE, 1);
    dispatch('debounceLoadEvents');
  },
  setMarketsFilter: debounce(({ commit, getters }, { search }) => {
    commit(types.SET_MARKETS_FILTERS, isNil(search) ? getters.marketsFilters.search : search);
  }, 500),
  setEventsPagination({ commit }, pagination = true) {
    commit(types.SET_EVENTS_PAGINATION, pagination);
  },
  updateEvent({ state, commit, getters }, payload) {
    commit(types.EVENT_UPDATED, payload);
    const events = cloneDeep(getters.events);
    const eventIndex = findIndex(events, { eventId: payload.eventId });
    if (eventIndex < 0) return;
    if (events[eventIndex].matchState !== 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock.period === 'FIRST_PERIOD')
        || payload.state.period === 'FIRST_QUARTER' || payload.state.period === 'IN_FIRST_HALF') {
        events[eventIndex].matchState = 'LIVE';
      }
    }
    if (events[eventIndex].matchState === 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock.period === 'POST_GAME')
        || payload.state.period === 'EVENT_COMPLETED') {
        events[eventIndex].matchState = 'FINISHED';
      }
    }
    if (events[eventIndex].footballEventDetails?.nodes?.[0]) {
      assign(events[eventIndex].footballEventDetails?.nodes?.[0], {
        awayScore: payload.state.awayScore,
        homeScore: payload.state.homeScore,
        gameTimeElapsedInPeriod: payload.state.gameTimeElapsedInPeriod,
        period: payload.state.period,
        teamInPossession: payload.state.teamInPossession,
      });
    }
    if (events[eventIndex].soccerEventDetails?.nodes?.[0]) {
      assign(events[eventIndex].soccerEventDetails?.nodes?.[0], {
        awayScore: payload.state.awayScore,
        homeScore: payload.state.homeScore,
        elapsedSecondInPeriod: payload.state.elapsedSecondInPeriod,
        period: payload.state.period,
        teamInPossession: payload.state.teamInPossession,
      });
    }
    if (events[eventIndex].sportId === 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f') {
      assign(eventHelper.findEventDetails(events[eventIndex]), {
        awayScore: payload.state.awayScore,
        homeScore: payload.state.homeScore,
        secondsLeftInPeriod: payload.state.currentClock?.secondsLeftInPeriod,
        period: payload.state.currentClock.period,
        teamInPossession: payload.state.teamInPossession,
      });
    }
    if (events[eventIndex].hockeyEventDetails?.nodes?.[0]) {
      assign(events[eventIndex].hockeyEventDetails?.nodes?.[0], {
        awayScore: payload.state.awayScore,
        homeScore: payload.state.homeScore,
        secondsLeftInPeriod: payload.state.currentClock?.secondsLeftInPeriod,
        period: payload.state.currentClock.period,
        teamInPossession: payload.state.teamInPossession,
      });
    }
    if (events[eventIndex].baseballEventDetails?.nodes?.[0]) {
      assign(events[eventIndex].baseballEventDetails?.nodes?.[0], {
        matchHits: payload.state.matchHits,
        matchHomeRuns: payload.state.matchHomeRuns,
        matchRuns: payload.state.matchRuns,
        pitchCount: payload.state.pitchCount,
        inningDetails: payload.state.inningDetails,
        teamInPossession: payload.state.teamInPossession,
      });
    }
    if (events[eventIndex].ultimateEventDetails?.nodes?.[0]) {
      assign(events[eventIndex].ultimateEventDetails?.nodes?.[0], {
        lastTeamToScore: payload.state.lastTeamToScore,
        teamInPossession: payload.state.teamInPossession,
        period: payload.state.period,
        secondsLeftInPeriod: payload.state.secondsLeftInPeriod,
        points: payload.state.points,
        scorePerPeriod: payload.state.scorePerPeriod,
        homeScore: payload.state.homeScore,
        awayScore: payload.state.awayScore,
      });
    }
    state.events = events;
  },
  processEventInfo({ getters, dispatch }, data) {
    if (!(data?.eventId && (getters.event || getters.events?.length))) return;

    if (getters.event) dispatch('updateEventInfo', data);
    if (getters.events) dispatch('updateEventListInfo', data);
  },
  processEventFeedChange({ getters, dispatch }, data) {
    const { operatorId = '', eventId = '' } = data || {};

    if (getSuperAdminData().isSuperAdmin) {
      const urlParams = new URLSearchParams(window.location.search);
      const selectedOperatorId = urlParams.get('client');
      if (operatorId !== selectedOperatorId) return;
    } else if (getters.operator) {
      if (operatorId !== getters.operator) return;
    }

    if (find(getters.events, { eventId })) dispatch('loadEvents');
    if (eventId === getters.event?.eventId) dispatch('reloadEvent');
  },
  processEventFlagsInfo({ getters, dispatch }, data) {
    if (!(data?.eventId && (getters.event || getters.events?.length))) return;

    if (getters.events) dispatch('updateEventFlagsListInfo', data);
  },
  updateEventInfo({ getters, commit }, data) {
    const {
      eventId,
      eventName,
      startDateTime: startsAt,
      status,
      matchStatus: matchState,
      competitors,
      isUSAView: isUsaView,
    } = data ?? {};
    const { event } = getters;

    if (eventId !== event.eventId) return;

    const updatedEvent = {
      ...event,
      eventName,
      startsAt,
      status,
      matchState,
      competitors,
      isUsaView,
    };
    commit(types.SET_EVENT, updatedEvent);
  },
  updateEventListInfo({ getters, commit }, data) {
    const {
      eventId,
      eventName,
      startDateTime: startsAt,
      status,
      matchStatus: matchState,
      competitors,
      isUSAView: isUsaView,
    } = data ?? {};

    const eventIndex = findIndex(getters.events, { eventId });
    if (eventIndex === -1) return;

    const updatedEvents = cloneDeep(getters.events);
    updatedEvents[eventIndex] = {
      ...updatedEvents[eventIndex],
      eventName,
      startsAt,
      status,
      matchState,
      competitors,
      isUsaView,
    };
    commit(types.SET_EVENTS, updatedEvents);
  },
  updateEventFlagsListInfo({ getters, commit }, data) {
    const { eventId } = data;
    const updatedFlag = eventHelper.getUpdatedEventFlag(data);

    const eventIndex = findIndex(getters.events, { eventId });
    if (eventIndex === -1) return;

    const updatedEvents = cloneDeep(getters.events);
    updatedEvents[eventIndex] = {
      ...updatedEvents[eventIndex],
      ...updatedFlag,
    };

    commit(types.SET_EVENTS, updatedEvents);
  },
  updateMarket({ getters, commit }, incomingMarkets) {
    const { events } = getters;
    const updatedEvents = updateEventListMarkets(events, incomingMarkets);
    commit(types.SET_EVENTS, updatedEvents);
  },

  setSelectedOddFormatById({ commit, getters }, oddFormatId) {
    const oddFormat = find(getters.oddFormats, { id: oddFormatId }) || getters.oddFormats?.[0];
    if (!oddFormat) throw new Error('No odds formats available!');
    commit(types.UPDATE_ODD_FORMAT, oddFormat);
  },
  async selectOddFormat({ getters, commit }, oddFormat) {
    dispatchGoogleTag('select_odds_format', {
      value: oddFormat?.label ?? 'N/A',
    });

    const oldOddFormat = cloneDeep(getters.selectedOddFormat);
    try {
      commit(types.UPDATE_ODD_FORMAT, oddFormat);
      await api.setUserConfiguration({ oddsFormat: oddFormat.id });
    } catch (error) {
      console.error(error);
      commit(types.UPDATE_ODD_FORMAT, oldOddFormat);
    }
  },

  async selectSport({ commit, getters, dispatch }, sportId) {
    dispatchGoogleTag('select_sport', {
      value: getters.sportById(sportId)?.sportName ?? 'N/A',
    });

    try {
      commit(types.SET_EVENTS_PAGE_LOADING, true);
      commit(types.SET_SEARCH_QUERY, '');
      commit(types.SELECT_SPORT, sportId);

      await Promise.all([
        dispatch('loadCompetitions'),
        dispatch('loadEvents'),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      commit(types.SET_EVENTS_PAGE_LOADING, false);
      dispatch('clearSearch');
      dispatch('selectMarket', 1);
    }
  },
  async loadCompetitions({ getters, commit }) {
    try {
      commit(types.SET_COMPETITIONS_LOADING, true);
      commit(types.SELECT_COMPETITION, '');
      const competitions = await api.getCompetitions({
        sportId: getters.selectedSportId,
        startDate: computeStartsAt(),
      });
      commit(types.UPDATE_COMPETITIONS, competitions);
    } catch (error) {
      console.error(error);
      commit(types.UPDATE_COMPETITIONS, []);
    } finally {
      commit(types.SET_COMPETITIONS_LOADING, false);
    }
  },
  selectCompetition({ commit, dispatch }, competitionId) {
    commit(types.SELECT_COMPETITION, competitionId);
    dispatch('clearSearch');
    dispatch('selectMarket', 1);
    dispatch('loadEvents');
  },
  selectDateRange({ commit, dispatch }, dateRange) {
    commit(types.SELECT_DATE_RANGE, dateRange);
    dispatch('setEventsFilters', { date: dateRange });
  },
  selectMarket({ commit }, marketId) {
    commit(types.SELECT_MARKET_ID, marketId);
  },
  toggleMobSportsOddsFilter({ commit }) {
    commit(types.TOGGLE_MOB_SPORTS_ODDS_FILTER);
  },
  toggleMobEventsFilter({ commit }) {
    commit(types.TOGGLE_MOB_EVENTS_FILTER);
  },

  openSearch({ commit }) {
    commit(types.SET_SEARCH_TOGGLED, true);
  },
  closeSearch({ commit, dispatch, getters }) {
    if (!getters.searchSelection) {
      commit(types.SET_SEARCH_QUERY, '');
    } else if (getters.searchSelection.name !== getters.searchQuery) {
      commit(types.SET_SEARCH_QUERY, getters.searchSelection.name);
      dispatch('search', getters.searchSelection.name);
    }

    commit(types.SET_SEARCH_TOGGLED, false);
  },
  clearSearch({ commit }) {
    commit(types.SET_SEARCH_QUERY, '');
    commit(types.SET_SEARCH_COMPETITOR_RESULTS, []);
    commit(types.SET_SEARCH_EVENT_RESULTS, []);
    commit(types.SET_SEARCH_SELECTION, null);
  },
  async search({ getters, commit, dispatch }, searchQuery) {
    if (!searchQuery) {
      dispatch('clearSearch');
      return;
    }

    try {
      commit(types.SET_SEARCH_LOADING, true);
      commit(types.SET_SEARCH_QUERY, searchQuery);

      const searchResults = await api.searchAll(searchQuery, computeStartsAt(), map(getters.sports, 'sportLabel'));
      commit(types.SET_SEARCH_COMPETITOR_RESULTS, searchResults.data.competitors?.nodes || []);
      commit(types.SET_SEARCH_EVENT_RESULTS, searchResults.data.events?.nodes || []);
    } catch {
      commit(types.SET_SEARCH_COMPETITOR_RESULTS, []);
      commit(types.SET_SEARCH_EVENT_RESULTS, []);
    } finally {
      commit(types.SET_SEARCH_LOADING, false);
    }
  },
  addRecentSearchResult({ commit, getters }, recentResult) {
    const uniqueRecentResults = filter(getters.searchRecentResults, ({ id }) => id !== recentResult.id);
    const trimmedAndUniqueRecentResults = take(uniqueRecentResults, 9);
    commit(types.SET_SEARCH_RECENT_RESULTS, [
      recentResult,
      ...trimmedAndUniqueRecentResults,
    ]);
  },
  async selectSearchResult({ commit, getters, dispatch }, result) {
    if (getters.events.length) dispatch('unsubscribeEventList');

    try {
      commit(types.SET_EVENTS_PAGE_LOADING, true);

      const { sportLabel, name } = result;
      commit(types.SET_SEARCH_SELECTION, result);

      const { sportId } = getters.sportByLabel(sportLabel);
      commit(types.SELECT_SPORT, sportId);
      commit(types.SET_SEARCH_QUERY, name);

      await dispatch('loadCompetitions');

      const queryOptions = {
        filter: {
          eventName: {
            includesInsensitive: name,
          },
          startsAt: {
            greaterThanOrEqualTo: computeStartsAt(),
          },
        },
        marketsFilter: {},
      };

      if (getters.selectedSportId) {
        queryOptions.filter.sportId = {
          in: [getters.selectedSportId],
        };
        queryOptions.marketsFilter.marketCode = {
          in: ['RESULT_EXC_OT', 'GOAL_OVER_UNDER', 'POINT_HANDICAP', 'RUN_HANDICAP', 'GOAL_HANDICAP', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER', 'RESULT'],
        };
        queryOptions.marketsCondition = {
          displayStatus: 'ON',
        };
        queryOptions.marketsFilter.source = {
          in: ['INTERNAL_AGGREGATION', 'DPS'],
        };
      }

      const response = await api.queryAllEvents(queryOptions);
      commit(types.UPDATE_EVENTS, {
        events: response.data.allEvents.events,
        eventsTotalCount: response.data.allEvents.totalCount || 0,
      });
      dispatch('subscribeEventList');
    } catch (err) {
      console.log('Querying events failed', err);
      commit(types.UPDATE_EVENTS, {
        events: [],
        eventsTotalCount: 0,
      });
      commit(types.UPDATE_COMPETITIONS, []);
    } finally {
      commit(types.SET_EVENTS_PAGE_LOADING, false);
    }
  },
  async getOperatorsFeatures({ commit }) {
    await api.useOperatorsFeaturesQuery()
      .then((res) => {
        commit(types.SET_OPERATORS, res.data.allOperators?.nodes || []);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_OPERATORS, []);
      });
  },
  getAllOperators({ commit }) {
    api.getAllOperators().then((res) => {
      const operatorsList = map(res, (operator) => ({
        operatorId: operator.operatorId,
      }));
      commit(types.SET_OPERATORS_LIST, operatorsList);
    });
  },

  updateBetslip({ state, commit }, { bet }) {
    let bets = cloneDeep(state.betslip.bets);
    if (find(bets, { marketId: bet.marketId })) {
      bets = filter(bets, (betParam) => betParam.marketId !== bet.marketId);
    } else {
      bets.push(bet);
    }
    commit(types.UPDATE_BETSLIP, { bets });
    if (bets.length < 2) {
      commit(types.UPDATE_BETSLIP, {
        probability: '',
        price: '',
      });
      commit(types.UPDATE_BETSLIP_ERROR, {
        selections: {
          message: 'At least 2 selections needed',
          type: 'error',
        },
      });
      return;
    }
    if (!isEmpty(state.betslipError)) {
      commit(types.UPDATE_BETSLIP_ERROR, {});
    }
    api.getBetslipValues(bets)
      .then((response) => {
        commit(types.UPDATE_BETSLIP, {
          probability: response.data?.sgpPrice?.probability,
          totalPrice: response.data?.sgpPrice?.decimalValue,
        });
        if (!response.data?.sgpPrice) {
          commit(types.UPDATE_BETSLIP_ERROR, {
            price: {
              message: 'Could not return probability and price value.',
              type: 'error',
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        commit(types.UPDATE_BETSLIP, {
          probability: '',
          price: '',
        });
        commit(types.UPDATE_BETSLIP_ERROR, {
          price: {
            message: 'Could not return probability and price value.',
            type: 'error',
          },
        });
      });
  },
  clearBetslip({ commit }) {
    commit(types.UPDATE_BETSLIP); // when passing no bets we delete them all
    commit(types.UPDATE_BETSLIP_ERROR, {});
  },
  updateBetslipOnSocketUpdate({ state, commit }) {
    const { bets } = state.betslip;
    if (bets?.length < 2) return;
    api.getBetslipValues(bets)
      .then((response) => {
        commit(types.UPDATE_BETSLIP, {
          probability: response.data?.sgpPrice?.probability,
          totalPrice: response.data?.sgpPrice?.decimalValue,
        });
        if (!response.data?.sgpPrice) {
          commit(types.UPDATE_BETSLIP_ERROR, {
            price: {
              message: 'Could not return probability and price value.',
              type: 'error',
            },
          });
        }
      })
      .catch(() => {
        commit(types.UPDATE_BETSLIP, {
          probability: '',
          price: '',
        });
        commit(types.UPDATE_BETSLIP_ERROR, {
          price: {
            message: 'Could not return probability and price value.',
            type: 'error',
          },
        });
      });
  },
  calculateCashout({ state, commit }, payload) {
    if (state.betslipError?.cashout) {
      const betslipError = cloneDeep(state.betslipError);
      delete betslipError.cashout;
      commit(types.UPDATE_BETSLIP_ERROR, betslipError);
    }
    api.calculateCashout(state.betslip.bets, payload)
      .then((response) => {
        const cashoutAmount = response.data?.cashoutAmount
          ? parseFloat(response.data.cashoutAmount / 100).toFixed(2)
          : '';
        commit(types.UPDATE_CASHOUT_AMOUNT, cashoutAmount);
      })
      .catch(() => {
        commit(types.UPDATE_CASHOUT_AMOUNT, '');
        commit(types.UPDATE_BETSLIP_ERROR, {
          cashout: {
            message: 'Could not return cashout value.',
            type: 'error',
          },
        });
      });
  },
  clearCashout({ commit }) {
    commit(types.UPDATE_CASHOUT_AMOUNT, '');
  },
  subscribeToSgpChanges({ getters }, eventId) {
    if (!socket.connected || !getters.event) return;
    socket.subscribeToSgpChanges(eventId);
  },
  unsubscribeToSgpChanges({ getters }, eventId) {
    if (!socket.connected || !getters.event) return;
    socket.unsubscribeToSgpChanges(eventId);
  },
  setSelectedEventNavigationTab({ commit }, tab) {
    commit(types.UPDATE_SELECTED_EVENT_NAVIGATION_TAB, tab);
  },
};
