<template>
  <div
    class="market-section-wrapper"
    ref="marketRef"
  >
    <EventMarketCodeSection
      :market-display-configuration="marketDisplayConfiguration"
      :markets-internal-source="marketsInternalSource"
      :sport="sport"
      :competition="competition"
      :event-id="eventId"
      :teams="teams"
      :has-sgp="hasSgp"
      :is-usa-view="isUsaView"
      :all-market-parameters="allMarketParameters"
      :market-visible="marketVisible"
    />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { each } from 'lodash';
import EventMarketCodeSection from './EventMarketCodeSection';

export default {
  components: {
    EventMarketCodeSection,
  },
  props: {
    marketDisplayConfiguration: {
      type: Object,
      required: true,
    },
    allMarketParameters: {
      type: Array,
      required: true,
    },
    marketsInternalSource: {
      type: String,
      required: true,
    },
    sport: {
      type: Object,
      default: null,
    },
    competition: {
      type: Object,
      default: null,
    },
    teams: {
      type: Object,
      default: null,
    },
    eventId: {
      type: String,
      default: null,
    },
    hasSgp: {
      type: Boolean,
      default: false,
    },
    isUsaView: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const observer = ref(null);
    const marketVisible = ref(false);
    const marketRef = ref(null);

    onMounted(() => {
      const options = {
        root: null,
        threshold: 0.001,
      };
      observer.value = new IntersectionObserver((entries) => {
        each(entries, (entry) => {
          marketVisible.value = entry.isIntersecting;
        });
      }, options);
      observer.value.observe(marketRef.value);
    });

    onUnmounted(() => {
      observer.value = null;
    });

    return {
      marketVisible,
      marketRef,
    };
  },
};
</script>
