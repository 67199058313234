<template>
  <div class="desktop-competition-filter">
    <ScrollControl>
      <CompetitionFilter />
    </ScrollControl>
  </div>
</template>

<script>
import ScrollControl from '@/components/common/ScrollControl';
import CompetitionFilter from '@/components/event-list/common/CompetitionFilter';

export default {
  components: {
    ScrollControl,
    CompetitionFilter,
  },
};
</script>

<style lang="scss">
.desktop-competition-filter {
  position: sticky;
  top: 5.875rem;
  padding-bottom: 1rem;
  z-index: 250;

  &::before {
    background: #FAFAFA;
    position: absolute;
    top: -2rem;
    left: 0;
    width: 100%;
    height: calc(100% + 2rem);
    content: '';
  }
}
</style>
