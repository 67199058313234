<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 1C12.5523 1 13 1.44772 13 2V3H14C16.7614 3 19 5.23858 19 8C19 8.55228 18.5523 9 18 9C17.4477 9 17 8.55228 17 8C17 6.34315 15.6569 5 14 5H13V11H14C16.7614 11 19 13.2386 19 16C19 18.7614 16.7614 21 14 21H13V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V21H10C7.23858 21 5 18.7614 5 16C5 15.4477 5.44772 15 6 15C6.55228 15 7 15.4477 7 16C7 17.6569 8.34315 19 10 19H11V13H10C7.23858 13 5 10.7614 5 8C5 5.23858 7.23858 3 10 3H11V2C11 1.44772 11.4477 1 12 1ZM11 5H10C8.34315 5 7 6.34315 7 8C7 9.65685 8.34315 11 10 11H11V5ZM13 13V19H14C15.6569 19 17 17.6569 17 16C17 14.3431 15.6569 13 14 13H13Z"
      fill="#191414"
    />
  </svg>
</template>
