export default {
  production: {
    socketApi: 'https://sio.phxp.huddle.tech',
    sgp: 'https://sgp.phxp.huddle.tech',
    gql: 'https://gqls.phxp.huddle.tech/graphql',
    backofficeUrl: 'https://backoffice.huddle.tech',
    googleAnalyticsId: 'G-KD66DYGJX9',
    cognitoPool: {
      region: 'us-east-2',
      UserPoolId: 'us-east-2_7ixLbwXDl',
      ClientId: '6kr8jmkku5b11p5rlrfet704s0',
      scope: 'openid',
    },
    charon: 'https://c.phxp.huddle.tech',
  },
  dev: {
    socketApi: 'https://sio.d.huddle.tech',
    sgp: 'https://sgp.d.huddle.tech',
    gql: 'https://gqls.d.huddle.tech/graphql',
    backofficeUrl: 'https://backoffice-huddle-dev.netlify.app',
    cognitoPool: {
      region: 'eu-west-1',
      UserPoolId: 'eu-west-1_gpOlmyIX9',
      ClientId: '2qfjog0k4ce96oo4fvdf8jr2e',
      scope: 'openid',
    },
    charon: 'https://c.d.huddle.tech',
  },
  staging: {
    socketApi: 'https://sio.s.huddle.tech',
    sgp: 'https://sgp.s.huddle.tech',
    gql: 'https://gqls.s.huddle.tech/graphql',
    backofficeUrl: 'https://backoffice-staging.huddle.tech',
    cognitoPool: {
      region: 'eu-west-1',
      UserPoolId: 'eu-west-1_gpOlmyIX9',
      ClientId: '2qfjog0k4ce96oo4fvdf8jr2e',
      scope: 'openid',
    },
    charon: 'https://c.s.huddle.tech',
  },
};
