<template>
  <div
    v-if="visibleResults.length"
    class="search-result-list-group"
  >
    <div class="search-result-list-group__title">
      {{ title }}
    </div>
    <div class="search-result-list-group__content">
      <SearchResultListItem
        v-for="(result, index) in visibleResults"
        :key="index"
        :result="result"
      />
      <div
        v-if="hasMore && collapsed"
        @click="collapsed = false"
        class="search-result-list-group__button"
      >
        Show more
      </div>
    </div>
  </div>
</template>

<script>
import { take } from 'lodash';
import { ref, computed } from 'vue';
import SearchResultListItem from './SearchResultListItem';

const SHOW_MORE_RESULTS_LIMIT = 4;

export default {
  components: {
    SearchResultListItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const collapsed = ref(true);
    const hasMore = computed(() => props.results.length > SHOW_MORE_RESULTS_LIMIT);
    const visibleResults = computed(() => {
      if (!hasMore.value || !collapsed.value) return props.results;
      return take(props.results, SHOW_MORE_RESULTS_LIMIT);
    });

    return {
      hasMore,
      collapsed,
      visibleResults,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-result-list-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.search-result-list-group__title {
  background-color: #fff;
  color: #A9A9A9;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.search-result-list-group__content {
  display: flex;
  flex-direction: column;
}

.search-result-list-group__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  font-size: 0.875rem;
  cursor: pointer;
  user-select: none;
  border-radius: 0.25rem;
  color: #191414;

  &:hover {
    background-color: #FAFAFA;
  }

  &:active {
    background-color: #F0F0F0;
  }
}
</style>
