<template>
  <div class="event-scoreboard-wrapper">
    <div class="event-scoreboard">
      <div class="event-scoreboard-header">
        <span class="competition">{{ event.competition?.name }}</span>
        <div class="event-scoreboard-header-details">
          <div
            class="replay"
            v-if="event.type === 'REPLAY'"
          >
            REPLAY
          </div>
          <div
            class="replay"
            v-if="event.type === 'TEST'"
          >
            TEST
          </div>
          <div
            class="is-live"
            v-if="isLive"
          >
            <LiveIcon />
          </div>
          <div
            :class="[
              { 'time': !isLive },
              { 'time-period': isLive },
            ]"
          >
            {{ periodLabel }}
          </div>
        </div>
      </div>
      <div class="event-scoreboard-body">
        <div :class="['team home', { 'usa-view ': event.isUSAView }]">
          <EventScoreboardCompetitor
            :name="event.home.name"
            side="HOME"
            :team-in-possession="event.teamInPossession"
            :is-live="event.matchState === 'LIVE'"
            :sport-label="event.sport.sportLabel"
            :is-usa-view="event.isUSAView"
            :score="event.home.score"
            :period="event.period"
            :timeouts-left="event.home.timeoutsLeft"
          />
        </div>
        <div :class="['team away', { 'usa-view ': event.isUSAView }]">
          <EventScoreboardCompetitor
            :name="event.away.name"
            side="AWAY"
            :team-in-possession="event.teamInPossession"
            :is-live="event.matchState === 'LIVE'"
            :sport-label="event.sport.sportLabel"
            :is-usa-view="event.isUSAView"
            :score="event.away.score"
            :period="event.period"
            :timeouts-left="event.away.timeoutsLeft"
          />
        </div>
      </div>
      <BaseballIndicators
        v-if="showBaseballIndicators"
        :balls="event.balls"
        :strikes="event.strikes"
        :outs="event.outs"
        :base1="event.base1"
        :base2="event.base2"
        :base3="event.base3"
      />
      <div
        v-if="showFootballIndicators"
        class="scoreboard__football-meta"
      >
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            Down
          </div>
          <div class="scoreboard__football-meta-value">
            {{ footballDownValue }}
          </div>
        </div>
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            To go
          </div>
          <div class="scoreboard__football-meta-value">
            {{ footballToGoValue }}
          </div>
        </div>
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            On
          </div>
          <div class="scoreboard__football-meta-value">
            {{ footballOnValue }}
          </div>
        </div>
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            Play running
          </div>
          <div class="scoreboard__football-meta-value">
            <BooleanIndicator
              v-if="footballPlayValueAvailable"
              :value="footballPlayValue"
            />
            <span v-else>
              -
            </span>
          </div>
        </div>
      </div>
      <PlayByPlay
        v-if="showPlayByPlay"
        :event="event"
      />
      <div
        v-if="showBaseballIndicators"
        class="scoreboard__baseball-meta"
      >
        <div class="scoreboard__baseball-meta-group">
          <div class="scoreboard__baseball-meta-label">
            <PitcherIcon />
            <span class="truncate">
              Pitcher
            </span>
          </div>
          <div
            class="scoreboard__baseball-meta-value"
            :title="pitcherName"
          >
            <span class="truncate">
              {{ pitcherName }}
            </span>
          </div>
        </div>
        <div class="scoreboard__baseball-meta-group">
          <div class="scoreboard__baseball-meta-label">
            <span class="truncate">
              Pitch Count
            </span>
          </div>
          <div class="scoreboard__baseball-meta-value">
            <span class="truncate">
              {{ pitchCount }}
            </span>
          </div>
        </div>
        <div class="scoreboard__baseball-meta-group">
          <div class="scoreboard__baseball-meta-label">
            <span class="truncate">
              Batting Order
            </span>
          </div>
          <div class="scoreboard__baseball-meta-value">
            <span class="truncate">
              {{ timesThrough }}
            </span>
          </div>
        </div>
        <div class="scoreboard__baseball-meta-group">
          <div class="scoreboard__baseball-meta-label">
            <HitterIcon />
            <span class="truncate">
              Hitter
            </span>
          </div>
          <div
            class="scoreboard__baseball-meta-value"
            :title="hitterName"
          >
            <span class="truncate">
              {{ hitterName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { find, isNil } from 'lodash';
import { useSquad } from '@/composables';
import { isPlayByPlayDisplayable } from '@/services/helpers/play-by-play';
import { LiveIcon, PitcherIcon, HitterIcon } from '@/components/svg';
import PlayByPlay from '@/components/common/PlayByPlay';
import BaseballIndicators from '@/components/common/BaseballIndicators';
import BooleanIndicator from '@/components/common/BooleanIndicator';
import EventScoreboardCompetitor from './EventScoreboardCompetitor';

export default {
  components: {
    LiveIcon,
    PitcherIcon,
    HitterIcon,
    PlayByPlay,
    BaseballIndicators,
    BooleanIndicator,
    EventScoreboardCompetitor,
  },
  props: {
    isPrematch: {
      type: Boolean,
      required: true,
    },
    isLive: {
      type: Boolean,
      required: true,
    },
    startsAt: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    periodLabel: {
      type: String,
      required: true,
    },
    teamInPossession: {
      type: String,
      default: '',
    },
    sportId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const showFootballPlayByPlay = computed(() => props.isLive && props.event?.sportId === '841e188b-0dcf-4f5f-974f-aa52c8cec95b');
    const showBasketballPlayByPlay = computed(() => props.isLive && props.event?.sportId === 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f');
    const showBaseballIndicators = computed(() => props.isLive && props.event?.sportId === '4e8eca10-6afa-44ed-af77-42414ec45cb3');
    const showFootballIndicators = computed(() => props.isLive && props.event?.sportId === '841e188b-0dcf-4f5f-974f-aa52c8cec95b');
    const showPlayByPlay = computed(() => isPlayByPlayDisplayable(props.event));

    const { squad } = useSquad(props.event);
    const pitcherName = computed(() => {
      const player = find(
        squad.value || [],
        ({ playerIndex }) => props.event?.currentPitcher?.index === playerIndex,
      );
      return player?.shortName || '-';
    });
    const pitchCount = computed(() => {
      const value = props.event?.currentPitcher?.stats?.pitchCount;
      return isNil(value) ? '-' : value;
    });
    const timesThrough = computed(() => {
      const value = props.event?.currentPitcher?.stats?.timesThrough;
      return isNil(value) ? '-' : value;
    });
    const hitterName = computed(() => {
      const player = find(
        squad.value || [],
        ({ playerIndex }) => props.event?.currentHitter?.index === playerIndex,
      );
      return player?.shortName || '-';
    });

    const footballDownValue = computed(() => (isNil(props.event.downNumber) ? '-' : props.event.downNumber));
    const footballToGoValue = computed(() => (isNil(props.event.yardsToWinDown) ? '-' : props.event.yardsToWinDown));
    const footballOnValue = computed(() => (isNil(props.event.yardsToEndZone) ? '-' : props.event.yardsToEndZone));
    const footballPlayValueAvailable = computed(() => !isNil(props.event.isPlayRunning));
    const footballPlayValue = computed(() => !!props.event.isPlayRunning);

    return {
      squad,
      showFootballPlayByPlay,
      showBasketballPlayByPlay,
      showBaseballIndicators,
      showFootballIndicators,
      showPlayByPlay,
      pitcherName,
      pitchCount,
      timesThrough,
      hitterName,
      footballDownValue,
      footballToGoValue,
      footballOnValue,
      footballPlayValueAvailable,
      footballPlayValue,
    };
  },
};

</script>

<style lang="scss">
  .event-scoreboard-wrapper {
    flex: 1;
    width: 100%;
    position: relative;
    z-index: 50;
  }

  .event-scoreboard {
    width: 100%;
    min-width: 250px;
    display: inline-flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 1rem;
    box-sizing: border-box;
    gap: 0.5rem;
    position: sticky;
    top: calc(68px + 1.5rem);

    .event-scoreboard-header {
      height: 24px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #A9A9A9;
      font-size: 0.875rem;

      .competition {
        @include truncate;
      }

      .event-scoreboard-header-details {
        display: flex;
        align-items: center;

        .replay {
          height: 16px;
          width: 54px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 8px;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #175FFF;
          border-radius: 100px;
          color: #175FFF;
          font-size: 10px;
        }

        .is-live, .time, .time-period {
          margin-left: 6px;
          white-space: nowrap;
        }

        .time-period {
          color: #175FFF;
        }
      }
    }

    .scoreboard__football-meta {
      display: grid;
      justify-content: space-around;
      grid-template-columns: 32px 32px 20px 71px;
      column-gap: 16px;
      padding: 8px 12px;
      width: 100%;
      margin: 0 auto;

      &-group {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &-label {
        display: flex;
        align-items: center;
        color: #6D6D6D;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        font-size: 10px;
        line-height: 11.85px;
        text-transform: uppercase;
        gap: 4px;

        .icon {
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
          max-width: 12px;
          max-height: 12px;
        }
      }

      &-value {
        color: #191414;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .event-scoreboard-body {
      display: flex;
      width: 100%;
      flex-direction: column;
      color: #191414;
      font-size: 1rem;
      gap: 0.5rem;

      .team {
        height: 44px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .team-possession-baseball {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1rem;
          height: 1rem;
          margin-right: 0.25rem;
        }

        .team-possession {
          width: 1rem;
          height: 1rem;
          margin-right: 0.25rem;
          fill: #CDCDCD;

          &.team-possession--active {
            fill: #175FFF;
          }
        }

        .team-name {
          @include truncate;
          display: inline-flex;
          align-items: center;
          margin-right: 0.5rem;
          text-transform: capitalize;

          span {
            color: #A9A9A9
          }
        }

        &.home {
          order: 1;

          &.usa-view {
            order: 2;
            margin-bottom: 0;
          }
        }

        &.away {
          order: 2;

          &.usa-view {
            order: 1;
          }
        }

        .team-score {
          font-weight: 600;
        }
      }
    }

    .scoreboard__baseball-meta {
      display: grid;
      grid-template-columns: 76px 65px 76px 76px;
      justify-content: space-between;
      gap: 8px;
      padding: 6px 2px;
      width: 100%;
      max-width: 336px;

      &-group {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &-label {
        display: flex;
        align-items: center;
        color: #6D6D6D;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        font-size: 10px;
        line-height: 11.85px;
        text-transform: uppercase;
        gap: 4px;

        svg {
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
          max-width: 12px;
          max-height: 12px;
        }
      }

      &-value {
        color: #191414;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-shrink: 0;
      }
    }

    .truncate {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
}

  @media screen and (max-width: $maxMobileBreakpoint) {
    .event-scoreboard-wrapper {
      width: 100%;
      max-width: 100%;
    }

    .event-scoreboard {
      width: 100%;
      border: 0;
      border-radius: 0;

      .event-scoreboard-header {
        font-size: 0.75rem;
      }

      .event-scoreboard-body {
        font-size: 0.875rem;

        .team {
          height: 24px;
        }
      }
    }
  }
</style>
