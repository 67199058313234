<template>
  <div
    :class="[
      'event-card',
      { 'event-card--usa-view': event.isUSAView },
    ]"
    @click="goToEvent"
  >
    <div class="event-card__body">
      <div class="event-card__row event-card__row--header">
        <div class="event-card__column event-card__column--main">
          <div class="event-card__competition-label">
            {{ competitionName }}
          </div>
        </div>
        <div class="event-card__column">
          {{ isMobile ? 'Money' : 'Moneyline' }}
        </div>
        <div class="event-card__column">
          {{ isMobile ? 'Total' : 'Total' }}
        </div>
        <div class="event-card__column">
          {{ isMobile ? 'Spread' : 'Spread' }}
        </div>
      </div>
      <div class="event-card__row event-card__row--competitor-a">
        <div class="event-card__column event-card__column--main">
          <EventCardCompetitor
            :name="event.away.name"
            side="AWAY"
            :team-in-possession="event.teamInPossession"
            :is-live="event.matchState === 'LIVE'"
            :sport-label="event.sport.sportLabel"
            :is-usa-view="event.isUSAView"
          />
        </div>
        <div
          v-for="market in eventMarkets"
          :key="market.marketId"
          class="event-card__column"
        >
          <EventCardMarket
            :event="event"
            :market="market"
            :selection="getSelectionForAwayCompetitor(market)"
          />
        </div>
      </div>
      <div class="event-card__row event-card__row--competitor-b">
        <div class="event-card__column event-card__column--main">
          <EventCardCompetitor
            :name="event.home.name"
            side="HOME"
            :team-in-possession="event.teamInPossession"
            :is-live="event.matchState === 'LIVE'"
            :sport-label="event.sport.sportLabel"
            :is-usa-view="event.isUSAView"
          />
        </div>
        <div
          v-for="market in eventMarkets"
          :key="market.marketId"
          class="event-card__column"
        >
          <EventCardMarket
            :event="event"
            :market="market"
            :selection="getSelectionForHomeCompetitor(market)"
          />
        </div>
      </div>
    </div>

    <div class="event-card__footer">
      <EventCardStatus
        :event="event"
      />
      <div class="event-card__footer-column">
        <div
          class="event-card__micro"
          v-if="hasMicroMarkets"
        >
          MICRO
        </div>
        <div
          class="event-card__sgp"
          v-if="hasSgpBetslip"
        >
          SGP
        </div>
        <div
          v-if="hasPlayerProps"
          class="event-card__player-props"
        >
          PROPS
        </div>
        <div class="event-card__view-all-markets">
          <span>
            {{ viewAllMarketsLabel }}
          </span>
          <ChevronRightIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  map,
  find,
  assign,
  filter,
} from 'lodash';
import { useBreakpoints } from '@/composables';
import MarketMapper from '@/services/helpers/market-mapper';
import { ChevronRightIcon } from '@/components/svg';
import EventCardCompetitor from './EventCardCompetitor';
import EventCardMarket from './EventCardMarket';
import EventCardStatus from './EventCardStatus';

export default {
  components: {
    EventCardMarket,
    EventCardStatus,
    ChevronRightIcon,
    EventCardCompetitor,
  },
  props: {
    event: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    markets: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { isMobile } = useBreakpoints();

    const teamInPossession = computed(() => props.event.teamInPossession);
    const competitionName = computed(() => props.event.competition?.name);
    const awayCompetitor = computed(() => props.event.away);
    const homeCompetitor = computed(() => props.event.home);
    const eventMarkets = computed(() => {
      let filteredEventMarkets = filter(props.event.markets, (eventMarket) => ( // Filter out basketball 3 way result market
        !(props.event.sportId === 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f'
          && eventMarket.selections.length === 3 && eventMarket.marketCode === 'RESULT')
      ));
      if (props.event.matchState === 'FINISHED') {
        filteredEventMarkets = [];
      }
      if (props.event.matchState === 'LIVE') {
        filteredEventMarkets = filter(filteredEventMarkets, { inPlay: true });
      }
      return map((props.markets),
        (headerMarket) => {
          const foundEventMarket = headerMarket.hasLine
            ? find(filteredEventMarkets, (eventMarket) => eventMarket.marketCode === headerMarket.code
              && eventMarket.marketSummary?.isMainLine && eventMarket.marketType.params.SELECTIONS === 2)
            : find(filteredEventMarkets, { marketCode: headerMarket.code });
          const emptySelections = [
            { price: { decimalValue: '-' } },
            { price: { decimalValue: '-' } },
          ];
          if (headerMarket.selections.length === 3) emptySelections.push({ price: { decimalValue: '-' } });

          if (!foundEventMarket) return { selections: emptySelections, hasLine: headerMarket.hasLine };

          return assign({}, foundEventMarket,
            {
              hasLine: headerMarket.hasLine,
              selections: MarketMapper.sortSelections({ selections: foundEventMarket.selections, isUsaView: props.event.isUsaView }),
            });
        });
    });
    const getSelectionByCode = (selections, code) => find(selections, { selectionType: { selectionCode: code } });
    const getSelectionForAwayCompetitor = ({ selections }) => getSelectionByCode(selections, 'AWAY') || getSelectionByCode(selections, 'OVER');
    const getSelectionForHomeCompetitor = ({ selections }) => getSelectionByCode(selections, 'HOME') || getSelectionByCode(selections, 'UNDER');

    const goToEvent = () => {
      store.dispatch('unsubscribeEventList');
      router.push({ name: 'event', params: { id: props.event.id } });
    };

    const hasSgpBetslip = computed(() => store.getters.betslipEnabled && props.event.hasSgp);
    const hasMicroMarkets = computed(() => props.event.hasMicroMarkets);
    const hasPlayerProps = computed(() => props.event.hasPlayerProps);

    const viewAllMarketsLabel = computed(() => (isMobile.value ? 'All markets' : 'View all markets'));

    return {
      competitionName,
      awayCompetitor,
      homeCompetitor,
      eventMarkets,
      getSelectionForAwayCompetitor,
      getSelectionForHomeCompetitor,
      goToEvent,
      isMobile,
      hasSgpBetslip,
      hasMicroMarkets,
      hasPlayerProps,
      teamInPossession,
      viewAllMarketsLabel,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 148px;
  background: #FFFFFF;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem;
  cursor: pointer;

  &:not(&.event-card--usa-view) {
    .event-card__row--competitor-a {
      order: 1;
    }
    .event-card__versus {
      display: none;
    }
  }
}

.event-card__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.event-card__row {
  display: flex;
  align-items: center;
  height: 2.75rem;
  gap: 0.25rem;

  &.event-card__row--header {
    height: 1.25rem;

    .event-card__column {
      color: #A9A9A9;
      text-transform: uppercase;
    }
  }
}

.event-card__column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  text-align: center;
  width: 100%;
  min-width: 5rem;
  height: 100%;
  @include truncate;

  &.event-card__column--main {
    font-size: 1rem;
    flex-basis: 53%;
    text-align: left;
    justify-content: flex-start;
  }
}

.event-card__versus {
  color: #A9A9A9;
  margin-left: 0.25rem;
}

.event-card__footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: #A9A9A9;
  margin-top: 0.5rem;
  height: 2rem;
  border-top: 1px solid #F0F0F0;
}

.event-card__competition-label {
  @include truncate;
}

.event-card__view-all-markets {
  display: flex;
  align-items: center;

  .chevron-right-icon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
    fill: #A9A9A9;
  }
}

@media screen and (max-width: 767px) {
  .event-card {
    font-size: 0.875rem;
  }

  .event-card__column {
    font-size: 0.75rem;

    &.event-card__column--main {
      font-size: 0.75rem;
      flex-basis: 12%;
    }
  }

  .event-card__row--competitor-a,
  .event-card__row--competitor-b {
    .event-card__column {
      font-size: inherit;
    }
  }

  .event-card__footer {
    font-size: 0.75rem;
  }
}

.event-card__footer-column {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.event-card__player-props, .event-card__sgp, .event-card__micro {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #F0F0F0;
  color: #CDCDCD;
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-size: 10px;
  border-radius: 2px;
  outline: 1px solid #F0F0F0;
  outline-offset: 1px;
  line-height: 12px;
  padding: 2px 5px;
  text-transform: uppercase;

  &.event-card__player-props--active {
    background: #175FFF;
    outline-color: #175FFF;
    color: #fff;
  }
}

.event-card__sgp {
  background: #175FFF;
  outline-color: #175FFF;
  color: #fff;
}

.event-card__micro {
  background: #FFC531;
  outline-color: #FFC531;
  color: #191414;
}
.event-card__player-props {
  background: #A63DF9;
  outline-color: #A63DF9;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .event-card {
    border: 1px solid #EBEBEB;
    border-radius: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .event-card__column {
    &.event-card__column--main {
      flex-basis: 25%;
    }
  }
}
</style>
