<template>
  <svg
    viewBox="0 0 429 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M142.387 0H154.342V21.7856C157.414 17.7664 162.714 15.2064 169.037 15.2064C179.712 15.2064 187.494 22.3744 187.494 35.456V63.232H175.539V37.0944C175.539 29.4912 171.341 25.3952 165.018 25.3952C158.515 25.3952 154.342 29.4912 154.342 37.0944V63.232H142.387V0Z"
      fill="#000"
    />
    <path
      d="M241.408 63.2321H229.35V57.2417C226.355 61.2609 221.158 63.8209 215.245 63.8209C204.211 63.8209 196.275 56.6529 196.275 43.6481V15.8977H208.256V41.9585C208.256 49.5617 212.454 53.6577 218.778 53.6577C225.28 53.6577 229.376 49.5617 229.376 41.9585V15.8977H241.434V63.2321H241.408Z"
      fill="#000"
    />
    <path
      d="M269.696 15.1296C275.942 15.1296 281.907 18.0224 285.158 22.4768V0H297.293V63.232H285.158V56.2176C282.342 60.5696 277.043 64 269.619 64C257.562 64 248.013 54.1696 248.013 39.3984C247.987 24.6016 257.562 15.1296 269.696 15.1296ZM272.691 25.6256C266.291 25.6256 260.224 30.4128 260.224 39.3728C260.224 48.3328 266.291 53.4784 272.691 53.4784C279.27 53.4784 285.261 48.512 285.261 39.552C285.261 30.592 279.27 25.6256 272.691 25.6256Z"
      fill="#000"
    />
    <path
      d="M325.504 15.1296C331.75 15.1296 337.715 18.0224 340.966 22.4768V0H353.101V63.232H340.966V56.2176C338.15 60.5696 332.851 64 325.427 64C313.37 64 303.821 54.1696 303.821 39.3984C303.795 24.6016 313.37 15.1296 325.504 15.1296ZM328.474 25.6256C322.074 25.6256 316.006 30.4128 316.006 39.3728C316.006 48.3328 322.074 53.4784 328.474 53.4784C335.053 53.4784 341.043 48.512 341.043 39.552C341.043 30.592 335.053 25.6256 328.474 25.6256Z"
      fill="#000"
    />
    <path
      d="M362.675 0H374.63V63.232H362.675V0Z"
      fill="#000"
    />
    <path
      d="M404.966 64C391.219 64 381.21 54.4256 381.21 39.552C381.21 24.6016 390.938 15.104 404.966 15.104C418.637 15.104 428.288 24.4224 428.288 38.528C428.288 40.064 428.211 41.6 427.955 43.136H393.344C393.933 50.1504 398.72 54.0672 404.71 54.0672C409.83 54.0672 412.646 51.5072 414.182 48.3328H427.085C424.525 57.088 416.666 64 404.966 64ZM393.421 35.1232H415.898C415.718 28.8768 410.778 24.96 404.608 24.96C398.899 24.96 394.368 28.6208 393.421 35.1232Z"
      fill="#000"
    />
    <path
      d="M119.373 0H123.878V1.3824H122.522V5.9648H120.73L120.755 1.3824H119.373V0ZM124.826 0H127.181L128.614 3.84L130.176 0H132.378V5.9904H130.739L130.714 2.2272L129.178 5.9904H127.974L126.438 2.0992V5.9904H124.851V0H124.826Z"
      fill="#B9A096"
    />
    <path
      d="M86.8352 16.896V0H74.88V11.52C79.0784 12.9024 83.072 14.72 86.8352 16.896Z"
      fill="#B9A096"
    />
    <path
      d="M80.8704 43.8784C80.4352 43.4688 79.9744 43.0848 79.5392 42.7008C79.488 42.6752 79.4624 42.624 79.4112 42.5984C78.976 42.24 78.5408 41.8816 78.08 41.5232C78.0032 41.472 77.952 41.4208 77.8752 41.3696C77.44 41.0368 76.9792 40.704 76.5184 40.3968C76.4416 40.3456 76.3904 40.2944 76.3136 40.2688C75.8528 39.9616 75.3664 39.6544 74.88 39.3472L74.8544 39.3216H35.8912V0H23.936V63.232H35.8912V51.2768H74.88V63.232H86.8352V51.0464C85.1456 48.4096 83.1232 46.0032 80.8704 43.8784Z"
      fill="#B9A096"
    />
    <path
      d="M98.7904 0V35.7888C88.4992 23.1936 72.8576 15.1552 55.3728 15.1552C50.7136 15.1552 46.1824 15.7184 41.856 16.7936V29.2352C46.1056 27.8528 50.6624 27.1104 55.3728 27.1104C76.9792 27.1104 94.976 42.7008 98.7648 63.232H110.771V0H98.7904Z"
      fill="#B9A096"
    />
    <path
      d="M11.9552 35.7888V0H0V63.232H12.0064C13.0304 57.7024 15.0784 52.5312 17.9456 47.9232V29.5168C15.7952 31.4624 13.7984 33.5616 11.9552 35.7888Z"
      fill="#B9A096"
    />
  </svg>
</template>
