<template>
  <div
    :class="[
      'play-by-play-action',
      { 'play-by-play-action--is-new': isNew }
    ]"
  >
    <div class="play-by-play-action__message">
      {{ message }}
    </div>
    <div class="play-by-play-action__timestamp">
      {{ clock }}
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    clock: {
      type: String,
      required: true,
    },
  },
  setup() {
    const timeoutId = ref(null);
    const isNew = ref(true);

    onMounted(() => {
      timeoutId.value = setTimeout(() => {
        isNew.value = false;
        clearTimeout(timeoutId.value);
        timeoutId.value = null;
      }, 2000);
    });
    onUnmounted(() => {
      if (!timeoutId.value) return;
      clearTimeout(timeoutId.value);
      timeoutId.value = null;
    });

    return {
      isNew,
    };
  },
};
</script>

<style lang="scss">
.play-by-play-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--radius-none, 0px);
  border-bottom: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
  height: 44px;
  min-height: var(--width-medium, 32px);
  padding: 0 4px;
  gap: var(--spacing-xsmall, 8px);
  flex-shrink: 0;
  align-self: stretch;

  &:last-of-type {
    border-bottom: none;
  }

  &--is-new {
    background: #E5EBEB;
  }

  &__message {
    color: var(--neutral-text-default-resting, #191414);
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__timestamp {
    color: #6D6D6D;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
  }
}
</style>
