<template>
  <div />
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch('logout').then(() => { router.push({ name: 'login' }); });

    return {
    };
  },
};
</script>
