<template>
  <div
    v-if="loading"
    class="search-result-list search-result-list--empty"
  >
    <Spinner />
  </div>
  <div
    v-else-if="searchResultsNotFound"
    class="search-result-list search-result-list--empty"
  >
    No results found
  </div>
  <div
    v-else-if="hasRecentResults"
    class="search-result-list"
  >
    <SearchResultListGroup
      title="Recent searches"
      :results="recentResults"
    />
  </div>
  <div
    v-else
    class="search-result-list"
  >
    <SearchResultListGroup
      title="Competitors"
      :results="competitorResults"
    />
    <SearchResultListGroup
      title="Events"
      :results="eventResults"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Spinner from '@/components/common/Spinner';
import SearchResultListGroup from './SearchResultListGroup';

export default {
  components: {
    Spinner,
    SearchResultListGroup,
  },
  setup() {
    const store = useStore();

    const loading = computed(() => store.getters.searchLoading);
    const recentResults = computed(() => store.getters.searchRecentResults);
    const competitorResults = computed(() => store.getters.searchCompetitorResults);
    const eventResults = computed(() => store.getters.searchEventResults);
    const searchResultsNotFound = computed(() => store.getters.searchResultsNotFound);
    const hasRecentResults = computed(() => !store.getters.searchQuery && recentResults.value.length);

    return {
      loading,
      recentResults,
      competitorResults,
      eventResults,
      searchResultsNotFound,
      hasRecentResults,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-result-list {
  padding: 0.5rem 0;

  &.search-result-list--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    color: #A9A9A9;
    padding: 1rem 0;
    cursor: default;
  }
}
</style>
