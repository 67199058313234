<template>
  <transition name="dropup">
    <div
      v-show="open"
      class="settings-dropdown"
    >
      <div class="settings-dropdown__header">
        <ArrowLeft
          class="settings-dropdown__header-button"
          @click="close"
        />
        Settings
      </div>
      <div class="settings-dropdown__content">
        <div class="settings-dropdown__group">
          <div class="settings-dropdown__group-header">
            Odds format
          </div>
          <div
            v-for="option in options"
            :key="option.id"
            class="settings-dropdown__group-item"
            @click.stop="updateSelection(option)"
          >
            <RadioInput
              :checked="option.id === selection.id"
            />
            {{ option.label }}
          </div>
        </div>
        <div
          v-if="showOperatorSelect"
          class="settings-dropdown__group"
        >
          <div class="settings-dropdown__group-header">
            Client view
          </div>
          <div
            v-for="option in operatorList"
            :key="option.operatorId"
            class="settings-dropdown__group-item"
            @click.stop="updateSelectedOperator(option.operatorId)"
          >
            <RadioInput
              :checked="option.operatorId === selectedOperator"
            />
            {{ option.operatorId }}{{ option.operatorId === SUPER_ADMIN_CLIENT ? '/Superadmin' : '' }}
          </div>
        </div>
      </div>
      <div class="settings-dropdown__footer">
        <div class="settings-dropdown__footer-username">
          {{ username }}
        </div>
        <div
          class="settings-dropdown__footer-logout-button"
          @click.stop="logout"
        >
          <div class="footer-logout-icon">
            <LogoutIcon />
          </div>
          Log out
        </div>
      </div>
    </div>
  </transition>
  <Backdrop
    :open="open"
    @update:open="close"
  />
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { filter } from 'lodash';
import RadioInput from '@/components/common/RadioInput';
import Backdrop from '@/components/common/Backdrop';
import ArrowLeft from '@/components/svg/ArrowLeft';
import { LogoutIcon } from '@/components/svg';
import { getSuperAdminData } from '@/services/helpers/super-admin';

export default {
  components: {
    RadioInput,
    Backdrop,
    ArrowLeft,
    LogoutIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:open': {
      type: Boolean,
    },
    onOperatorChange: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const { isSuperAdmin, SUPER_ADMIN_CLIENT, client } = getSuperAdminData();
    const showOperatorSelect = computed(() => !!(isSuperAdmin && store.getters.operatorsList?.length));

    const root = ref(null);
    const options = computed(() => store.getters.oddFormats);
    const selection = computed(() => store.getters.selectedOddFormat);
    const username = computed(() => store.getters.username);
    const operatorList = computed(() => [
      ...[{ operatorId: SUPER_ADMIN_CLIENT }],
      ...filter(store.getters.operatorsList, (item) => item.operatorId !== SUPER_ADMIN_CLIENT),
    ]);
    const selectedOperator = computed(() => client);

    const logout = () => {
      store.dispatch('logout').then(() => { router.push({ name: 'login' }); });
    };

    const close = () => {
      emit('update:open', false);
    };
    const updateSelection = (newSelection) => {
      if (selection.value.id === newSelection.id) return;
      store.dispatch('selectOddFormat', newSelection);
    };

    const updateSelectedOperator = (operator) => {
      emit('onOperatorChange', operator);
    };

    watch(
      () => props.open,
      (newOpen) => {
        if (newOpen) {
          document.body.classList.add('modal-open');
        } else {
          document.body.classList.remove('modal-open');
        }
      },
      { immediate: true },
    );

    return {
      root,
      options,
      selection,
      operatorList,
      selectedOperator,
      SUPER_ADMIN_CLIENT,
      close,
      updateSelection,
      username,
      updateSelectedOperator,
      logout,
      showOperatorSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
.settings-dropdown {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #DDDDDD;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  overflow-y: auto;
  z-index: 9999;
}

.settings-dropdown__header {
  display: flex;
  padding: 12px 16px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: #191414;
  stroke: #191414;
  font-family: Poppins;
  font-size: 15.72px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;

  .settings-dropdown__header-button {
    display: flex;
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: $buttonHoverBgColor;
      color: $buttonHoverTextColor;
    }

  }
}

.settings-dropdown__content {
  padding: 1rem 1.5rem 4.5rem;
}

.settings-dropdown__footer {
  height: 77px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 0;
  font-size: 14px;
  color: #191414;
  font-weight: 400;
  border-top: 1px solid #F0F0F0;
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 1000;

  .settings-dropdown__footer-username {
    height: 44px;
    line-height: 44px;
  }
  .settings-dropdown__footer-logout-button {
    height: 44px;
    display: flex;
    align-items: center;

    .footer-logout-icon {
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }
  }
}

.settings-dropdown__group {
  margin-bottom: 1.125rem;
}

.settings-dropdown__group-header {
  display: flex;
  align-items: center;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
}

.settings-dropdown__group-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 2.75rem;
  font-size: 0.875rem;
}
</style>
