import {
  cloneDeep,
  find,
  filter,
  keys,
  isEqual,
  isEmpty,
  isFunction,
} from 'lodash';

export const socketEventTypes = {
  EVENT_LIST: 'EVENT_LIST',
  EVENT: 'EVENT',
};

let eventsByType = {};

const has = (type, options) => {
  const events = eventsByType[type] || [];
  return !!find(events, (eventOptions) => isEqual(options, eventOptions));
};

export default {
  has,
  register(type, options, callback) {
    if (has(type, options)) return false;
    const events = eventsByType[type] || [];
    eventsByType[type] = [...events, options];
    if (isFunction(callback)) callback();
    return true;
  },
  unregister(type, options, callback) {
    if (!has(type, options)) return false;
    const events = eventsByType[type] || [];
    eventsByType[type] = filter(events, (eventOptions) => !isEqual(options, eventOptions));
    if (isFunction(callback)) callback();
    return true;
  },
  clear(type, callback) {
    const hasExisted = !isEmpty(eventsByType[type]);
    delete eventsByType[type];
    if (hasExisted && isFunction(callback)) callback();
  },
  clearAll() {
    eventsByType = {};
  },
  getEventTypes() {
    return keys(eventsByType);
  },
  getEventsByType(type) {
    return cloneDeep(eventsByType[type] || []);
  },
};
