<template>
  <transition name="fade">
    <div
      v-if="searchToggled"
      class="search-view"
    >
      <div class="search-view__header">
        <div class="search-view__header-row">
          <div class="navbar__action-item">
            <ArrowLeft
              class="navbar__action navbar__action--stroke"
              @click="closeSearch"
            />
          </div>
        </div>
        <div class="search-view__header-row">
          <SearchField autofocus />
        </div>
      </div>
      <div class="search-view__content">
        <SearchResultList />
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { ArrowLeft } from '@/components/svg';
import SearchField from '@/components/navbar/common/SearchField';
import SearchResultList from '@/components/navbar/common/SearchResultList';

export default {
  components: {
    ArrowLeft,
    SearchField,
    SearchResultList,
  },
  setup() {
    const store = useStore();
    const searchToggled = computed(() => store.getters.searchToggled);
    const closeSearch = () => {
      store.dispatch('closeSearch');
    };

    watch(
      searchToggled,
      (newSearchToggled) => {
        if (newSearchToggled) {
          document.body.classList.add('modal-open');
        } else {
          document.body.classList.remove('modal-open');
        }
      },
      { immediate: true },
    );

    return {
      searchToggled,
      closeSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-view {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9990;
  padding: 0.25rem 1rem 0;
}

.search-view__header {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
}

.search-view__header-close {
  height: 100%;
  aspect-ratio: 1/1;
}

.search-view__header-row {
  flex: 1;
  flex-basis: 2.75rem;
}

.search-bar__field {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9999;
}

.search-bar__icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  stroke: #000;
  padding: 0.7rem;
}

.search-bar__input {
  padding-left: 2.5rem;
  padding-right: 0.5rem;
  font-size: 14px;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #DDDDDD;
  outline: none !important;

  &::placeholder {
    color: #DDDDDD;
  }

  &:hover {
    border-color: #CDCDCD;
  }

  &:focus-visible {
    outline-width: 3px;
    outline-color: transparentize(#175FFF, 0.6);
    outline-offset: 2px;
  }
}

.search-view__content {
  flex: 1;
  overflow-y: auto;
}

.navbar__action-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  fill: #000;
  stroke: #000;
  border-radius: 0.25rem;

  &:active {
    background-color: #F0F0F0;
  }
}

.navbar__action {
  width: 1rem;
  height: 1rem;
  fill: inherit;
  stroke: inherit;
}
</style>
