<template>
  <div
    :class="[
      'scroll-control',
      { 'scroll-control--desktop': !isMobile },
    ]"
  >
    <transition
      v-if="!hideControls"
      name="fade"
    >
      <div
        v-show="canScrollLeft"
        class="scroll-control__button scroll-control__button--left"
      >
        <ChevronLeftIcon
          class="scroll-control__button-icon"
          @click="scrollLeft"
        />
      </div>
    </transition>
    <div
      ref="scroller"
      class="scroll-control__scroller"
    >
      <div ref="content">
        <slot />
      </div>
    </div>
    <transition
      v-if="!hideControls"
      name="fade"
    >
      <div
        v-show="canScrollRight"
        class="scroll-control__button scroll-control__button--right"
      >
        <ChevronRightIcon
          class="scroll-control__button-icon"
          @click="scrollRight"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue';
import { throttle } from 'lodash';
import { useWindowSize } from '@vueuse/core';
import { useBreakpoints } from '@/composables';
import { ChevronLeftIcon, ChevronRightIcon } from '@/components/svg';

const SCROLL_AMOUNT = 100;

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    hideControls: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isMobile } = useBreakpoints();
    const windowSize = useWindowSize();
    let resizeObserver = null;

    const scroller = ref(null);
    const content = ref(null);
    const viewportWidth = ref(0);
    const scrollPosition = ref(0);
    const canScrollLeft = computed(() => scrollPosition.value > 0);
    const canScrollRight = computed(() => scrollPosition.value < viewportWidth.value);

    const scroll = (delta) => {
      scroller.value.scrollTo({
        left: scroller.value.scrollLeft + delta,
        behavior: 'smooth',
      });
    };
    const scrollLeft = () => {
      scroll(-1 * SCROLL_AMOUNT);
    };
    const scrollRight = () => {
      scroll(SCROLL_AMOUNT);
    };

    const updateMeasures = () => {
      if (!scroller.value) return;

      const {
        scrollLeft: position,
        scrollWidth: width,
        offsetWidth: offset,
      } = scroller.value;
      scrollPosition.value = position;
      viewportWidth.value = width - offset;
    };
    onMounted(() => {
      scroller.value.addEventListener('scroll', updateMeasures);
      resizeObserver = new ResizeObserver(updateMeasures);
      resizeObserver.observe(content.value);
    });
    onBeforeUnmount(() => {
      scroller.value.removeEventListener('scroll', updateMeasures);
      resizeObserver.unobserve(content.value);
      resizeObserver.disconnect();
    });

    watch(windowSize.width, throttle(updateMeasures, 100));

    return {
      scroller,
      content,
      isMobile,
      canScrollLeft,
      canScrollRight,
      scrollLeft,
      scrollRight,
    };
  },
};
</script>

<style lang="scss" scoped>
.scroll-control {
  position: relative;
  display: flex;
  width: 100%;
  height: 2.75rem;
}

.scroll-control__button {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 46.35%, #FFFFFF 100%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  width: 2.75rem;
  height: 100%;
  z-index: 50;
  pointer-events: none;

  &.scroll-control__button--left {
    left: 0;
    justify-content: flex-end;
    transform: scale(-1);
  }
}

.scroll-control__button-icon {
  background: #fff;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.28rem 0;
  pointer-events: all;
  cursor: pointer;
}

.scroll-control__scroller {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.scroll-content.scroll-content--desktop {
  height: 2.75rem;

  .scroll-control__button {
    width: 1.375rem;
  }
}
</style>
