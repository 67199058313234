<template>
  <div class="event-navigation">
    <div
      :class="['event-navigation-tab', { 'selected': selectedEventNavigationTab === 'event-odds' }]"
      @click="selectScoreboardTab('event-odds')"
    >
      Event odds
    </div>
    <div
      :class="['event-navigation-tab', { 'selected': selectedEventNavigationTab === 'player-statistics' }]"
      v-if="event.sportId === '841e188b-0dcf-4f5f-974f-aa52c8cec95b' || event.sportId === 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f' || event.sportId === '4e8eca10-6afa-44ed-af77-42414ec45cb3'"
      @click="selectScoreboardTab('player-statistics')"
    >
      Player statistics
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();

    const selectedEventNavigationTab = computed(() => store.getters.selectedEventNavigationTab);

    const selectScoreboardTab = (tab) => {
      if (selectedEventNavigationTab.value === tab) return;
      store.dispatch('setSelectedEventNavigationTab', tab);
    };

    return {
      selectScoreboardTab,
      selectedEventNavigationTab,
    };
  },
};
</script>

<style lang="scss">
.event-navigation {
  font-size: 14px;
  height: 33px;
  width: 100%;
  box-shadow: inset 0px -2px 0px #CDCDCD;
  display: flex;
  margin: 0 0 16px;

  .event-navigation-tab {
    height: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.selected {
      box-shadow: inset 0px -2px 0px #003C3C;
    }
  }
}
</style>
