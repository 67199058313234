import {
  padStart,
  max,
  floor,
  isFinite,
} from 'lodash';

export const formatMinutesAndSeconds = (timeInSeconds) => {
  if (!isFinite(timeInSeconds)) return 'NaN:NaN';
  const minutes = padStart(max([floor(timeInSeconds / 60), 0]), 2, '0');
  const seconds = padStart(max([floor(timeInSeconds % 60), 0]), 2, '0');
  return `${minutes}:${seconds}`;
};

export const appendOrdinalSuffix = (number) => {
  if (!isFinite(number)) return undefined;
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;
  if (lastDigit === 1 && lastTwoDigits !== 11) return `${number}st`;
  if (lastDigit === 2 && lastTwoDigits !== 12) return `${number}nd`;
  if (lastDigit === 3 && lastTwoDigits !== 13) return `${number}rd`;
  return `${number}th`;
};
