<template>
  <div
    class="scoreboard-player-statistics-team-player-header"
    @click="() => { playerToggled = !playerToggled; }"
  >
    <ChevronUpIcon v-if="playerToggled" />
    <ChevronDownIcon v-else />
    {{ player.playerName }}
  </div>
  <component
    v-if="showDetails"
    :is="detailsComponent"
    :player="player"
    :event="event"
  />
</template>

<script>
import { ref, computed } from 'vue';
import { ChevronUpIcon, ChevronDownIcon } from '@/components/svg';
import PlayerStatisticsFootballDetails from './PlayerStatisticsFootballDetails';
import PlayerStatisticsBasketballDetails from './PlayerStatisticsBasketballDetails';
import PlayerStatisticsBaseballDetails from './PlayerStatisticsBaseballDetails';

export default {
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
    PlayerStatisticsFootballDetails,
    PlayerStatisticsBasketballDetails,
    PlayerStatisticsBaseballDetails,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    player: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const playerToggled = ref(false);
    const detailsComponent = computed(() => {
      switch (props.event.sportId) {
      case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
        return 'PlayerStatisticsFootballDetails';
      case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
        return 'PlayerStatisticsBasketballDetails';
      case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
        return 'PlayerStatisticsBaseballDetails';
      default:
        return '';
      }
    });
    const showDetails = computed(() => playerToggled.value && detailsComponent.value);

    return {
      playerToggled,
      detailsComponent,
      showDetails,
    };
  },
};
</script>

<style lang="scss">
  .scoreboard-player-statistics-team-player-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #E5EBEB;
    border-radius: 4px;
    padding: 0 8px;
    margin-bottom: 20px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: #003C3C;

    svg {
      width: 24px;
      height: 24px;
      color: #003C3C;
      margin-right: 4px;

      path {
        stroke-width: 1;
      }
    }
  }
</style>
