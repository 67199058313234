<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8047 4.02925C14.0651 4.2896 14.0651 4.71171 13.8047 4.97206L6.4714 12.3054C6.21106 12.5657 5.78894 12.5657 5.5286 12.3054L2.19526 8.97206C1.93491 8.71171 1.93491 8.2896 2.19526 8.02925C2.45561 7.7689 2.87772 7.7689 3.13807 8.02925L6 10.8912L12.8619 4.02925C13.1223 3.7689 13.5444 3.7689 13.8047 4.02925Z"
      fill="#191414"
    />
  </svg>
</template>
