<template>
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2069 8.41332C10.1924 7.90943 9.03634 7.72071 7.99824 7.99813C7.62546 9.38619 6.42692 10.544 5.02736 11.1036M9.13732 1.43054C7.9366 1.22289 6.70191 1.34823 5.56742 1.79293C4.43293 2.23764 3.44202 2.98471 2.70235 3.95297C1.96268 4.92124 1.50254 6.07367 1.37192 7.28505C1.24129 8.49644 1.44519 9.72045 1.96145 10.8241C2.47771 11.9278 3.28659 12.8688 4.3002 13.5451C5.31382 14.2214 6.49341 14.607 7.71081 14.66C8.92821 14.713 10.1369 14.4315 11.2055 13.8459C12.2741 13.2603 13.1617 12.3931 13.772 11.3385V11.3385C14.3093 10.4087 14.6142 9.3629 14.6606 8.29001C14.707 7.21712 14.4936 6.14889 14.0386 5.17614C13.5836 4.20339 12.9004 3.35489 12.047 2.70277C11.1937 2.05065 10.1955 1.61421 9.13732 1.43054V1.43054Z"
      stroke-width="1.2"
      stroke-miterlimit="10"
    />
    <path
      d="M5.02739 11.1035C3.34754 8.63496 2.99835 5.44931 3.97984 2.68262"
      stroke-width="1.2"
      stroke-miterlimit="10"
    />
    <path
      d="M12.2224 9.07422C10.9201 11.7532 8.34177 13.6404 5.46149 14.1698"
      stroke-width="1.2"
      stroke-miterlimit="10"
    />
    <path
      d="M6.80444 3.84346C9.76211 3.6085 12.6811 4.8654 14.5997 7.05931"
      stroke-width="1.2"
      stroke-miterlimit="10"
    />
    <path
      d="M13.1427 12.2441H13.1416"
      stroke-width="1.2"
      stroke-miterlimit="10"
    />
    <path
      d="M7.99725 8.00358C6.3325 6.33809 6.31551 3.01939 7.99725 1.33691"
      stroke-width="1.2"
      stroke-miterlimit="10"
    />
    <path
      d="M7.99728 8.00293C7.62545 9.38627 6.42691 10.5441 5.02735 11.1037C4.11947 11.467 3.12761 11.5755 2.22351 11.3358"
      stroke-width="1.2"
      stroke-miterlimit="10"
    />
    <path
      d="M7.99731 8.00508C9.03542 7.72766 10.1924 7.91638 11.206 8.42027C12.4244 9.02513 13.4342 10.0848 13.7711 11.3379"
      stroke-width="1.2"
      stroke-miterlimit="10"
    />
  </svg>
</template>
