export default {
  SET_INITIALIZED: 'SET_INITIALIZED',
  SET_SCROLL_POSITION: 'SET_SCROLL_POSITION',

  UPDATE_SPORTS: 'UPDATE_SPORTS',
  UPDATE_COMPETITIONS: 'UPDATE_COMPETITIONS',
  SET_COMPETITIONS_LOADING: 'SET_COMPETITIONS_LOADING',

  SET_EVENTS: 'SET_EVENTS',
  UPDATE_EVENTS: 'UPDATE_EVENTS',
  SET_EVENTS_PAGE: 'SET_EVENTS_PAGE',
  SET_EVENTS_LOADING: 'SET_EVENTS_LOADING',
  SET_EVENTS_PAGE_LOADING: 'SET_EVENTS_PAGE_LOADING',
  SET_EVENTS_FILTERS: 'SET_EVENTS_FILTERS',
  SET_MARKETS_FILTERS: 'SET_MARKETS_FILTERS',
  SET_EVENTS_PAGINATION: 'SET_EVENTS_PAGINATION',

  CONNECTION_OPENED: 'CONNECTION_OPENED',
  CONNECTION_CLOSED: 'CONNECTION_CLOSED',
  EVENT_UPDATED: 'EVENT_UPDATED',
  SET_LAZY_SUBSCRIBE: 'SET_LAZY_SUBSCRIBE',
  UPDATE_ODD_FORMAT: 'UPDATE_ODD_FORMAT',
  STORE_USER_TOKEN: 'STORE_USER_TOKEN',
  STORE_USER_NAME: 'STORE_USER_NAME',
  STORE_USER_OPERATOR: 'STORE_USER_OPERATOR',
  UPDATE_LOGIN_ERROR_MESSAGE: 'UPDATE_LOGIN_ERROR_MESSAGE',
  UPDATE_CHANGE_PASSWORD_ATTRIBUTES: 'UPDATE_CHANGE_PASSWORD_ATTRIBUTES',

  SELECT_SPORT: 'SELECT_SPORT',
  SELECT_COMPETITION: 'SELECT_COMPETITION',
  SELECT_DATE_RANGE: 'SELECT_DATE_RANGE',

  SET_EVENT: 'SET_EVENT',
  SET_EVENT_MARKETS: 'SET_EVENT_MARKETS',
  UPDATE_SINGLE_EVENT_MARKET: 'UPDATE_SINGLE_EVENT_MARKET',
  SET_EVENT_DISPLAY_MARKETS: 'SET_EVENT_DISPLAY_MARKETS',
  SET_ALL_MARKET_PARAMETERS: 'SET_ALL_MARKET_PARAMETERS',
  SET_EVENT_LOADING: 'SET_EVENT_LOADING',
  UPDATE_EVENT_TEAM_PLAYERS: 'UPDATE_EVENT_TEAM_PLAYERS',
  UPDATE_SELECTED_EVENT_NAVIGATION_TAB: 'UPDATE_SELECTED_EVENT_NAVIGATION_TAB',

  SELECT_MARKET_ID: 'SELECT_MARKET_ID',

  TOGGLE_MOB_SPORTS_ODDS_FILTER: 'TOGGLE_MOB_SPORTS_ODDS_FILTER',
  TOGGLE_MOB_EVENTS_FILTER: 'TOGGLE_MOB_EVENTS_FILTER',

  SET_SEARCH_TOGGLED: 'SET_SEARCH_TOGGLED',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_SEARCH_LOADING: 'SET_SEARCH_LOADING',
  SET_SEARCH_RECENT_RESULTS: 'SET_SEARCH_RECENT_RESULTS',
  SET_SEARCH_COMPETITOR_RESULTS: 'SET_SEARCH_COMPETITOR_RESULTS',
  SET_SEARCH_EVENT_RESULTS: 'SET_SEARCH_EVENT_RESULTS',
  SET_SEARCH_SELECTION: 'SET_SEARCH_SELECTION',
  SET_MARKET_GROUPS_BY_SPORT: 'SET_MARKET_GROUPS_BY_SPORT',
  SET_SELECTED_MARKET_GROUP: 'SET_SELECTED_MARKET_GROUP',
  SET_LATEST_LOADED_MARKET_GROUP_SPORT: 'SET_LATEST_LOADED_MARKET_GROUP_SPORT',

  SET_OPERATORS: 'SET_OPERATORS',
  SET_OPERATORS_LIST: 'SET_OPERATORS_LIST',

  UPDATE_BETSLIP: 'UPDATE_BETSLIP',
  UPDATE_BETSLIP_ERROR: 'UPDATE_BETSLIP_ERROR',
  UPDATE_CASHOUT_AMOUNT: 'UPDATE_CASHOUT_AMOUNT',
};
