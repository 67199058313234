import 'es6-promise/auto';
import '@/styles/main.scss';
import { createApp, h, provide } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import apolloClient from '@/services/apollo-client';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import { setupGoogleAnalytics } from '@/services/google-analytics';

createApp({
  setup() {
    setupGoogleAnalytics();
    provide(DefaultApolloClient, apolloClient);
  },
  render() {
    return h(App);
  },
})
  .use(router)
  .use(store)
  .mount('#app');
