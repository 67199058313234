/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import {
  split,
  reverse,
  join,
  take,
  sortBy,
  values,
  isNil,
  flatMap,
} from 'lodash';

export const createTeamOptions = (event) => sortBy([
  {
    id: '',
    label: 'All',
    order: 0,
  },
  {
    id: event.home.id,
    label: event.home.name,
    order: (event.isUSAView ? 2 : 1),
  },
  {
    id: event.away.id,
    label: event.away.name,
    order: (event.isUSAView ? 1 : 2),
  },
], 'order');

export const abbreviatePlayerName = (name) => {
  const [firstName, ...lastNames] = reverse(split(name, ', '));
  const firstNameInitial = take(firstName);
  const lastName = join(lastNames, ' ');
  return `${firstNameInitial}. ${lastName}`;
};

export const mapTeams = ({ home, away }) => ({
  [home.id]: {
    id: home.id,
    name: home.name,
  },
  [away.id]: {
    id: away.id,
    name: away.name,
  },
});

export const mapSquad = ({ teams, squad }) => {
  const players = [];

  for (const player of squad) {
    if (!player.player?.playerId) continue;

    const { name: playerName = '' } = player.player.personalInfo ?? {};
    const name = playerName ?? 'N/A';
    const shortName = playerName ? abbreviatePlayerName(playerName) : 'N/A';

    players.push({
      id: player.player.playerId,
      playerIndex: player.player.playerIndex,
      team: teams[player.player.teamId],
      name,
      shortName,
    });
  }

  return players;
};

export const mapStatistics = (lineup) => {
  if (isNil(lineup)) return null;

  const statisticsById = {};

  for (const player of lineup) {
    if (!player.playerId) continue;
    statisticsById[player.playerId] = player.playerStats;
  }

  return statisticsById;
};

export const mapPositions = (lineup) => {
  if (isNil(lineup)) return null;

  const statisticsById = {};

  for (const player of lineup) {
    if (!player.playerId) continue;
    statisticsById[player.playerId] = player.position;
  }

  return statisticsById;
};

export const mapPlayers = ({ squad, statistics, positions }) => {
  const players = {};

  for (const player of squad) {
    if (!player.id) continue;
    players[player.id] = {
      ...player,
      position: positions[player.id],
      stats: statistics[player.id],
    };
  }

  return values(players);
};

export const sortPlayers = (players, options = {}) => sortBy(
  players,
  [
    (player) => {
      const order = player.team.id === options.homeId ? -1 : 1;
      const orderFactor = options.isUSAView ? -1 : 1;
      return order * orderFactor;
    },
    'shortName',
  ],
);

export const mapPlayerIdsFromPlayerProps = ({ playerProps }) => {
  const playersFromProps = [
    ...(playerProps?.teamA?.playerProps || []),
    ...(playerProps?.teamB?.playerProps || []),
  ];
  return flatMap(
    playersFromProps,
    (player) => {
      if (!player.marketCodeDetails.length) return [];
      return player.playerId;
    },
  );
};
