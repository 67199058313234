export default {
  initialized: false,
  scrollPosition: {
    x: 0,
    y: 0,
  },

  sports: [],
  competitions: [],
  competitionsLoading: false,

  events: [],
  eventsLoading: false,
  eventsPageLoading: false,
  eventsTotalCount: 0,
  eventsPage: 1,
  eventsPageSize: 100,
  eventsFilters: {
    search: '',
    live: false,
    sports: [],
  },
  marketsFilters: '',
  eventsPagination: true,

  connection: null,
  socketUpdatedEvent: null,
  lazySubscribeEventId: null,
  oddFormats: [ // Will move this to CMS config when implemented
    {
      id: 'AMERICAN',
      name: 'american',
      label: 'American',
    },
    {
      id: 'DECIMAL',
      name: 'decimal',
      label: 'Decimal',
    },
    {
      id: 'FRACTIONAL',
      name: 'fractional',
      label: 'Fractional',
    },
    {
      id: 'PROBABILITY',
      name: 'probability',
      label: 'Probability',
    },
  ],
  selectedOddFormat: {
    id: 'AMERICAN',
    name: 'american',
    label: 'American',
  },
  userToken: '',
  username: '',
  operator: '',
  loginErrorMessage: '',
  changePasswordAttributes: null,

  selectedSportId: '',
  selectedCompetitionId: '',
  dateRanges: [
    {
      id: 1,
      label: 'Today',
      value: 0,
    },
    {
      id: 2,
      label: 'Next 3 days',
      value: 2,
    },
    {
      id: 3,
      label: 'Next 7 days',
      value: 6,
    },
    {
      id: 4,
      label: 'Previous 7 days',
      value: -7,
    },
  ],
  selectedDateRange: {
    id: 3,
    label: 'Next 7 days',
    value: 6,
  },

  event: null,
  eventMarkets: null,
  eventDisplayMarkets: null,
  allMarketParameters: null,
  eventLoading: false,
  selectedMarketId: 1,
  mobSportsOddsFilterToggled: false,
  mobEventsFilterToggled: false,
  selectedEventNavigationTab: 'event-odds',
  eventTeamPlayers: {
    home: [],
    away: [],
  },

  search: {
    toggled: false,
    query: '',
    loading: false,
    recentResults: [],
    competitorResults: [],
    eventResults: [],
    selection: null,
  },
  marketGroupsBySport: [],
  selectedMarketGroup: null,
  latestLoadedMarketGroupSport: null,

  operators: [],
  operatorsList: [],

  betslip: {
    bets: [],
    probability: '',
    totalPrice: '',
  },
  cashoutAmount: '',
  betslipError: {},
};
