import environment from '@/../config/env';
import { isFunction } from 'lodash';

const { googleAnalyticsId = '' } = environment ?? {};

const loadTagManager = (head) => new Promise((resolve, reject) => {
  try {
    const tagManagerScript = document.createElement('script');
    tagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    tagManagerScript.setAttribute('async', '');
    tagManagerScript.onload = () => {
      resolve();
    };
    head.appendChild(tagManagerScript);
  } catch (error) {
    reject(error);
  }
});

const initializeTagManager = (head) => new Promise((resolve, reject) => {
  try {
    const initScript = document.createElement('script');
    initScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${googleAnalyticsId}');
    `;
    initScript.onload = () => {
      resolve();
    };
    head.appendChild(initScript);
  } catch (error) {
    reject(error);
  }
});

export const setupGoogleAnalytics = async () => {
  const head = document.getElementsByTagName('head')[0];

  if (!head) {
    throw new Error('Fatal error: <head> section not found in the source code');
  }

  await loadTagManager(head);
  await initializeTagManager(head);
};

export const dispatchGoogleTag = (name, data) => {
  // eslint-disable-next-line no-undef
  if (!(window.gtag && isFunction(window.gtag))) {
    return;
  }

  // eslint-disable-next-line no-undef
  window.gtag('event', name, data ?? {});
};
