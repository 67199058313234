import { createRouter, createWebHistory } from 'vue-router';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const { isSuperAdmin, client } = getSuperAdminData();

  const jwtToken = localStorage.getItem('huddleDemoUserToken');
  if (!jwtToken && !to.meta.login) {
    next('/login');
    return;
  }

  // Covering the case after user login.
  // Here we set the initial value for the query client param.
  // We set it here because we need to have it inside the query params before we hit the first network request
  if (isSuperAdmin && !to.query.client) {
    const { query } = to;
    next({
      ...to,
      query: {
        ...query,
        client,
      },
    });
    return;
  }

  next();
});

export default router;
