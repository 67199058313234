<template>
  <div
    ref="root"
    class="user-dropdown"
  >
    <div
      :class="[
        'user-dropdown__field',
        { 'user-dropdown__field--active': open },
      ]"
      @click="toggleDropdown"
    >
      {{ username }}
      <ChevronUpIcon
        v-if="open"
        class="user-dropdown__icon"
      />
      <ChevronDownIcon
        v-else
        class="user-dropdown__icon"
      />
    </div>

    <transition name="dropdown">
      <div
        v-show="open"
        class="user-dropdown__list"
      >
        <div class="user-dropdown__list-header">
          Odds format
        </div>

        <div
          v-for="option in options"
          :key="option.id"
          class="user-dropdown__list-item"
          @click="updateSelection(option)"
        >
          <RadioInput
            :checked="option.id === selection.id"
          />
          {{ option.label }}
        </div>
        <div
          class="user-dropdown__list-separator"
        />
        <div
          class="user-dropdown__list-item"
          @click="logout"
        >
          <i class="user-dropdown__list-item-icon">
            <LogoutIcon />
          </i>
          Log out
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import { ChevronDownIcon, ChevronUpIcon, LogoutIcon } from '@/components/svg';
import RadioInput from '@/components/common/RadioInput';

export default {
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    LogoutIcon,
    RadioInput,
  },
  setup() {
    const root = ref(null);
    const open = ref(false);

    const store = useStore();
    const router = useRouter();

    const username = computed(() => store.getters.username);
    const options = computed(() => store.getters.oddFormats);
    const selection = computed(() => store.getters.selectedOddFormat);

    const toggleDropdown = () => {
      open.value = !open.value;
    };
    const updateSelection = (newSelection) => {
      if (selection.value.id === newSelection.id) return;
      store.dispatch('selectOddFormat', newSelection);
    };
    const logout = () => {
      store.dispatch('logout')
        .then(() => {
          router.push({ name: 'login' });
        });
    };

    onClickOutside(root, () => {
      open.value = false;
    });

    return {
      root,
      open,
      username,
      options,
      selection,
      toggleDropdown,
      updateSelection,
      logout,
    };
  },
};
</script>

<style lang="scss">
.user-dropdown {
  position: relative;
  user-select: none;
  font-size: 0.875rem;
  font-weight: 400;

  .user-dropdown__field {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    color: #6D6D6D;
    fill: #6D6D6D;
    transition: color linear 200ms, fill linear 200ms;

    &:hover,
    &.user-dropdown__field--active {
      color: #000;
      fill: #000;
    }
  }

  .user-dropdown__icon {
    width: 1rem;
    height: 1rem;
  }

  .user-dropdown__list {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    overflow: hidden;
    padding: 0.25rem 0;
    min-width: 8.75rem;
  }

  .user-dropdown__list-header {
    overflow: hidden;
    color: #6D6D6D;
    text-overflow: ellipsis;
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    padding: 0 0.5rem;
    height: 0.75rem;
  }

  .user-dropdown__list-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 0.5rem;
    height: 2rem;
    cursor: pointer;

    &:hover {
      background-color: #FAFAFA;
    }
  }

  .user-dropdown__list-separator {
    background: #F0F0F0;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
  }

  .user-dropdown__list-item-icon {
    width: 1rem;
    height: 1rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
