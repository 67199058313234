export default {
  1: {
    american: '-∞',
    fractional: '0/∞',
    probability: '100%',
  },
  1.01: {
    american: '-10000',
    fractional: '1/100',
    probability: '99.01%',
  },
  1.02: {
    american: '-5000',
    fractional: '1/50',
    probability: '98.04%',
  },
  1.03: {
    american: '-3333',
    fractional: '1/33',
    probability: '97.09%',
  },
  1.04: {
    american: '-2500',
    fractional: '1/25',
    probability: '96.15%',
  },
  1.05: {
    american: '-2000',
    fractional: '1/20',
    probability: '95.24%',
  },
  1.06: {
    american: '-1667',
    fractional: '1/16',
    probability: '94.34%',
  },
  1.07: {
    american: '-1429',
    fractional: '1/14',
    probability: '93.46%',
  },
  1.08: {
    american: '-1250',
    fractional: '1/13',
    probability: '92.59%',
  },
  1.09: {
    american: '-1111',
    fractional: '1/11',
    probability: '91.74%',
  },
  1.1: {
    american: '-1000',
    fractional: '1/10',
    probability: '90.91%',
  },
  1.11: {
    american: '-909',
    fractional: '1/9',
    probability: '90.09%',
  },
  1.12: {
    american: '-833',
    fractional: '2/17',
    probability: '89.29%',
  },
  1.13: {
    american: '-769',
    fractional: '2/15',
    probability: '88.5%',
  },
  1.14: {
    american: '-714',
    fractional: '1/7',
    probability: '87.72%',
  },
  1.15: {
    american: '-667',
    fractional: '2/13',
    probability: '86.96%',
  },
  1.16: {
    american: '-625',
    fractional: '2/13',
    probability: '86.21%',
  },
  1.17: {
    american: '-588',
    fractional: '1/6',
    probability: '85.47%',
  },
  1.18: {
    american: '-556',
    fractional: '2/11',
    probability: '84.75%',
  },
  1.19: {
    american: '-526',
    fractional: '2/11',
    probability: '84.03%',
  },
  1.2: {
    american: '-500',
    fractional: '1/5',
    probability: '83.33%',
  },
  1.22: {
    american: '-455',
    fractional: '2/9',
    probability: '81.97%',
  },
  1.25: {
    american: '-400',
    fractional: '1/4',
    probability: '80%',
  },
  1.28: {
    american: '-357',
    fractional: '2/7',
    probability: '78.13%',
  },
  1.3: {
    american: '-333',
    fractional: '3/10',
    probability: '76.92%',
  },
  1.33: {
    american: '-303',
    fractional: '1/3',
    probability: '75.19%',
  },
  1.35: {
    american: '-286',
    fractional: '7/20',
    probability: '74.07%',
  },
  1.36: {
    american: '-278',
    fractional: '4/11',
    probability: '73.53%',
  },
  1.4: {
    american: '-250',
    fractional: '2/5',
    probability: '71.43%',
  },
  1.44: {
    american: '-227',
    fractional: '4/9',
    probability: '69.44%',
  },
  1.45: {
    american: '-222',
    fractional: '9/20',
    probability: '68.97%',
  },
  1.47: {
    american: '-213',
    fractional: '8/17',
    probability: '68.03%',
  },
  1.5: {
    american: '-200',
    fractional: '1/2',
    probability: '66.67%',
  },
  1.53: {
    american: '-189',
    fractional: '8/15',
    probability: '65.36%',
  },
  1.57: {
    american: '-175',
    fractional: '4/7',
    probability: '63.69%',
  },
  1.6: {
    american: '-167',
    fractional: '3/5',
    probability: '62.5%',
  },
  1.62: {
    american: '-161',
    fractional: '8/13',
    probability: '61.73%',
  },
  1.63: {
    american: '-159',
    fractional: '5/8',
    probability: '61.35%',
  },
  1.66: {
    american: '-152',
    fractional: '2/3',
    probability: '60.24%',
  },
  1.7: {
    american: '-143',
    fractional: '7/10',
    probability: '58.82%',
  },
  1.72: {
    american: '-139',
    fractional: '8/11',
    probability: '58.14%',
  },
  1.8: {
    american: '-125',
    fractional: '4/5',
    probability: '55.56%',
  },
  1.83: {
    american: '-120',
    fractional: '5/6',
    probability: '54.64%',
  },
  1.9: {
    american: '-111',
    fractional: '9/10',
    probability: '52.63%',
  },
  1.91: {
    american: '-110',
    fractional: '10/11',
    probability: '52.36%',
  },
  1.95: {
    american: '-105',
    fractional: '20/21',
    probability: '51.28%',
  },
  2: {
    american: '+/-100',
    fractional: '1/1',
    probability: '50%',
  },
  2.05: {
    american: '+105',
    fractional: '21/20',
    probability: '48.78%',
  },
  2.1: {
    american: '+110',
    fractional: '11/10',
    probability: '47.62%',
  },
  2.2: {
    american: '+120',
    fractional: '6/5',
    probability: '45.45%',
  },
  2.25: {
    american: '+125',
    fractional: '5/4',
    probability: '44.44%',
  },
  2.3: {
    american: '+130',
    fractional: '13/10',
    probability: '43.48%',
  },
  2.38: {
    american: '+138',
    fractional: '11/8',
    probability: '42.02%',
  },
  2.4: {
    american: '+140',
    fractional: '7/5',
    probability: '41.67%',
  },
  2.5: {
    american: '+150',
    fractional: '3/2',
    probability: '40%',
  },
  2.6: {
    american: '+160',
    fractional: '8/5',
    probability: '38.46%',
  },
  2.63: {
    american: '+163',
    fractional: '13/8',
    probability: '38.02%',
  },
  2.7: {
    american: '+170',
    fractional: '17/10',
    probability: '37.04%',
  },
  2.75: {
    american: '+175',
    fractional: '7/4',
    probability: '36.36%',
  },
  2.8: {
    american: '+180',
    fractional: '9/5',
    probability: '35.71%',
  },
  2.88: {
    american: '+188',
    fractional: '15/8',
    probability: '34.72%',
  },
  2.9: {
    american: '+190',
    fractional: '19/10',
    probability: '34.48%',
  },
  3: {
    american: '+200',
    fractional: '2/1',
    probability: '33.33%',
  },
  3.1: {
    american: '+210',
    fractional: '21/10',
    probability: '32.26%',
  },
  3.13: {
    american: '+213',
    fractional: '17/8',
    probability: '31.95%',
  },
  3.2: {
    american: '+220',
    fractional: '11/5',
    probability: '31.25%',
  },
  3.25: {
    american: '+225',
    fractional: '9/4',
    probability: '30.77%',
  },
  3.3: {
    american: '+230',
    fractional: '23/10',
    probability: '30.3%',
  },
  3.38: {
    american: '+238',
    fractional: '19/8',
    probability: '29.59%',
  },
  3.4: {
    american: '+240',
    fractional: '12/5',
    probability: '29.41%',
  },
  3.5: {
    american: '+250',
    fractional: '5/2',
    probability: '28.57%',
  },
  3.6: {
    american: '+260',
    fractional: '13/5',
    probability: '27.78%',
  },
  3.75: {
    american: '+275',
    fractional: '11/4',
    probability: '26.67%',
  },
  3.8: {
    american: '+280',
    fractional: '14/5',
    probability: '26.32%',
  },
  4: {
    american: '+300',
    fractional: '3/1',
    probability: '25%',
  },
  4.2: {
    american: '+320',
    fractional: '16/5',
    probability: '23.81%',
  },
  4.33: {
    american: '+333',
    fractional: '10/3',
    probability: '23.09%',
  },
  4.5: {
    american: '+350',
    fractional: '7/2',
    probability: '22.22%',
  },
  4.6: {
    american: '+360',
    fractional: '18/5',
    probability: '21.74%',
  },
  5: {
    american: '+400',
    fractional: '4/1',
    probability: '20%',
  },
  5.5: {
    american: '+450',
    fractional: '9/2',
    probability: '18.18%',
  },
  6: {
    american: '+500',
    fractional: '5/1',
    probability: '16.67%',
  },
  6.5: {
    american: '+550',
    fractional: '11/2',
    probability: '15.38%',
  },
  7: {
    american: '+600',
    fractional: '6/1',
    probability: '14.29%',
  },
  7.5: {
    american: '+650',
    fractional: '13/2',
    probability: '13.33%',
  },
  8: {
    american: '+700',
    fractional: '7/1',
    probability: '12.5%',
  },
  8.5: {
    american: '+750',
    fractional: '15/2',
    probability: '11.76%',
  },
  9: {
    american: '+800',
    fractional: '8/1',
    probability: '11.11%',
  },
  9.5: {
    american: '+850',
    fractional: '17/2',
    probability: '10.53%',
  },
  10: {
    american: '+900',
    fractional: '9/1',
    probability: '10%',
  },
  11: {
    american: '+1000',
    fractional: '10/1',
    probability: '9.09%',
  },
  20: {
    american: '+1900',
    fractional: '19/1',
    probability: '5%',
  },
  30: {
    american: '+2900',
    fractional: '30/1',
    probability: '3.33%',
  },
  40: {
    american: '+3900',
    fractional: '40/1',
    probability: '2.5%',
  },
  50: {
    american: '+4900',
    fractional: '50/1',
    probability: '2%',
  },
  60: {
    american: '+5900',
    fractional: '60/1',
    probability: '1.67%',
  },
  70: {
    american: '+6900',
    fractional: '70/1',
    probability: '1.43%',
  },
  80: {
    american: '+7900',
    fractional: '80/1',
    probability: '1.25%',
  },
  90: {
    american: '+8900',
    fractional: '90/1',
    probability: '1.11%',
  },
  100: {
    american: '+9900',
    fractional: '100/1',
    probability: '1%',
  },
  1000: {
    american: '+99900',
    fractional: '1000/1',
    probability: '0.1%',
  },
};
