<template>
  <div class="event-player-statistics">
    <div class="event-player-statistics-header-wrapper">
      <EventDetailsNavigation :event="event" />
      <div
        v-if="hasSomePlayers"
        class="event-player-statistics-header-search"
      >
        <div class="search-input">
          <div class="search-icon">
            <SearchIcon />
          </div>
          <input
            v-model="searchValue"
            placeholder="Search players"
          >
        </div>
      </div>
      <div
        v-if="hasSomePlayers"
        class="event-player-statistics-header-team-selection"
      >
        <div
          :class="['team-button', { 'selected': appliedTeamFilter.team === -1 }]"
          @click="applyTeamFilter(teamFilterData[0])"
        >
          Both teams
        </div>
        <div
          :class="['team-button', { 'selected': appliedTeamFilter.team === 0 }]"
          @click="applyTeamFilter(teamFilterData[1])"
        >
          {{ teamFilterData[1]?.label }}
        </div>
        <div
          :class="['team-button', { 'selected': appliedTeamFilter.team === 1 }]"
          @click="applyTeamFilter(teamFilterData[2])"
        >
          {{ teamFilterData[2]?.label }}
        </div>
      </div>
      <div
        v-if="isBaseball && hasSomePlayers"
        class="event-player-statistics-header-baseball-selection"
      >
        <div
          :class="['team-button', { 'selected': selectedPlayerType === 'pitchers' }]"
          @click="selectPlayerType('pitchers')"
        >
          Pitchers
        </div>
        <div
          :class="['team-button', { 'selected': selectedPlayerType === 'hitters' }]"
          @click="selectPlayerType('hitters')"
        >
          Hitters
        </div>
      </div>
    </div>
    <div class="event-player-statistics-body">
      <div
        class="no-content-found"
        v-if="!awayTeamPlayers.length && !homeTeamPlayers.length && !searchValue"
      >
        <NotFoundIcon />
        No players for this event at the moment
      </div>
      <div
        class="no-content-found"
        v-if="!awayTeamPlayers.length && !homeTeamPlayers.length && searchValue"
      >
        <NoResultsIcon />
        No results found
      </div>
      <div
        class="event-player-statistics-team"
        v-if="(appliedTeamFilter.team === -1 || appliedTeamFilter.team === 0)
          && (awayTeamPlayers.length || (!awayTeamPlayers.length && homeTeamPlayers.length))"
      >
        <div class="event-player-statistics-team-label">
          {{ event.away.name }}
        </div>
        <div
          class="event-player-statistics-team-player"
          v-for="player in awayTeamPlayers"
          :key="player.playerId"
        >
          <PlayerStatisticsDetails
            :player="player"
            :event="event"
          />
        </div>
        <div v-if="!awayTeamPlayers.length && homeTeamPlayers.length">
          No players found.
        </div>
      </div>
      <div
        class="event-player-statistics-team"
        v-if="(appliedTeamFilter.team === -1 || appliedTeamFilter.team === 1)
          && (homeTeamPlayers.length || (awayTeamPlayers.length && !homeTeamPlayers.length))"
      >
        <div class="event-player-statistics-team-label">
          {{ event.home.name }}
        </div>
        <div
          class="event-player-statistics-team-player"
          v-for="player in homeTeamPlayers"
          :key="player.playerId"
        >
          <PlayerStatisticsDetails
            :player="player"
            :event="event"
          />
        </div>
        <div v-if="!homeTeamPlayers.length && awayTeamPlayers.length">
          No players found.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, provide } from 'vue';
import { useStore } from 'vuex';
import {
  map, find, orderBy,
  trim, includes, toLower, filter,
} from 'lodash';
import { SearchIcon, NotFoundIcon, NoResultsIcon } from '@/components/svg';
import PlayerStatisticsDetails from './PlayerStatisticsDetails';
import EventDetailsNavigation from '../EventDetailsNavigation';

const FOOTBALL_ID = '841e188b-0dcf-4f5f-974f-aa52c8cec95b';
const FOOTBALL_PLAYER_POSITIONS = ['OFFENSIVE_NON_QB', 'QUARTERBACK', 'UNKNOWN'];

export default {
  components: {
    PlayerStatisticsDetails,
    EventDetailsNavigation,
    SearchIcon,
    NotFoundIcon,
    NoResultsIcon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const eventTeamPlayers = computed(() => store.getters.eventTeamPlayers);

    const searchValue = ref('');

    const hasSomePlayers = computed(() => props.event.homeLineup?.length && props.event.awayLineup?.length);

    const isBaseball = computed(() => props.event?.sportId === '4e8eca10-6afa-44ed-af77-42414ec45cb3');
    const selectedPlayerType = ref('pitchers');
    provide('selectedPlayerType', selectedPlayerType);
    const selectPlayerType = (type) => {
      selectedPlayerType.value = type;
    };

    const homeLineup = computed(() => {
      let lineup = props.event.homeLineup ?? [];
      if (props.event.sportId === FOOTBALL_ID) lineup = filter(lineup, ({ position }) => includes(FOOTBALL_PLAYER_POSITIONS, position));
      return lineup;
    });
    const awayLineup = computed(() => {
      let lineup = props.event.awayLineup ?? [];
      if (props.event.sportId === FOOTBALL_ID) lineup = filter(lineup, ({ position }) => includes(FOOTBALL_PLAYER_POSITIONS, position));
      return lineup;
    });

    const applyPlayerSearchFilter = (player) => includes(toLower(player.playerName), toLower(searchValue.value));
    const applyPlayerTypeFilter = (player) => {
      switch (selectedPlayerType.value) {
      case 'pitchers':
        return !!player.playerStats.pitcherDetails;
      case 'hitters':
        return !!player.playerStats.batterDetails;
      default:
        return false;
      }
    };
    const applyPlayerFilters = (player) => {
      if (!isBaseball.value) return applyPlayerSearchFilter(player);
      return applyPlayerTypeFilter(player) && applyPlayerSearchFilter(player);
    };

    const homeTeamPlayers = computed(() => orderBy(filter(map(homeLineup.value, (player) => {
      const foundPlayer = find(eventTeamPlayers.value?.home, (squadPlayer) => squadPlayer.playerId === player.playerId);
      const playerName = foundPlayer?.personalInfo?.name;
      const mappedPlayerName = playerName ? `${trim(playerName.split(',')[1])} ${playerName.split(',')[0]}` : 'N/A';
      return {
        ...player,
        playerName: mappedPlayerName,
      };
    }), applyPlayerFilters), 'playerName'));

    const awayTeamPlayers = computed(() => orderBy(filter(map(awayLineup.value, (player) => {
      const foundPlayer = find(eventTeamPlayers.value?.away, (squadPlayer) => squadPlayer.playerId === player.playerId);
      const playerName = foundPlayer?.personalInfo?.name;
      const mappedPlayerName = playerName ? `${trim(playerName.split(',')[1])} ${playerName.split(',')[0]}` : 'N/A';
      return {
        ...player,
        playerName: mappedPlayerName,
      };
    }), applyPlayerFilters), 'playerName'));

    const teamFilterData = computed(() => [
      {
        team: -1,
        label: 'Both Teams',
      },
      {
        team: 0,
        label: props.event.away.name,
      },
      {
        team: 1,
        label: props.event.home.name,
      },
    ]);

    const appliedTeamFilter = ref(teamFilterData.value[0]);

    const applyTeamFilter = (team) => { appliedTeamFilter.value = team; };

    return {
      hasSomePlayers,
      homeTeamPlayers,
      awayTeamPlayers,
      teamFilterData,
      appliedTeamFilter,
      applyTeamFilter,
      searchValue,
      isBaseball,
      selectedPlayerType,
      selectPlayerType,
    };
  },
};
</script>

<style lang="scss">
.event-player-statistics {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  width: 100%;

  .event-player-statistics-header-wrapper {
    width: 100%;
    padding: 1.5rem 1.5rem 0;
    background-color: #fff;
    position: sticky;
    top: calc(68px + 1.5rem);
    border: 1px solid #F0F0F0;
    border-bottom: none;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @media screen and (max-width: $maxMobileBreakpoint) {
      top: 56px;
    }

    &::after {
      position: absolute;
      background-color: #FAFAFA;
      left: -1px;
      top: calc(-1.5rem - 1px);
      width: calc(100% + 2px);
      height: 1.5rem;
      content: '';

      @media screen and (max-width: $maxMobileBreakpoint) {
        position: static;
        content: unset;
      }
    }

    .event-player-statistics-header-search {
      width: 100%;
      height: 44px;
      margin-bottom: 16px;

      .search-input {
        height: 100%;
        width: 100%;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        position: relative;

        .search-icon {
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 12px;
          top: 50%;
          transform: translateY(-50%);

          svg {
            height: 16px;
            width: 16px;
            stroke: #191414;
          }
        }

        input {
          outline: none;
          border: 0;
          height: 100%;
          width: 100%;
          color: #191414;
          border-radius: 4px;
          background-color: #FFF;
          font-size: 14px;
          padding-left: 44px;

          &::placeholder {
            color: #DDDDDD;
          }

          &:focus {
            box-shadow:  0 0 0 2px #658A8A;
            outline: none;
          }
        }
      }
    }

    .event-player-statistics-header-team-selection {
      width: 100%;
      height: 44px;
      display: flex;
      overflow-y: auto;

      .team-button {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
        background-color: #fff;
        color: #191414;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #F0F0F0;
        margin-right: 16px;
        cursor: pointer;
        white-space: nowrap;

        &.selected {
          color: #fff;
          background-color: #003C3C;
          border: 1px solid #003C3C;
        }
      }
    }
  }

  .event-player-statistics-header-baseball-selection {
    font-size: 14px;
    height: 33px;
    width: 100%;
    box-shadow: inset 0px -2px 0px #CDCDCD;
    display: flex;
    margin: 16px 0 0;

    .team-button {
      height: 100%;
      padding: 0 8px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.selected {
        box-shadow: inset 0px -2px 0px #003C3C;
      }
    }
  }

  .event-player-statistics-body {
    padding: 0 1.5rem 0;
    background-color: #fff;
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;

    .event-player-statistics-team {
      width: 100%;
      padding: 16px 0;

      .event-player-statistics-team-label {
        font-weight: 600;
        margin-bottom: 16px;
      }

      .event-player-statistics-team-player {
        width: 100%;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .no-content-found {
      flex: 1;
      align-items: center;
      justify-content: center;
      color: #A9A9A9;
      font-size: 1rem;
      gap: 0;
      width: 25rem;
      margin: 0 auto;
      text-align: center;
      padding-top: 24px;
      margin-bottom: 45px;

      svg {
        width: 100%;
        margin-bottom: 1.5rem;
        }
    }
  }
}
</style>
