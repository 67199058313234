<template>
  <div class="event-scoreboard-competitor">
    <div class="event-scoreboard-competitor__meta">
      <BaseballClock
        v-if="sportLabel === 'BASEBALL'"
        :side="side"
        :team-in-possession="teamInPossession"
      />
      <SportIcon
        v-else
        :class="[
          'event-scoreboard-competitor__possession-indicator',
          { 'event-scoreboard-competitor__possession-indicator--active': isInPossession },
          sportLabel
        ]"
        :sport-label="sportLabel"
      />
      <div class="event-scoreboard-competitor__competitor-label">
        {{ name }}
      </div>
      <div
        v-if="showSuffix"
        class="event-scoreboard-competitor__suffix"
      >
        @
      </div>
    </div>
    <div class="event-scoreboard-competitor__score">
      <FootballTimeoutIndicators
        v-if="isLive && sportLabel === 'AMERICAN_FOOTBALL'"
        :period="period"
        :timeouts-left="timeoutsLeft"
      />
      <span>
        {{ score }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import BaseballClock from '@/components/common/BaseballClock';
import FootballTimeoutIndicators from '@/components/common/FootballTimeoutIndicators';
import SportIcon from '@/components/common/SportIcon';

export default {
  components: {
    BaseballClock,
    SportIcon,
    FootballTimeoutIndicators,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    side: {
      type: String,
      required: true,
    },
    teamInPossession: {
      type: String,
      default: 'NEUTRAL',
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    sportLabel: {
      type: String,
      default: '',
    },
    isUsaView: {
      type: Boolean,
      default: false,
    },
    score: {
      type: Number,
      default: 0,
    },
    period: {
      type: String,
      default: '',
    },
    timeoutsLeft: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const showPossessionIndicator = computed(() => props.sportLabel !== 'SOCCER' && props.isLive && props.sportLabel);
    const showStandardPossessionIndicator = computed(() => props.sportLabel !== 'BASEBALL' && showPossessionIndicator.value);
    const isInPossession = computed(() => props.side === props.teamInPossession);
    const showSuffix = computed(() => props.isUsaView && props.side === 'AWAY');

    return {
      showPossessionIndicator,
      showStandardPossessionIndicator,
      isInPossession,
      showSuffix,
    };
  },
};
</script>

<style lang="scss">
.event-scoreboard-competitor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.25rem;
  width: 100%;

  .event-scoreboard-competitor__meta {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin-right: 0.25rem;
  }

  .event-scoreboard-competitor__score {
    display: grid;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    grid-template-columns: 1fr 1fr;
    text-align: right;
  }

  .event-scoreboard-competitor__possession-indicator {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
    fill: #CDCDCD;

    &.event-scoreboard-competitor__possession-indicator--active {
      fill: #175FFF;

      &.ICE_HOCKEY {
        stroke: #175FFF;
      }
    }
  }

  .event-scoreboard-competitor__competitor-label {
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .event-scoreboard-competitor__suffix {
    color: #A9A9A9;
    margin-left: 0.25rem;
  }
}
</style>
