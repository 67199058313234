import logos from '@/services/logos';

export default [
  {
    name: 'INTERNAL_AGGREGATION',
    logo: logos.findByName('HUDDLE'),
    markets: [],
    position: 1,
  },
  {
    name: 'DRAFTKINGS',
    logo: logos.findByName('DRAFTKINGS'),
    markets: [],
    position: 2,
  },
  {
    name: 'FANDUEL',
    logo: logos.findByName('FANDUEL'),
    markets: [],
    position: 3,
  },
  {
    name: 'BETMGM_NEWJERSEY',
    logo: logos.findByName('BET_MGM'),
    markets: [],
    position: 4,
  },
  {
    name: 'FIVE_DIMES',
    logo: logos.findByName('FIVE_DIMES'),
    markets: [],
    position: 5,
  },
  {
    name: 'BET_365',
    logo: logos.findByName('BET_365'),
    markets: [],
    position: 6,
  },
  {
    name: 'RUSHBET',
    logo: logos.findByName('RUSHBET'),
    markets: [],
    position: 7,
  },
  {
    name: 'PINNACLE',
    logo: logos.findByName('PINNACLE'),
    markets: [],
    position: 8,
  },
  {
    name: 'BWIN',
    logo: logos.findByName('BWIN'),
    markets: [],
    position: 9,
  },
  {
    name: 'LADBROKES',
    logo: logos.findByName('LADBROKES'),
    markets: [],
    position: 10,
  },
  {
    name: 'WILLIAM_HILL',
    logo: logos.findByName('WILLIAMHILL'),
    markets: [],
    position: 11,
  },
  {
    name: 'MARATHON',
    logo: logos.findByName('MARATHON'),
    markets: [],
    position: 12,
  },
  {
    name: 'TEN_BET',
    logo: logos.findByName('TEN_BET_CO_UK'),
    markets: [],
    position: 13,
  },
  {
    name: 'BET_188',
    logo: logos.findByName('BET_188'),
    markets: [],
    position: 14,
  },
  {
    name: 'BETVICTOR',
    logo: logos.findByName('BETVICTOR'),
    markets: [],
    position: 15,
  },
  {
    name: 'FONBET',
    logo: logos.findByName('FONBET'),
    markets: [],
    position: 16,
  },
  {
    name: 'SBO',
    logo: logos.findByName('SBO'),
    markets: [],
    position: 17,
  },
  {
    name: 'BETFAIR',
    logo: logos.findByName('BETFAIR_SB'),
    markets: [],
    position: 18,
  },
  {
    name: 'ISN',
    logo: logos.findByName('ISN'),
    markets: [],
    position: 19,
  },
];
