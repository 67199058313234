<template>
  <div
    class="navbar"
    :class="{ 'is-superadmin': isSuperAdminSelected }"
  >
    <div class="super-admin-chip">
      Superadmin
    </div>
    <div class="navbar__content">
      <HuddleNameLogo
        class="navbar__logo"
        @click="goHome"
      />
      <SearchView
        :absolute="isSearchAbsolute"
      />
      <div
        ref="navbarActionsElement"
        class="navbar__actions"
      >
        <OperatorDropdown
          ref="operatorDropdown"
          v-if="showOperatorSelect"
          class="operator-dropdown"
          @onOperatorChange="onOperatorChange"
        />
        <div
          v-if="showOperatorSelect"
          class="navbar__separator"
        />
        <UserDropdown
          ref="userDropdown"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { round } from 'lodash';
import { useElementSize, useWindowSize } from '@vueuse/core';
import { HuddleNameLogo } from '@/components/svg';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import SearchView from './SearchView';
import UserDropdown from './UserDropdown';
import OperatorDropdown from './OperatorDropdown';

export default {
  emits: ['onOperatorChange'],
  components: {
    HuddleNameLogo,
    SearchView,
    UserDropdown,
    OperatorDropdown,
  },
  props: {
    showOperatorSelect: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const navbarActionsElement = ref(null);
    const navbarActionsSize = useElementSize(navbarActionsElement);
    const windowSize = useWindowSize();
    const isSearchAbsolute = computed(() => {
      /*
       * Long usernames need to push search bar from the center
       */
      const elementWidth = navbarActionsSize.width.value;
      const availableWidth = windowSize.width.value;
      const takenSpacePercentage = round((elementWidth / availableWidth) * 100);
      return takenSpacePercentage < 25;
    });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { isSuperAdminSelected } = getSuperAdminData();

    const goHome = () => {
      if (route.name !== 'home') {
        router.push({
          name: 'home',
        });
      }

      store.dispatch('resetEvents');
    };

    const onOperatorChange = (operator) => emit('onOperatorChange', operator);

    return {
      navbarActionsElement,
      isSearchAbsolute,
      goHome,
      isSuperAdminSelected,
      onOperatorChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  align-items: center;
  background-color: $appHeaderBgColor;
  color: $appHeaderTextColor;
  box-shadow: $appHeaderBoxShadow;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: $appHeaderHeight;
  min-height: $appHeaderHeight;
  z-index: $appHeaderZIndex;

  .super-admin-chip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    display: none;
    color: #FFF;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    background-color: #003C3C;
    border-radius: 100px;
    padding: 2px 8px;
  }

  &.is-superadmin {
    border-bottom: 1px solid #003C3C;
    .super-admin-chip {
      display: inline-block;
    }
  }
}

.navbar__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 1rem;
  gap: 1rem;
  height: 100%;

  @media screen and (min-width: 1520px) {
    max-width: 1520px;
    padding: 0 1rem;
    margin: 0 auto;
  }
}

.navbar__actions {
  display: flex;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
}

.navbar__separator {
  background: #F0F0F0;
  width: 1px;
  min-width: 1px;
  max-width: 1px;
  height: calc(100% - 0.625rem - 0.625rem);
  margin: 0 0.75rem;
}

.navbar__logo {
  flex-shrink: 0;
  height: 1.125rem;
  cursor: pointer;
}
</style>
