import {
  isPlainObject,
  toLower,
  orderBy,
  map,
  find,
} from 'lodash';
import eventHelpers from '@/services/helpers/event';
import Event from './event';

const getCompetitorKey = (side, isUSAView = false) => {
  if (isUSAView && side === 'AWAY') return 'teamB';
  if (!isUSAView && side === 'AWAY') return 'teamA';
  if (isUSAView && side === 'HOME') return 'teamA';
  if (!isUSAView && side === 'HOME') return 'teamB';
  return '';
};

const makeScorePerPeriod = ({ side, details, isUSAView = false }) => {
  const key = getCompetitorKey(side, isUSAView);
  const inningDetails = Object.values(details?.inningDetails ?? {});

  return inningDetails.reduce(
    (scorePerPeriod, inning) => ({
      ...scorePerPeriod,
      [`INNING_${inning.inningNumber}`]: inning?.runs?.[key] ?? 0,
    }),
    {},
  );
};

const makeHitsPerPeriod = ({ side, details, isUSAView = false }) => {
  const key = getCompetitorKey(side, isUSAView);
  const inningDetails = Object.values(details?.inningDetails ?? {});

  return inningDetails.reduce(
    (scorePerPeriod, inning) => ({
      ...scorePerPeriod,
      [`INNING_${inning.inningNumber}`]: inning?.hits?.[key] ?? 0,
    }),
    {},
  );
};

const makeHomeRunsPerPeriod = ({ side, details, isUSAView = false }) => {
  const key = getCompetitorKey(side, isUSAView);
  const inningDetails = Object.values(details?.inningDetails ?? {});

  return inningDetails.reduce(
    (scorePerPeriod, inning) => ({
      ...scorePerPeriod,
      [`INNING_${inning.inningNumber}`]: inning?.homeRuns?.[key] ?? 0,
    }),
    {},
  );
};

const makeStrikeOutsPerPeriod = ({ side, details, isUSAView = false }) => {
  const key = getCompetitorKey(side, isUSAView);
  const inningDetails = Object.values(details?.inningDetails ?? {});

  return inningDetails.reduce(
    (scorePerPeriod, inning) => ({
      ...scorePerPeriod,
      [`INNING_${inning.inningNumber}`]: inning?.strikeOuts?.[key] ?? 0,
    }),
    {},
  );
};

const makePeriods = ({ details }) => {
  const inningDetails = details?.inningDetails ?? {};
  const keys = Object.keys(inningDetails);
  const numericKeys = keys.map((key) => +key).sort((a, b) => a - b);
  return numericKeys.map((number) => `INNING_${number}`);
};

const makeCurrentPeriod = ({ details }) => {
  const inningDetails = details?.inningDetails ?? {};
  const keys = Object.keys(inningDetails);
  const numericKeys = keys.map((key) => +key).sort((a, b) => a - b);
  const latestIndex = numericKeys?.[numericKeys.length - 1];
  const { status = 'FINISHED', inningNumber = 0 } = inningDetails[latestIndex] ?? {};

  return {
    id: `INNING_${inningNumber}`,
    number: inningNumber,
    status,
  };
};

/*
 * Opposite team in possession represents who's currently batting (who DOESN'T HAVE ball)
 */
const makeTeamInPossession = ({ period, isUSAView = false }) => {
  if (isUSAView && period.status === 'TOP') return 'AWAY';
  if (!isUSAView && period.status === 'TOP') return 'HOME';
  if (isUSAView && period.status === 'BOTTOM') return 'HOME';
  if (!isUSAView && period.status === 'BOTTOM') return 'AWAY';
  return '';
};

/*
 * Team in possession represents who's currently pitching (who HAS the ball)
 */
const makeOppositeTeamInPossession = ({ period, isUSAView = false }) => {
  if (isUSAView && period.status === 'TOP') return 'HOME';
  if (!isUSAView && period.status === 'TOP') return 'AWAY';
  if (isUSAView && period.status === 'BOTTOM') return 'AWAY';
  if (!isUSAView && period.status === 'BOTTOM') return 'HOME';
  return '';
};

const makeOutsCount = ({ inningDetails } = {}) => {
  if (!inningDetails) return 0;
  const inningDetailsKeys = orderBy(map(Object.keys(inningDetails), (key) => +key)).reverse();
  return inningDetails?.[inningDetailsKeys?.[0]]?.outs || 0;
};

const BaseballCompetitor = (event, { side, details, isUSAView = false }) => {
  const competitor = event?.[toLower(side)];
  if (!isPlainObject(competitor)) return null;

  const key = getCompetitorKey(side, isUSAView);

  return {
    ...competitor,
    score: details?.matchRuns?.[key] ?? 0,
    runs: details?.matchRuns?.[key] ?? 0,
    hits: details?.matchHits?.[key] ?? 0,
    homeRuns: details?.matchHomeRuns?.[key] ?? 0,
    strikeOuts: details?.strikeOuts?.[key] || 0,
    strikeOutsPerPeriod: makeStrikeOutsPerPeriod({ side, details }),
    scorePerPeriod: makeScorePerPeriod({ side, details, isUSAView }),
    hitsPerPeriod: makeHitsPerPeriod({ side, details, isUSAView }),
    homeRunsPerPeriod: makeHomeRunsPerPeriod({ side, details, isUSAView }),
  };
};

const makeCurrentPitcher = (options) => {
  if (!options.teamInPossession) return null;

  const idField = `${getCompetitorKey(options.teamInPossession, options.isUSAView)}Id`;
  const player = find(options.lineup, ({ playerIndex }) => options[idField] === playerIndex);
  if (!player) return null;

  const {
    playerId: id,
    playerIndex: index,
    playerStats: stats,
  } = player;
  return {
    id,
    index,
    stats: stats.pitcherDetails,
  };
};

const makeCurrentHitter = (options) => {
  if (!options.teamInPossession) return null;

  const idField = `${getCompetitorKey(options.teamInPossession, options.isUSAView)}Id`;
  const player = find(options.lineup, ({ playerIndex }) => options[idField] === playerIndex);
  if (!player) return null;

  const {
    playerId: id,
    playerIndex: index,
    playerStats: stats,
  } = player;
  return {
    id,
    index,
    stats: stats.batterDetails,
  };
};

export default (data) => {
  if (!isPlainObject(data)) return null;
  const event = Event(data);
  const details = eventHelpers.findEventDetails(data);
  const period = makeCurrentPeriod({ details });
  const lineup = [...(details?.homeLineup || []), ...(details?.awayLineup || [])];
  const teamInPossession = makeTeamInPossession({ period, isUSAView: event.isUSAView });

  return {
    ...event,
    home: BaseballCompetitor(event, { side: 'HOME', details, isUSAView: event.isUSAView }),
    away: BaseballCompetitor(event, { side: 'AWAY', details, isUSAView: event.isUSAView }),
    period: period.id,
    periodStatus: period.status,
    periods: makePeriods({ details }),
    isOvertimePossible: !details?.tiePossible,
    isClockRunning: ['TOP', 'BOTTOM'].includes(period.status),
    homeLineup: details?.homeLineup || [],
    awayLineup: details?.awayLineup || [],
    outs: makeOutsCount(details),
    balls: details?.pitchCount?.balls || 0,
    strikes: details?.pitchCount?.strikes || 0,
    base1: details?.loadedBases?.base1Loaded,
    base2: details?.loadedBases?.base2Loaded,
    base3: details?.loadedBases?.base3Loaded,
    teamInPossession,
    currentTeamAPitcherId: details?.currentTeamAPitcherId,
    currentTeamBPitcherId: details?.currentTeamBPitcherId,
    currentTeamABatterId: details?.currentTeamABatterId,
    currentTeamBBatterId: details?.currentTeamBBatterId,
    currentPitcher: makeCurrentPitcher({
      lineup,
      teamInPossession: makeOppositeTeamInPossession({
        period,
        isUSAView: event.isUSAView,
      }),
      teamAId: details?.currentTeamAPitcherId,
      teamBId: details?.currentTeamBPitcherId,
      isUSAView: event?.isUSAView,
    }),
    currentHitter: makeCurrentHitter({
      lineup,
      teamInPossession,
      teamAId: details?.currentTeamABatterId,
      teamBId: details?.currentTeamBBatterId,
      isUSAView: event?.isUSAView,
    }),
  };
};
