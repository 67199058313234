import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import routes from '@/../config/env';

const { cognitoPool } = routes;

const userPool = new CognitoUserPool(cognitoPool);
let cognitoUser = userPool.getCurrentUser();

export const isUserLoggedIn = () => new Promise((resolve, reject) => {
  cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) reject();
  cognitoUser.getSession((err, result) => {
    if (!err && result.isValid()) {
      resolve({
        token: result.idToken.jwtToken,
        userRoles: result.idToken.payload['cognito:groups'],
        username: result.idToken.payload['cognito:username'],
        operator: result.idToken.payload['custom:operator_id'],
      });
    } else {
      reject(err);
    }
  });
});

export const authenticateUser = (user) => {
  const authenticationData = {
    Username: user.username,
    Password: user.password,
  };

  const authenticationDetails = new AuthenticationDetails(
    authenticationData,
  );

  const userData = {
    Username: user.username,
    Pool: userPool,
  };
  cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        const idToken = session.idToken.jwtToken;
        resolve({
          message: 'token',
          data: {
            token: idToken,
            userRoles: session.idToken.payload['cognito:groups'],
            username: session.idToken.payload['cognito:username'],
          },
        });
      },
      onFailure: (err) => {
        reject(err.message || JSON.stringify(err));
      },
      newPasswordRequired: (userAttributes) => {
        resolve({ message: 'newPasswordRequired', data: userAttributes });
      },
    });
  });
};

export const changePassword = (payload) => new Promise((resolve, reject) => {
  const data = payload;
  delete data.userAttributes?.email;
  delete data.userAttributes?.email_verified;
  cognitoUser.completeNewPasswordChallenge(data.newPassword, data.userAttributes, {
    onSuccess: (result) => {
      resolve(result);
    },
    onFailure: (err) => {
      reject(err);
    },
  });
});

export const requestCode = (username) => new Promise((resolve, reject) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  cognitoUser = new CognitoUser(userData);
  cognitoUser.forgotPassword({
    onSuccess: (result) => {
      resolve(result);
    },
    onFailure: (err) => {
      reject(err);
    },
    inputVerificationCode() {
      resolve();
    },
  });
});

export const confirmNewPassword = (payload) => new Promise((resolve, reject) => {
  const userData = {
    Username: payload.username,
    Pool: userPool,
  };
  cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmPassword(payload.verificationCode, payload.newPassword, {
    onSuccess: (result) => {
      resolve(result);
    },
    onFailure: (err) => {
      reject(err);
    },
  });
});

export const logout = () => {
  cognitoUser.signOut();
};
