export const SOCCER_ID = '09eb8090-68f3-4a53-838f-f79e7a6912c3';
export const BASKETBALL_ID = 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f';
export const FOOTBALL_ID = '841e188b-0dcf-4f5f-974f-aa52c8cec95b';
export const ULTIMATE_ID = '6093d1bf-d572-486b-af56-96287ee0e865';
export const BASEBALL_ID = '4e8eca10-6afa-44ed-af77-42414ec45cb3';
export const RUGBY_LEAGUE_ID = '09315bc4-5ea0-4102-b467-c9558d30b1a1';
export const RUGBY_UNION_ID = '3e74c8f1-e268-493f-9b3a-e45e52d24446';
export const TENNIS_ID = '01330fb1-5b81-4b90-89fe-7f3796d8eb21';
export const VOLLEYBALL_ID = '87740346-f7ec-480c-bb89-c494a3b683ea';
export const HOCKEY_ID = 'db5e8b75-30a3-4a97-9112-f28b8e962887';
