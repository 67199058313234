<template>
  <div
    :class="[
      'event-card-status',
      { 'event-card-status--live': isLive },
    ]"
  >
    <Badge v-if="isReplay">
      Replay
    </Badge>
    <Badge v-if="isTest">
      Test
    </Badge>
    <SignalIcon
      class="event-card-status__icon"
      v-if="isLive"
    />
    {{ statusLabel }}
  </div>
</template>

<script>
import { computed } from 'vue';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { includes } from 'lodash';
import { useBreakpoints } from '@/composables';
import Event from '@/services/helpers/event';
import Soccer from '@/services/helpers/soccer';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Hockey from '@/services/helpers/hockey';
import Baseball from '@/services/helpers/baseball';
import Ultimate from '@/services/helpers/ultimate';
import Badge from '@/components/common/Badge';
import { SignalIcon } from '@/components/svg';

export default {
  components: {
    Badge,
    SignalIcon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isMobile } = useBreakpoints();
    const isReplay = computed(() => props.event.eventType === 'REPLAY');
    const isTest = computed(() => props.event.eventType === 'TEST');
    const dateTimeLabel = computed(() => {
      const eventDate = format(zonedTimeToUtc(props.event.startsAt, 'UTC'), 'MMM dd');
      const eventTime = format(zonedTimeToUtc(props.event.startsAt, 'UTC'), 'p');
      return `${eventDate} • ${eventTime}`;
    });
    const isLive = computed(() => Event.isLive(props.event));
    const isCanceled = computed(() => Event.isCanceled(props.event));
    const livePeriods = computed(() => {
      switch (props.event.sportId) {
      case '09eb8090-68f3-4a53-838f-f79e7a6912c3':
        return Soccer.getLivePeriods(props.event);
      case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
        return Basketball.getLivePeriods(props.event);
      case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
        return Football.getLivePeriods(props.event);
      case 'db5e8b75-30a3-4a97-9112-f28b8e962887':
        return Hockey.getLivePeriods(props.event);
      case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
        return Baseball.getLivePeriods(props.event);
      case '6093d1bf-d572-486b-af56-96287ee0e865':
        return Ultimate.getLivePeriods(props.event);
      default:
        return [];
      }
    });
    const periodLabel = computed(() => {
      if (isLive.value && !includes(livePeriods.value, props.event.period)) return 'Live';
      switch (props.event.sportId) {
      case '09eb8090-68f3-4a53-838f-f79e7a6912c3':
        return Soccer.getPeriodLabel(props.event);
      case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
        return Basketball.getPeriodLabel(props.event, { shortLabel: isMobile.value });
      case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
        return Football.getPeriodLabel(props.event, { shortLabel: isMobile.value });
      case 'db5e8b75-30a3-4a97-9112-f28b8e962887':
        return Hockey.getPeriodLabel(props.event, { shortLabel: isMobile.value });
      case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
        return Baseball.getPeriodLabel(props.event, { shortLabel: isMobile.value });
      case '6093d1bf-d572-486b-af56-96287ee0e865':
        return Ultimate.getPeriodLabel(props.event);
      default:
        return 'N/A';
      }
    });
    const statusLabel = computed(() => {
      if (isCanceled.value) return 'Canceled';
      if (isLive.value) return periodLabel.value;
      return dateTimeLabel.value;
    });

    return {
      isLive,
      isCanceled,
      isReplay,
      isTest,
      statusLabel,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-card-status {
  display: flex;
  align-items: center;
  color: #A9A9A9;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  gap: 0.25rem;

  &.event-card-status--live {
    color: #175FFF;
  }
}

.event-card-status__icon {
  fill: #FF2E2D;
  width: 1rem;
  height: 1rem;
}

@media screen and (max-width: 767px) {
  .event-card-status {
    font-size: inherit;
  }
}
</style>
