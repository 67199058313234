<template>
  <div class="search-field">
    <SearchIcon
      class="search-field__icon"
    />
    <input
      ref="searchInput"
      class="search-field__input"
      placeholder="Search events"
      :value="searchQuery"
      @input="search"
      @focus="openSearch"
    >
    <XIcon
      v-if="searchQuery"
      class="search-field__icon search-field__icon--clear"
      @click="clearSearch"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { SearchIcon, XIcon } from '@/components/svg';

export default {
  components: {
    SearchIcon,
    XIcon,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const searchQuery = computed(() => store.getters.searchQuery);
    const searchInput = ref(null);

    const search = debounce((event) => {
      store.dispatch('search', event.target.value);
    }, 250);
    const openSearch = () => {
      store.dispatch('openSearch');
    };
    const clearSearch = () => {
      store.dispatch('clearSearch');
      store.dispatch('loadEvents');
    };

    onMounted(() => {
      if (props.autofocus) { searchInput.value.focus(); }
    });

    return {
      searchQuery,
      searchInput,
      search,
      openSearch,
      clearSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9999;
}

.search-field__icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  stroke: #000;
  padding: 0.7rem;

  &.search-field__icon--clear {
    pointer-events: all;
    left: initial;
    right: 0;
  }
}

.search-field__input {
  padding: 0 2.5rem;
  font-size: 14px;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #DDDDDD;
  outline: none !important;

  &::placeholder {
    color: #DDDDDD;
  }

  &:hover {
    border-color: #CDCDCD;
  }
}
</style>
