import {
  isPlainObject, pickBy, isInteger, toLower,
} from 'lodash';
import eventHelpers from '@/services/helpers/event';
import Event from './event';

const FootballCompetitor = (event, { side, details }) => {
  const competitor = event?.[toLower(side)];
  if (!isPlainObject(competitor)) return null;

  return {
    ...competitor,
    score: details?.[`${toLower(side)}Score`],
    scorePerPeriod: pickBy(
      {
        IN_FIRST_PERIOD: details?.[`${toLower(side)}ScoreFirstQuarter`],
        IN_SECOND_PERIOD: details?.[`${toLower(side)}ScoreSecondQuarter`],
        IN_THIRD_PERIOD: details?.[`${toLower(side)}ScoreThirdQuarter`],
        IN_FOURTH_PERIOD: details?.[`${toLower(side)}ScoreFourthQuarter`],
        IN_OVERTIME: details?.[`${toLower(side)}ScoreOvertime`],
      },
      isInteger,
    ),
    timeoutsLeft: details?.[`${toLower(side)}TimeoutsLeft`],
  };
};

export default (data) => {
  if (!isPlainObject(data)) return null;
  const event = Event(data);
  const details = eventHelpers.findEventDetails(data);

  return {
    ...event,
    period: details?.period || 'N_A',
    gameTimeElapsedInPeriod: details?.gameTimeElapsedInPeriod || 0,
    quarterDuration: details?.quarterDuration || 0,
    overtimeDuration: details?.overtimeDuration || 0,
    isOvertimePossible: details?.overtimePossible || false,
    teamInPossession: details?.inPossession || 'NEUTRAL',
    home: FootballCompetitor(event, { side: 'HOME', details }),
    away: FootballCompetitor(event, { side: 'AWAY', details }),
    homeLineup: details?.homeLineup || [],
    awayLineup: details?.awayLineup || [],
    drives: details?.drives || {},
    downNumber: details?.downNumber,
    yardsToEndZone: details?.yardsToEndZone,
    yardsToWinDown: details?.yardsToWinDown,
    isPlayRunning: details?.isPlayRunning,
  };
};
