import { subHours, addHours } from 'date-fns';

export default () => {
  let startsAt = new Date();
  const currentDate = new Date();
  const timezoneHoursOffset = currentDate.getTimezoneOffset() / 60;
  if (timezoneHoursOffset >= 0) {
    startsAt = addHours(startsAt, -5);
  } else {
    startsAt = subHours(startsAt, 5);
  }
  return startsAt;
};
