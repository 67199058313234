import { computed } from 'vue';
import { useWindowSize } from '@vueuse/core';
import { MAX_MOBILE_WIDTH, MIN_DESKTOP_WIDTH } from '@/utils/breakpoints';

export default (options) => {
  const { width } = useWindowSize();
  const minDesktopWidth = options?.minDesktopWidth || MIN_DESKTOP_WIDTH;
  const maxMobileWidth = options?.maxMobileWidth || MAX_MOBILE_WIDTH;

  const isMobile = computed(() => width.value < minDesktopWidth);
  const isDesktop = computed(() => width.value > maxMobileWidth);

  return {
    isMobile,
    isDesktop,
  };
};
