<template>
  <Responsive>
    <template #desktop>
      <DesktopNavbar
        :show-operator-select="showOperatorSelect"
        @onOperatorChange="onOperatorChange"
      />
    </template>
    <template #mobile>
      <MobileNavbar @onOperatorChange="onOperatorChange" />
    </template>
  </Responsive>
</template>

<script>
import Responsive from '@/components/common/Responsive';
import DesktopNavbar from '@/components/navbar/desktop/Navbar';
import MobileNavbar from '@/components/navbar/mobile/Navbar';
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { getSuperAdminData } from '@/services/helpers/super-admin';

export default {
  components: {
    Responsive,
    DesktopNavbar,
    MobileNavbar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { isSuperAdmin } = getSuperAdminData();
    const showOperatorSelect = computed(() => !!(isSuperAdmin && store.getters.operatorsList?.length));

    const onOperatorChange = (operator) => {
      router.replace({
        ...route,
        query: {
          ...route.query,
          client: operator,
        },
      }).then(() => {
        // When the client is changed we want to refresh the page
        router.go(0);
      });
    };

    onMounted(() => {
      if (isSuperAdmin) {
        store.dispatch('getAllOperators');
      }
    });

    return {
      showOperatorSelect,
      onOperatorChange,
    };
  },
};
</script>
