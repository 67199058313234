<template>
  <div
    class="scoreboard-player-statistics-team-player-data"
    v-for="(stat, index) in playerFootballData"
    :key="index"
  >
    <div class="scoreboard-player-statistics-team-player-data-label">
      {{ stat.label }}
    </div>
    <div class="scoreboard-player-statistics-team-player-data-value">
      {{ player.playerStats?.[statsField]?.[stat.value] }}
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';

const statsByPlayerType = {
  pitchers: [
    {
      label: 'Hits',
      value: 'hits',
    },
    {
      label: 'Runs',
      value: 'runs',
    },
    {
      label: 'Home Runs',
      value: 'homeRuns',
    },
    {
      label: 'Walks',
      value: 'walks',
    },
    {
      label: 'Strikeouts',
      value: 'strikeOuts',
    },
    {
      label: 'Batters Faced',
      value: 'battersFaced',
    },
    {
      label: 'Stolen Bases',
      value: 'stolenBases',
    },
  ],
  hitters: [
    {
      label: 'At Bats',
      value: 'atBats',
    },
    {
      label: 'Runs',
      value: 'runs',
    },
    {
      label: 'Hits',
      value: 'hits',
    },
    {
      label: 'Singles',
      value: 'singles',
    },
    {
      label: 'Doubles',
      value: 'doubles',
    },
    {
      label: 'Triples',
      value: 'triples',
    },
    {
      label: 'Home Runs',
      value: 'homeRuns',
    },
    {
      label: 'Runs Batted In',
      value: 'rbis',
    },
    {
      label: 'Walks',
      value: 'walks',
    },
    {
      label: 'Strikeouts',
      value: 'strikeOuts',
    },
    {
      label: 'Hit-by-Pitch',
      value: 'hitByPitch',
    },
    {
      label: 'Total Bases',
      value: 'bases',
    },
  ],
};

export default {
  props: {
    player: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const selectedPlayerType = inject('selectedPlayerType');
    const statsField = computed(() => {
      switch (selectedPlayerType.value) {
      case 'pitchers':
        return 'pitcherDetails';
      case 'hitters':
        return 'batterDetails';
      default:
        return '';
      }
    });
    const playerToggled = ref(true);
    const playerFootballData = computed(() => statsByPlayerType[selectedPlayerType.value]);

    return {
      statsField,
      playerToggled,
      playerFootballData,
    };
  },
};
</script>

<style lang="scss">
  .scoreboard-player-statistics-team-player-data {
    display: flex;
    width: 100%;
    height: 33px;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    border-bottom: 1px solid #F0F0F0;
    font-size: 14px;

    &:nth-child(odd) {
      background-color: #FAFAFA;
    }
  }
</style>
