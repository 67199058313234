<template>
  <div class="navbar">
    <div
      :class="[
        'navbar__primary-container',
        { 'navbar__primary-container--shadow': !hasSecondaryContainer },
        { 'is-superadmin': isSuperAdminSelected },
      ]"
    >
      <div class="navbar__main">
        <HuddleNameLogo
          class="navbar__logo"
          @click="goHome"
        />
        <div class="super-admin-chip">
          Superadmin
        </div>
      </div>
      <div class="navbar__action-list">
        <div class="navbar__action-item">
          <SearchIcon
            class="navbar__action navbar__action--stroke"
            @click="openSearch"
          />
        </div>
        <div class="navbar__action-item">
          <GearIcon
            class="navbar__action"
            @click="openSettings"
          />
        </div>
      </div>
    </div>
    <transition name="drop">
      <div
        v-if="hasSecondaryContainer"
        v-show="showMenu"
        class="navbar__secondary-container"
      >
        <SportFilter />
      </div>
    </transition>
  </div>
  <SearchView />
  <SettingsDropdown
    v-model:open="isSettingsOpen"
    @onOperatorChange="onOperatorChange"
  />
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useScrollMenu } from '@/composables';
import { HuddleNameLogo, SearchIcon, GearIcon } from '@/components/svg';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import SportFilter from './SportFilter';
import SearchView from './SearchView';
import SettingsDropdown from './SettingsDropdown';

export default {
  emits: ['onOperatorChange'],
  components: {
    SportFilter,
    HuddleNameLogo,
    SearchIcon,
    GearIcon,
    SearchView,
    SettingsDropdown,
  },
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { isSuperAdminSelected } = getSuperAdminData();

    const isSettingsOpen = ref(false);
    const isHomeRoute = computed(() => route.name === 'home');
    const { showMenu } = useScrollMenu({
      initialValue: true,
      mobileOnly: true,
    });

    const openSearch = () => {
      store.dispatch('openSearch');
    };
    const openSettings = () => {
      isSettingsOpen.value = true;
    };
    const goHome = () => {
      if (!isHomeRoute.value) {
        router.push({
          name: 'home',
        });
      }

      store.dispatch('resetEvents');
    };

    const hasSports = computed(() => !!store.getters.sports?.length);
    const hasSecondaryContainer = computed(() => route.name === 'home');

    store.subscribe((mutation, state) => {
      if (mutation.type !== 'SET_BACKDROP' || state.backdrop) return;
      isSettingsOpen.value = false;
    });

    const onOperatorChange = (operator) => {
      emit('onOperatorChange', operator);
    };

    return {
      isSettingsOpen,
      isHomeRoute,
      showMenu,
      isSuperAdminSelected,
      openSearch,
      openSettings,
      goHome,
      hasSports,
      hasSecondaryContainer,
      onOperatorChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: $appHeaderBgColor;
  color: $appHeaderTextColor;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
}

.navbar__primary-container {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1rem;
  width: 100%;
  height: 3.5rem;
  position: relative;
  z-index: 300;

  .navbar__main {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .super-admin-chip {
    display: none;
    color: #FFF;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    background-color: #003C3C;
    border-radius: 100px;
    padding: 2px 8px;
  }

  &.is-superadmin {
    .super-admin-chip {
      display: inline-block;
    }
  }

  &.navbar__primary-container--shadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  }
}

.navbar__secondary-container {
  background: #fff;
  border-bottom: 2px solid #CDCDCD;
  position: fixed;
  width: 100%;
  height: 2.75rem;
}

.navbar__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1rem;
  width: 100%;
}

.navbar__logo {
  height: 1.125rem;
  cursor: pointer;
}

.navbar__action-list {
  display: flex;
}

.navbar__action-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  fill: #000;
  stroke: #000;
  border-radius: 0.25rem;

  &:active {
    background-color: #F0F0F0;
  }
}

.navbar__action {
  width: 1rem;
  height: 1rem;
  stroke: none;

  &.navbar__action--stroke {
    fill: none;
    stroke: inherit;
  }
}
</style>
