<template>
  <div class="sidebar">
    <div
      v-for="sport in sports"
      :key="sport.sportId"
      :class="[
        'sidebar__item',
        { 'sidebar__item--selected': sport.sportId === selectedSport.sportId },
      ]"
      :ref="ref => itemRefs[sport.sportId] = ref"
      @click="selectSport(sport)"
    >
      <div
        :class="[
          'sidebar__item-icon',
          { 'sidebar__item-icon--stroke': sport.sportLabel === 'VOLLEYBALL' },
        ]"
      >
        <SportIcon
          :sport-label="sport.sportLabel"
        />
      </div>
      {{ sport.sportName }}
    </div>
    <span
      v-if="selectedSport"
      class="sidebar__notch"
      :style="{
        top: `${selectionTop}px`,
      }"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import SportIcon from '@/components/common/SportIcon';

export default {
  components: {
    SportIcon,
  },
  setup() {
    const store = useStore();
    const sports = computed(() => store.getters.sports);
    const selectedSport = computed(() => {
      const sportId = store.getters.selectedSportId;
      return store.getters.sportById(sportId);
    });

    const itemRefs = ref({});
    const selectionTop = computed(() => {
      const itemRef = itemRefs.value[selectedSport.value?.sportId];
      if (!itemRef) return 0;
      return itemRef.offsetTop;
    });

    const selectSport = ({ sportId }) => {
      if (sportId === selectedSport.value?.sportId) return;
      store.dispatch('selectSport', sportId);
    };

    return {
      sports,
      selectedSport,
      itemRefs,
      selectionTop,
      selectSport,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: #fff;
  border: 1px solid #F0F0F0;
  border-radius: 0.5rem;
  padding: 1rem 0.5rem 1rem 0;
  width: 15rem;
  position: sticky;
  top: 5.75rem;
}

.sidebar__item {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 0.5rem;
  height: 2.75rem;
  padding: 1rem 0.5rem;
  border-radius: 0.25rem;
  user-select: none;
  cursor: pointer;
  fill: #000;
  stroke: #000;

  &::after {
    position: absolute;
    background: inherit;
    top: 0;
    left: -0.5rem;
    width: 0.25rem;
    height: 100%;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    content: '';
  }

  &:hover {
    background-color: #F0F0F0;
  }

  &.sidebar__item--selected {
    background-color: #E5EBEB;
    fill: #003C3C;
    stroke: #003C3C;

    &::after {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.sidebar__item-icon {
  fill: inherit;
  stroke: none;
  height: 1rem;
  margin-right: 0.25rem;
  aspect-ratio: 1 / 1;

  &.sidebar__item-icon--stroke {
    fill: none;
    stroke: inherit;
  }
}

.sidebar__notch {
  background-color: #003C3C;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 0.25rem;
  height: 2.75rem;
  transition: top 200ms;
}

@media screen and (max-width: 767px) {
  .sidebar {
    font-size: 0.875rem;
    width: 12rem;
  }
}
</style>
