<template>
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99332 1.33302C7.11709 1.33151 6.24926 1.50386 5.44009 1.84007C4.63092 2.17628 3.89649 2.66967 3.27932 3.29168C0.680656 5.89035 0.680656 10.1203 3.27932 12.7204C4.54266 13.9837 6.21599 14.679 7.99332 14.679C9.77066 14.679 11.4447 13.9837 12.7073 12.7204C15.3067 10.121 15.3067 5.89102 12.7073 3.29168C12.0902 2.66967 11.3557 2.17628 10.5466 1.84007C9.73739 1.50386 8.86956 1.33151 7.99332 1.33302ZM11.7647 11.7777C11.0657 12.4789 10.1845 12.9706 9.22066 13.197L9.21999 13.1724C9.21887 12.6572 9.31406 12.1465 9.50066 11.6664L8.25866 11.1804C7.99135 11.8679 7.86616 12.6024 7.89066 13.3397C6.51101 13.316 5.19495 12.7554 4.22199 11.777C3.24721 10.8023 2.68969 9.48668 2.66732 8.10835C2.72066 8.10968 2.77399 8.11168 2.82732 8.11168C3.50876 8.11354 4.18448 7.98735 4.81932 7.73968L4.33266 6.49835C3.84773 6.68537 3.3317 6.77858 2.81199 6.77302C3.03589 5.81161 3.5243 4.93201 4.22199 4.23368C4.94631 3.50597 5.86612 3.00392 6.86999 2.78835C6.86361 3.23191 6.78317 3.6713 6.63199 4.08835L7.88599 4.54235C8.09704 3.96539 8.20578 3.35603 8.20732 2.74168C8.20732 2.71968 8.20466 2.69835 8.20399 2.67635C9.54599 2.72902 10.804 3.27368 11.7653 4.23368C12.7142 5.18334 13.2678 6.45737 13.3147 7.79902C12.6814 7.79168 12.052 7.8985 11.4567 8.11435L11.9127 9.36768C12.3262 9.21591 12.7622 9.13456 13.2027 9.12702C12.99 10.1311 12.4904 11.0519 11.7647 11.7777Z"
    />
    <path
      d="M9.408 9.41959C9.17881 9.6484 8.97208 9.89865 8.79067 10.1669L9.89467 10.9136C10.028 10.7163 10.1813 10.5309 10.35 10.3629C10.5867 10.1265 10.8503 9.91868 11.1353 9.74359L10.436 8.60826C10.0628 8.83761 9.71779 9.10991 9.408 9.41959ZM5.63733 5.64892C5.46818 5.81719 5.28359 5.96919 5.086 6.10292L5.832 7.20826C6.10021 7.02648 6.35065 6.81979 6.58 6.59092C6.88926 6.28076 7.16152 5.93579 7.39133 5.56292L6.256 4.86426C6.08063 5.14873 5.87304 5.41202 5.63733 5.64892Z"
    />
  </svg>
</template>
