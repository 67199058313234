import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  Observable,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { isUserLoggedIn } from '@/services/api/cognito';
import routes from '@/../config/env';
import { getSuperAdminData } from './helpers/super-admin';

const { gql } = routes;

const httpLink = createHttpLink({
  uri: gql,
});

const asyncAuthLink = setContext(async (_, { headers }) => {
  const { token } = await isUserLoggedIn();
  const { isSuperAdmin, client } = getSuperAdminData();

  const config = {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };

  if (isSuperAdmin) {
    // For special cases where we want to have manual client.
    // For example allOperators query have '*' value for x-admin-for
    config.headers['x-admin-for'] = config.headers['x-admin-for'] || client;
  }

  return config;
});

const connections = {};
const cancelRequestLink = new ApolloLink(
  (operation, forward) => new Observable((observer) => {
    const context = operation.getContext();

    const connectionHandle = forward(operation).subscribe({
      next: (...arg) => observer.next(...arg),
      error: () => {
        connectionHandle.unsubscribe();
        delete connections[context.requestTrackerId];
      },
      complete: (...arg) => {
        connectionHandle.unsubscribe();
        delete connections[context.requestTrackerId];
        observer.complete(...arg);
      },
    });

    if (context.requestTrackerId) {
      const controller = new AbortController();
      controller.signal.onabort = () => {
        connectionHandle.unsubscribe();
        delete connections[context.requestTrackerId];
      };
      operation.setContext({
        ...context,
        fetchOptions: {
          signal: controller.signal,
          ...context?.fetchOptions,
        },
      });

      if (connections[context.requestTrackerId]) {
        // If a controller exists, that means this operation should be aborted.
        connections[context.requestTrackerId].abort();
      }

      connections[context.requestTrackerId] = controller;
    }

    return connectionHandle;
  }),
);

export default new ApolloClient({
  link: (asyncAuthLink.concat(cancelRequestLink)).concat(httpLink),
  cache: new InMemoryCache(),
  queryDeduplication: false,
});
