import { orderBy } from 'lodash';

const competitionSortIndices = {
  '6b3e9b3b-0b2d-4a1f-bb0d-4e87ad704738': 10, // NFL
  'b38e915d-d7f0-4bd6-ba3c-a37a7a8e5b84': 20, // CFL
  'c97fde3d-6bf7-4940-8773-69884c020c91': 10, // MLB
  '1c69236d-03f1-4f7e-9937-f0fbb6bd4ecd': 10, // NBA
  'd1303850-9f46-4ef3-bc0d-11e0b8477d69': 20, // NCAA
  'ac69a12d-9956-4cec-8ae5-13f486025a97': 20, // NHL
};

export const sortCompetitions = (competitions) => orderBy(competitions, [
  ({ competitionId }) => competitionSortIndices[competitionId] ?? Number.MAX_SAFE_INTEGER,
  'competitionName',
]);
