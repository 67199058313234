<template>
  <div class="spinner">
    <svg
      class="spinner-icon"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="spinner-icon-path"
        fill="none"
        stroke-width="6"
        stroke-linecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>

    <span class="spinner-label">
      <slot />
    </span>
  </div>
</template>

<style lang="scss">
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  .spinner-icon {
    width: 2rem;
    height: 2rem;
    animation: rotate 1.5s linear infinite;
  }

  .spinner-icon-path {
    stroke: darken($primary, 10%);
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    transform-origin: center;
    animation: dash 1.5s ease-in-out infinite;
  }

  .spinner-label {
    font-size: 1rem;
    font-weight: 500;
    margin-left: 1rem;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -200;
  }
}
</style>
